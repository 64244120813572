import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Spinner } from 'react-bootstrap'
import { useParams, Link } from 'react-router-dom'
import moment from 'moment'
import { useForm } from 'react-hook-form'

import { Container } from './styles'
import api from '~/services/api'
import Button from '~/components/Button'
import MedicineImage from '~/assets/images/produto.png'
import Thumb from '~/components/Thumb'
import ModalEditOrder from './ModalEditOrder/index'
import { Input, Select } from '~/components/Form'
import { calculateValueList, formatValue, phoneMask, transform } from '~/shared/utils'
import { Currency } from '~/components/Currency'
import { ModalConfimation } from '~/components/ModalConfirmation/ModalConfimation'
import pdfIcon from '~/assets/icons/pdfIcon.png'
import { isAuthenticated } from '~/services/auth'
import { setOrderStates } from '~/redux/actions/orders'

export default function Order() {
  const [order, setOrder] = useState({ medicines: [] })
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [brands, setBrands] = useState([])
  const [tracking, setTracking] = useState('')
  const [link, setLink] = useState('')
  const [carriers, setCarriers] = useState('')
  const [showEditOrder, setShowEditOrder] = useState(false)
  const [productDiscount, setProductDiscount] = useState(0)
  const [shippingDiscount, setShippingDiscount] = useState(0)
  const { register, watch, setValue } = useForm()
  const [showButton, setShowButton] = useState(false)
  const { orderStates } = useSelector(state => state.orderReducer)
  const [discountState, setDiscountState] = useState(0)
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false)
  const [persistedDiscount, setPersistedDiscount] = useState({
    product: 0,
    shipping: 0,
  })
  const [baseAnvisa, setBaseAnvisa] = useState('')
  const [baseRg, setBaseRg] = useState('')
  const [basePrescription, setBasePrescription] = useState('')
  const [baseProofOfAddress, setBaseProofOfAddress] = useState('')
  const [showLinkAnvisa, setShowLinkAnvisa] = useState(false)
  const [showLinkRg, setShowLinkRg] = useState(false)
  const [showLinkPrescription, setShowLinkPrescription] = useState(false)
  const { status } = watch()
  const dispatch = useDispatch();

  useEffect(()=>{
    if (orderStates.length===0) {
      if (isAuthenticated) {
        const requestOrder = async () => {
          const responseOrder = await api.get('/orders/states')
          dispatch(setOrderStates(responseOrder.data))
        }
        requestOrder();
      }
    }
  }, [order])


  const getOrder = async () => {
    setLoading(true)
    const res = await api.get(`/orders/${id}`)
    setOrder(res.data)
    setLoading(false)
    setValue('status', res.data.status)
    setShowButton(res.data.status === 'VALIDATION_SUCCEEDED')
  }

  const submitCode = async (
    orderId,
    orderTrackingCode,
    orderUserEmail,
    orderLink,
    orderCarriers,
  ) => {
    setLoading(true)
    await api.put(`/orders/${orderId}`, {
      tracking_code: orderTrackingCode,
      status: 'transporting',
      user_email: orderUserEmail,
      link: orderLink,
      carriers: orderCarriers,
    })
    setLoading(false)
  }

  const changeForm = event => {
    if (event.target.name === 'tracking') {
      setTracking(event.target.value)
    }
    if (event.target.name === 'link') {
      setLink(event.target.value)
    }
    if (event.target.name === 'carriers') {
      setCarriers(event.target.value)
    }
  }

  useEffect(() => {
    if (
      order.prescription?.patient?.documents &&
      order.prescription?.patient?.documents[0]?.file?.id
    ) {
      const url = `/files/${order.prescription.patient.documents[0].file.id}`
      transform(url).then(r => setBaseRg(r))
    }
    if ( order.address ) {
      const url = `/files/${order.address.file?.id}`
      transform(url).then(r => setBaseProofOfAddress(r))
    }
    if (order?.prescription?.file?.id) {
      const url = `/files/${order.prescription.file.id}`
      transform(url).then(r => setBasePrescription(r))
    }
    if (order?.prescription?.anvisa) {
      const url = `/files/${order.prescription.anvisa.id}`
      transform(url).then(r => setBaseAnvisa(r))
    }
  }, [order.prescription])

  useEffect(() => {
    if (baseAnvisa.substr(0, 5) === 'data:') {
      setShowLinkAnvisa(true)
    }
  }, [baseAnvisa])

  useEffect(() => {
    if (baseRg.substr(0, 5) === 'data:') {
      setShowLinkRg(true)
    }
  }, [baseRg])

  useEffect(() => {
    let result
    if (showButton) {
      result = 1
      if (productDiscount > 0 || shippingDiscount > 0) {
        if (
          productDiscount === persistedDiscount.product &&
          shippingDiscount === persistedDiscount.shipping
        ) {
          result = 2 // salvo sem mudar de valor
        } else {
          result = 1 // Valor alterado
        }
      } else {
        result = 1 // sem mudar de valor
      }
    } else {
      result = 0
    }
    setDiscountState(result)
    console.log(result)
  }, [productDiscount, shippingDiscount, persistedDiscount])

  useEffect(() => {
    getOrder()
  }, [id])

  useEffect(() => {
    setBrands(
      order.medicines
        .map(medicine => medicine.brand)
        .filter(
          (brand, index, self) =>
            index === self.findIndex(b => b.id === brand.id),
        ),
    )
  }, [order])

  const putOrders = async () => {
    if (!loading) {
      const data = {
        status,
      }
      await api.put(`/orders/${id}`, data)
      setShowButton(data.status === 'VALIDATION_SUCCEEDED')
    }
  }

  const addDiscount = () => {
    if (!loading) {
      const data = {
        status,
      }
      api.put(`/orders/${id}`, data)
      setDiscountState(0)
    }
  }

  const handleCloseEditOrder = async () => {
    await getOrder()
    setShowEditOrder(false)
  }

  let total = []

  return (
    <Container>
      {loading && <Spinner/>}
      {!loading && (
        <>

          <Row>
            <Col className="d-flex align-items-center">
              <h2>Pedido #{order.id}
              </h2>
              <a href={process.env.REACT_APP_API + `/orders/${order.id}/quotation`} target="_blank"
                 className="ml-5 btn-link">
                <i className="fa fa-2x mr-2 fa-file-invoice-dollar font-size-20"></i>
                Gerar Cotação
              </a>
              <a href={process.env.REACT_APP_API + `/orders/${order.id}/invoice`} target="_blank"
                 className="ml-5 btn-link">
                <i className="fa fa-2x mr-2 fa-file-invoice-dollar font-size-20"></i>
                Gerar Invoice
              </a>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex">
              <div className="desc pt-2">
                <div className="infos">
                  <div className="my-3">
                    <b>Medico</b>
                    {order.prescription &&
                    order.prescription.physician !== null ? (
                      <>
                        <p>
                          <a
                            href={`/admin/pacientes/${order.prescription.physician.id}`}
                          >
                            {order.prescription.physician
                              ? order.prescription.physician.profile.full_name
                              : order.prescription.prePhysician?.full_name}
                          </a>
                        </p>
                        <p className="mt-3">
                          <b>Telefone:</b>{' '}
                          {phoneMask(
                            order.prescription.physician.profile.phone_number,
                          )}
                        </p>
                        <p>
                          <b>E-mail:</b>{' '}
                          <a
                            href={`mailto:${order.prescription.physician.email}`}
                          >
                            {order.prescription.physician.email}
                          </a>
                        </p>
                      </>
                    ) : (
                      <p>
                        --------------------------------------------------------
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="pl-5 desc pt-2">
                <div className="infos">
                  <div className="my-3">
                    <b>Paciente</b>
                    <p>
                      {order.prescription && (
                        <a
                          href={`/admin/pacientes/${order.prescription.patient.id}`}
                        >
                          {order.prescription.patient.profile.full_name}
                        </a>
                      )}
                    </p>
                    <p className="mt-3">
                      <b>Telefone:</b>{' '}
                      {order.prescription &&
                      phoneMask(
                        order.prescription.patient.profile.phone_number,
                      )}
                    </p>
                    <p>
                      <b>E-mail:</b>{' '}
                      <a href={`mailto:${order.prescription?.patient?.email}`}>
                        {order.prescription?.patient?.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="pl-5 desc p-2">
                <div className="infos">
                  <div className="my-3">
                    <b>Data do pedido</b>
                    <p className="mb-3">
                      {moment(order.created_at).format('DD/MM/YYYY')}
                    </p>
                    <b>Endereço da entrega</b>
                    <p>
                      {order.address?.street}, {order.address?.number}
                    </p>
                    <p>{order.address?.complement}</p>
                    <p>{order.address?.district}</p>
                    <p>
                      {order.address?.state} - {order.address?.city}
                    </p>
                    <p>{order.address?.cep}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex">
                {order.prescription.patient?.documents && order.prescription.patient?.documents[0]?.file?.id && (
                  <a
                    href={baseRg}
                    target="_blank"
                    rel="noopener"
                    className={showLinkRg ? '' : 'd-none'}
                    download={`${order.prescription.patient?.profile?.full_name}-documento-rg`}
                  >
                    <div className="file">
                      <img src={pdfIcon}/>
                      <p>RG</p>
                    </div>
                  </a>
                )}
                {order.prescription.patient?.addresses && order.prescription.patient?.addresses[0]?.file?.id && (
                  <a
                    href={baseProofOfAddress}
                    target="_blank"
                    rel="noopener"
                    className={showLinkRg ? '' : 'd-none'}
                    download={`${order.prescription.patient?.profile?.full_name}-comprovante-residencia`}
                  >
                    <div className="file">
                      <img src={pdfIcon}/>
                      <p>Comp Residência</p>
                    </div>
                  </a>
                )}
                {order.prescription.file && (
                  <a
                    href={basePrescription}
                    target="_blank"
                    rel="noopener"
                    className={showLinkPrescription ? '' : 'd-none'}
                    download={`${order.prescription.patient?.profile?.full_name}-${order.prescription.id}`}
                  >
                    <div className="file">
                      <img src={pdfIcon}/>
                      <p>Prescrição</p>
                    </div>
                  </a>
                )}
                {order.prescription.anvisa && (
                  <a
                    href={baseAnvisa}
                    target="_blank"
                    rel="noopener"
                    className={showLinkAnvisa ? '' : 'd-none'}
                    download={`${order.prescription.patient?.profile?.full_name}-${order.prescription.id}`}
                  >
                    <div className="file">
                      <img src={pdfIcon}/>
                      <p>ANVISA</p>
                    </div>
                  </a>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="px-0">
              <form onChange={e => changeForm(e)}>
                <Col lg={12} className="d-flex">
                  <Select
                    ref={register}
                    name="status"
                    label="Status do pedido"
                    size="md"
                    fluid
                    icon="icon-chevron-down"
                    className="my-3"
                  >
                    {orderStates.map(state => (
                      <option value={state.key}>{state.name}</option>
                    ))}
                  </Select>
                </Col>
                <Col lg={12} className="d-flex">
                  <Select
                    name="carriers"
                    label="Transportadoras"
                    size="md"
                    fluid
                    icon="icon-chevron-down"
                    className="my-3"
                    defaultValue={order.carriers}
                  >
                    <option value="UPS">UPS</option>
                    <option value="DHL">DHL</option>
                    <option value="Fedex">Fedex</option>
                  </Select>

                  <Input
                    size="md"
                    type="text"
                    label="Link de rastreio do pedido"
                    name="link"
                    className="my-3 ml-3"
                    placeholder="Link do rastreio"
                    value={order.link ?? ''}
                  />
                  <Input
                    size="md"
                    type="text"
                    label="Código de rastreio do pedido"
                    name="tracking"
                    className="my-3 ml-3"
                    placeholder="Código de rastreio"
                    value={order.tracking_code ?? ''}
                  />
                  <Button
                    className="primary ml-3 mt-5"
                    style={{
                      height: '50px',
                      marginTop: '35px',
                    }}
                    onClick={() =>
                      submitCode(
                        order.id,
                        tracking,
                        order.owner.email,
                        link,
                        carriers,
                      )
                    }
                  >
                    Salvar código
                  </Button>
                </Col>
              </form>
            </Col>
            <Col lg={12} className="mt-4">
              <h4>Produtos</h4>
              {order.medicines.map(medicine => (
                <div className="item" key={medicine.id}>
                  <div className="d-flex justify-content-center">
                    <Thumb className="lg">
                      <div className="inner">
                        <Link className="btn-link" to={`/produtos/${medicine.slug}`}>
                          {order.medicines[0].photo ? (
                            <img
                              className="product"
                              src={order.medicines[0].photo?.uuidUrl}
                              alt={order.medicines[0].photo?.name}
                            />
                          ) : (
                            <img src={MedicineImage} alt=""/>
                          )}
                        </Link>
                      </div>
                    </Thumb>
                  </div>
                  <div className="d-flex flex-column justify-content-between p-3 w-100">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h4 className="mb-2">
                          <Link className="btn-link" to={`/produtos/${medicine.slug}`}> {medicine?.name}</Link>
                        </h4>
                        <p>{medicine?.pivot.quantity} unidade(s)</p>
                      </div>
                    </div>
                    <div>
                      <p>Valor vendido:</p>
                      <Currency
                        value={medicine.pivot.price}
                        currency={medicine.currency}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column justify-content-end">
                        <p>Vendido por:</p>
                        <p>{medicine?.brand.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {brands.map((brand, brandIdx) => {
                return (
                  <div key={brand.id}>
                    <h5 className="mt-3 mb-2">{brand.name}</h5>
                    <table>
                      <thead>
                      <tr>
                        <th>
                          <b>Produto</b>
                        </th>
                        <th className="text-center">
                          <b>Quantidade</b>
                        </th>
                        <th className="text-right">
                          <b>Valor</b>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {order.medicines.map((medicine, medIdx) => {
                        if (medicine.brand.id === brand.id) {
                          total[brandIdx] =
                            parseInt(total[brandIdx]) +
                            parseInt(medicine.shipping) *
                            parseInt(medicine.pivot.quantity)
                          total[brandIdx] = total[brandIdx].toFixed(2)

                          return (
                            <>
                              <tr key={medicine.id}>
                                <td>{medicine.comercial}</td>
                                <td className="text-center">
                                  {medicine.pivot.quantity}
                                </td>
                                <td className="text-right">
                                  <Currency
                                    value={medicine.pivot.price}
                                    currency={medicine.currency}
                                  />
                                </td>
                              </tr>
                            </>
                          )
                        }
                      })}
                      <tr>
                        <td colSpan="2"></td>
                        <td className="d-flex justify-content-between">
                          <b className="mr-3">SubTotal:</b>{' '}
                          <Currency
                            value={ order.subtotal }
                            currency={
                              order.medicines[0].shipping_currency
                                ? order.medicines[0].shipping_currency
                                : order.medicines[0].brand.shipping_currency
                            }
                          />
                        </td>
                      </tr>
                      {discountState===0 && order.discount.product>0 && (
                        <tr>
                          <td colSpan="2"></td>
                          <td className="d-flex justify-content-between">
                            <b className="mr-3">Desconto produto:</b>{' '}
                            <Currency
                              value={ order.discount?.product }
                              currency={
                                order.medicines[0].shipping_currency
                                  ? order.medicines[0].shipping_currency
                                  : order.medicines[0].brand.shipping_currency
                              }
                            />
                          </td>
                        </tr>
                      )}
                      {showButton && discountState > 0 && (
                        <tr>
                          <td className="text-right" colSpan="3">
                            <Input
                              label="Desconto sobre o Produto"
                              innerLabel={true}
                              size="sm"
                              type="text"
                              name="productDiscount"
                              className="my-2 ml-3 align-items-right"
                              classContainer="d-flex justify-content-end"
                              classLabel="mt-2 mr-3 font-weight-bold"
                              placeholder="0,00"
                              classInput="discount-input"
                              onChange={e =>
                                setProductDiscount(e.target.value)
                              }
                              value={order.discount ? order.discount.product : 0}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan="2"></td>
                        <td className="d-flex justify-content-between">
                          <b className="mr-3">Frete:</b>{' '}
                          <Currency
                            value={ order.shipping }
                            currency={
                              order.medicines[0].shipping_currency
                                ? order.medicines[0].shipping_currency
                                : order.medicines[0].brand.shipping_currency
                            }
                          />
                        </td>
                      </tr>
                      {discountState===0 && order.discount.shipping>0 && (
                        <tr>
                          <td colSpan="2"></td>
                          <td className="d-flex justify-content-between">
                            <b className="mr-3">Desconto frete:</b>{' '}
                            <Currency
                              value={ order.discount?.shipping }
                              currency={
                                order.medicines[0].shipping_currency
                                  ? order.medicines[0].shipping_currency
                                  : order.medicines[0].brand.shipping_currency
                              }
                            />
                          </td>
                        </tr>
                      )}

                      {showButton && discountState > 0 && (
                        <tr>
                          <td className="text-right" colSpan="3">
                            <Input
                              label="Desconto sobre o frete"
                              innerLabel={true}
                              size="sm"
                              type="text"
                              name="shippingDiscount"
                              className="my-2 ml-3 align-items-right"
                              classContainer="d-flex justify-content-end"
                              classLabel="mt-2 mr-3 font-weight-bold"
                              placeholder="0,00"
                              classInput="discount-input"
                              onChange={(e) =>
                                setShippingDiscount(e.target.value)
                              }
                              value={order.discount ? order.discount.shipping : 0}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan="2"></td>
                        <td className="d-flex justify-content-between">
                          <b className="mr-3">Total:</b>{' '}
                          <b>
                            <Currency
                              value={order.total}
                              currency={order.medicines[0].currency}
                            />
                          </b>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </Col>

            <Col lg={12} className="d-flex justify-content-end mt-3">
              {showButton && discountState === 0 && (
                <Button
                  className="primary mr-5 px-5"
                  disabled={loading}
                  type="submit"
                  onClick={async () => {
                    setDiscountState(2)
                  }}
                >
                  Adicionar Desconto
                </Button>
              )}

              {showButton && discountState > 0 && (
                <Button
                  className="primary mr-5 px-5"
                  disabled={loading}
                  type="submit"
                  onClick={async () => {
                    setShowButton(false)
                    const data = { product: 0, shipping: 0 }
                    setPersistedDiscount(data)
                    await api.delete(`/orders/discount/${id}`)
                    setDiscountState(0)
                    setShowButton(true)
                  }}
                >
                  Remover Desconto
                </Button>
              )}

              {showButton && discountState === 1 && (
                <Button
                  className="primary mr-5 px-5"
                  disabled={loading}
                  type="submit"
                  onClick={async () => {
                    const data = { product: productDiscount, shipping: shippingDiscount }
                    setPersistedDiscount(data)
                    await api.post(`/orders/discount/${id}`, data)
                  }}
                >
                  Gravar Desconto
                </Button>
              )}
              <button
                className="btn btn-danger mr-5 d-none"
                data-toggle="modal"
                data-target="#confirmModal"
                onClick={()=>setOpenModalConfirmation(true)}
              >
                Apagar pedido
              </button>

              <Button
                className="primary sm mr-5"
                disabled={loading}
                type="submit"
                onClick={() => putOrders()}
              >
                Salvar
              </Button>
              <Button
                className="primary sm"
                onClick={() => setShowEditOrder(true)}
              >
                Editar
              </Button>
            </Col>
          </Row>
          <ModalEditOrder
            show={showEditOrder}
            handleClose={handleCloseEditOrder}
            order={order}
          />
          <ModalConfimation clicked={openModalConfirmation}/>
        </>
      )}
    </Container>
  )
}
