import React, { useEffect, useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Creators as AuthActions } from '~/store/ducks/auth'

import { Container, ContainerMobile, MenuMobile } from './styles'
import Logo from '~/assets/images/logo_horizontal_p.png'
import Sprites from '~/assets/icons/sprites.svg'
import LoginButton from './Login'
import Button from '../Button/index'
import { Patient, Physician, Admin, Public } from '../SideMenu/links'
import { RenderImg } from '~/components/RenderImg/RenderImg'
import avatar from '../../assets/icons/profile.svg'
import {isAuthenticated} from '~/services/auth'

export default function Header() {
  const roles = localStorage.getItem('roles')
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const token = localStorage.getItem('@DrCannabis:JWT_TOKEN')
  const [openMenu, setOpenMenu] = useState(false)
  const [links, setLinks] = useState([])
  const location = useLocation()

  useEffect(() => {
      switch (roles) {
        case 'patient':
          setLinks(Patient)
          break
        case 'responsible':
          setLinks(Patient)
          break
        case 'physician':
          setLinks(Physician)
          break
        case 'administrator':
          setLinks(Admin)
          break
        default:
          setLinks(Public)
    }
  }, [roles])

  useEffect(() => {
    setOpenMenu(false)
  }, [location])

  useEffect(() => {
    if (token) {
      dispatch(AuthActions.getSessionRequest())
    }
  }, [auth.token, dispatch, token])

  let urlDashboard = '/dashboard'

  if (location.pathname.split('/').includes('template')) return null

  return (
    <>
      <Container className="d-none  d-md-flex align-items-center">
        <div className="container-lg container-fluid">
          <div className="row m-0">
            <div className="col-12 d-flex align-items-center justify-content-between p-0">
              <div className="d-flex align-items-center">
                <Link to="/">
                  <img className="logo" src={Logo} alt="Cannabis Logo" />
                </Link>
              </div>
              <div className="content d-flex flex-grow-1 justify-content-between">
                <Link
                  to="/sobre"
                  className="anchor  text-decoration-none"
                >
                  Sobre nós
                </Link>
                <Link
                  to="/produtos"
                  className="anchor  text-decoration-none"
                >
                  Produtos
                </Link>
                <Link
                  to="/comprar-cbd"
                  className="anchor  text-decoration-none"
                >
                  Como comprar CBD
                </Link>
                <Link
                  to="/faq"
                  className="anchor  text-decoration-none"
                >
                  FAQ
                </Link>
                <a
                  href="https://blog.drcannabis.com.br/"
                  target="_blank"
                  rel="noopener"
                  className="anchor  text-decoration-none"
                >
                  Blog
                </a>
              </div>
              <div className="d-flex align-items-center">
                {isAuthenticated() && (
                  <>
                    <Link to={urlDashboard}>
                      {auth.session?.profile?.avatar ? (
                        <div className="avatar">
                          <RenderImg
                            photo_id={auth.session.profile.avatar.id}
                            photo_uuid={auth.session.profile.avatar.uuidUrl}
                            isPrivate={auth.session.profile.avatar.private}
                            classNameImg="img align-self-center mb-3"
                            alt="Usuário"
                          />
                        </div>
                      ) : (
                        <img src={avatar} className="icon"/>

                      )}
                    </Link>
                    <button className="btn btn-link d-none">
                      <svg className="icon">
                        <use xlinkHref={`${Sprites}#icon-notification`} />
                      </svg>
                    </button>
                  </>
                )}
                {!isAuthenticated() && <LoginButton />}
              </div>
            </div>
          </div>
        </div>
      </Container>
      <ContainerMobile className="d-flex align-items-center d-md-none">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <svg className="icon icon-open" onClick={() => setOpenMenu(true)}>
                <use xlinkHref={`${Sprites}#icon-menu`} />
              </svg>
              <img className="img" src={Logo} alt="Logo" />
              <div></div>
            </div>
          </div>
        </div>
      </ContainerMobile>
      <MenuMobile
        className={`d-md-none position-absolute pt-4 ${openMenu ? 'open' : ''}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between mb-5">
                <svg className="icon close" onClick={() => setOpenMenu(false)}>
                  <use xlinkHref={`${Sprites}#icon-plus`} />
                </svg>
                <div className="d-flex">
                  {isAuthenticated() ? (
                    <Link to="/dashboard">
                      <svg className="icon">
                        <use xlinkHref={`${Sprites}#icon-profile-fill`} />
                      </svg>
                    </Link>
                  ) : (
                    <Link to="/login">
                      <Button className="primary">Entrar</Button>
                    </Link>
                  )}
                </div>
              </div>
              <ul className="list-unstyled">
                {links.map(link => (
                  <li className={`item`} key={link.url}>
                    { link.label != 'Blog' ? <Link
                      className={`anchor text-decoration-none`}
                      to={link.url}
                    >
                      {link.label}
                    </Link> : (
                      <a className={`anchor text-decoration-none`} href="https://blog.drcannabis.com.br" target='_blank'>Blog</a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </MenuMobile>
    </>
  )
}
