import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'

import { Container } from './styles'
import Patient from './PatientCard'
import api from '~/services/api'

export default function Patients() {
  const [patients, setPatients] = useState([])
  const [prescriptions, setPrescriptions] = useState([])
  const [favorites, setFavorites] = useState([])
  const [loading, setLoading] = useState(false)

  const getPatients = async () => {
    setLoading(true)
    const res = await api.get('/patients/list')
    setPatients(res.data.patients)
    setPrescriptions(res.data.prescriptionCount)
    setFavorites(res.data.favoriteCount)
    setLoading(false)
  }

  useEffect(() => {
    getPatients()
  }, [])

  const handlePageChange = page => {
    getPatients()
  }

  return (
    <Container>
      {favorites>0 ? (
        <Row>
          <Col>
            <h2 className="text--rm mb-4"> <b>{favorites}</b> { favorites > 1 ?  ('Pacientes favoritaram ') : ('Paciente favoritou ') } o seu perfil</h2>
          </Col>
        </Row>
      ) : ''
      }
      {prescriptions>0 ? (
      <Row>
        <Col>
          <h3 className="text--rl">{prescriptions} { prescriptions > 1 ? ('pacientes já receberam prescrições') : ('paciente já recebeu prescrição') }</h3>
        </Col>
      </Row>
      ) : ''
      }
      <Row>
        {loading && (
          <Col className="d-flex justify-content-center">
            <Spinner animation="grow" />
          </Col>
        )}
        {!loading &&
          (patients.length ? (
            patients.map(patient => (
              <Col md={4} sm={12} className="my-3" key={patient.id}>
                <Link to={`/medico/pacientes/${patient.id}`}>
                  <Patient data={patient} />
                </Link>
              </Col>
            ))
          ) : (
            <Col className="d-flex w-100 justify-content-center m-5">
              <Alert variant="warning p-3">
                {' '}
                Você ainda não tem pacientes ativos. Assim que prescrever
                seus pacientes estarão listado nesse espaço.
              </Alert>
            </Col>
          ))}
      </Row>
      {/*{patients.length > 0 && (*/}
      {/*  <Row>*/}
      {/*    <Col className="d-flex justify-content-center mt-3">*/}
      {/*      <Pagination*/}
      {/*        activePage={patients.page}*/}
      {/*        itemsCountPerPage={patients.perPage}*/}
      {/*        totalItemsCount={patients.total ? patients.total : 0}*/}
      {/*        pageRangeDisplayed={5}*/}
      {/*        onChange={handlePageChange}*/}
      {/*        itemClass="page-item"*/}
      {/*        linkClass="page-link"*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*)}*/}
    </Container>
  )
}
