import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as ErrorActions } from '~/store/ducks/error'
import api from '~/services/api'
import { Container } from './styles'
import Thumb from '~/components/Thumb'
import Sprites from '~/assets/icons/sprites.svg'
import pdfIcon from '~/assets/icons/pdfIcon.png'
import Button from '~/components/Button'
import { Input } from '~/components/Form'

export default function NewPrescription({
  next,
  refresh,
  data,
  userMail = '',
  ...rest
}) {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(true)
  const dispatch = useDispatch()
  const { session } = useSelector(state => state.auth)

  const cancelFile = () => {
    setFiles([])
  }

  const sendFile = async (file, type) => {
    try {
      var formData = new FormData()
      formData.append('assetType', 'prescription')
      formData.append('file', file)
      formData.append('assetType', type)
      const res = await api.post('/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return res
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, application/pdf',
    noKeyboard: true,
    multiple: false,
    onDrop: async acceptedFiles => {
      const res = await sendFile(acceptedFiles[0], 'prescription')
      if (res?.data) {
        setFiles(
          acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              id: res.data.id,
            }),
          ),
        )
      }
    },
  })

  const onSubmit = async () => {
    if (files.length > 0) {
      setLoading(true)
      try {
        const prescription = {
          file_id: files[0].id,
          status: 'askAnvisa',
          user_email: userMail,
          physician_email:
            session.roles[0] === 'physician' ? session.email : '',
        }

        let res
        if (data?.id) {
          res = await api.put(`/prescriptions/${data.id}`, prescription)
        } else {
          res = await api.post(`/prescriptions`, prescription)
        }
        await refresh(res.data.id)
        next('askAnvisa')
        setLoading(false)
      } catch (e) {
        dispatch(ErrorActions.setError(e.response))
        setLoading(false)
      }
    } else {
      dispatch(ErrorActions.setError('Selecione um arquivo para upload'))
    }
  }

  let thumbs = (
    <Thumb>
      <div className="inner">
        <svg className="m-4">
          <use xlinkHref={`${Sprites}#icon-plus`} />
        </svg>
      </div>
    </Thumb>
  )

  if (files.length) {
    thumbs = files.map(file => (
      <Thumb key={file.name}>
        <div className="inner">
          {file.type.split('/')[0] !== 'image' && (
            <img src={pdfIcon} alt="preview" />
          )}
          {file.type.split('/')[0] === 'image' && (
            <img src={file.preview} alt="preview" />
          )}
        </div>
      </Thumb>
    ))
  }

  return (
    visible && (
      <Container {...rest}>
        <Row>
          <Col>
            <h4 className="mb-3 text-center">Prescrição Médica</h4>
          </Col>
        </Row>
        <Row className="w-100">
          <Col md={2} className="d-flex justify-content-center">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {thumbs}
            </div>
            {files.length > 0 && (
              <div className="cancel" onClick={cancelFile}>
                x
              </div>
            )}
          </Col>
          <Col md={9} className="align-self-end">
            <div>
              <small>JPG ou PDF até 2mb</small>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Input
                  size="sm"
                  type="text"
                  name="file"
                  disabled
                  fluid
                  value={
                    files[0]
                      ? files[0].name
                      : 'Clique para anexar seu documento.'
                  }
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="d-flex justify-content-end mt-4">
            <Button className="primary float-right" onClick={() => onSubmit()}>
              Enviar
            </Button>
          </Col>
        </Row>
      </Container>
    )
  )
}
