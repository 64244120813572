import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'


const Status = ({id}) => {
  const {orderStates} = useSelector((state)=>state.orderReducer)
  const getItem = (id) => orderStates.filter( (item) => item.key === id ).pop()
  return (
    <>
        {getItem(id) && getItem(id).name}
    </>
  )
}

export { Status }
