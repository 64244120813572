import React, { useState, useEffect } from 'react'
import { Row, Col, ProgressBar } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Creators as AuthActions } from '~/store/ducks/auth'

import Yup from '~/helpers/yup'
import { Creators as ErrorActions } from '~/store/ducks/error'
import api from '~/services/api'
import {CustomInput, CustomSelect, CustomCheckbox,CustomMultSelect, CustomTextArea} from '~/components'
import {ButtonPrimary} from '~/components/Buttons/PrimaryButton'
import Skeleton from 'react-loading-skeleton'
import {
  Input,
  Select,
  TextArea,
  AutoComplete,
  Checkbox,
} from '~/components/Form'
import Button from '~/components/Button'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import Diseases from '~/helpers/Diseases.json'
import Specialties from '~/helpers/Specialties.json'

const EditPhysicianSchema = Yup.object().shape({
  physician: Yup.object().shape({
    crm: Yup.string().required('Digite seu CRM'),
    uf: Yup.string().required('Selecione o UF do seu CRM'),
    specialty: Yup.string().required('Selecione sua especialidade'),
    about: Yup.string(),
  }),
})

export default function Edit({ history }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    validationSchema: EditPhysicianSchema,
  })
  // const error = useSelector(state => state.error)
  const { session } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [defaultDiseases, setDefaultDiseases] = useState([])


  useEffect(() => {
    register({ name: 'physician.diseases' })
  })

  const diseases = watch('physician.diseases')

  useEffect(() => {
    if (session.physician)
      if (Array.isArray(session.physician.diseases)) {
        setDefaultDiseases(session.physician.diseases)
        setValue('physician.diseases', session.physician.diseases)
      }
    setValue('physician', session.physician)
  }, [session.physician])

  const onSubmit = async data => {
    setLoading(true)
    try {
      if (Array.isArray(diseases)) data.physician.diseases = diseases
      await api.put(`/users/${session.id}`, data)
      dispatch(AuthActions.getSessionRequest())
      setLoading(false)
      const to = '/dashboard'
      history.push(to)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoading(false)
    }
  }

  return (
    <>
      {
    !session.roles[0] === '' || !session.roles[0] ? (
      <div className='contanier-fluid p-5 patient__profile__show__skeleton'>


        <div className="row ">
          <div className="col-lg-12">
            <Skeleton width='60%'/>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6 patient__profile__show__skeleton__mb">
            <Skeleton width='70%'/>
            <Skeleton className='mt-2' width='50%'/>
          </div>
          <div className='col-lg-6'>
            <Skeleton width='70%'/>
            <Skeleton className='mt-2' width='50%'/>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6 patient__profile__show__skeleton__mb">
            <Skeleton width='70%'/>
            <Skeleton className='mt-2' width='50%'/>
          </div>
          <div className='col-lg-6'>
            <Skeleton width='70%'/>
            <Skeleton className='mt-2' width='50%'/>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-12 patient__profile__show__skeleton__mb">
            <Skeleton width='70%'/>
            <Skeleton className='mt-2' width='100%' height='100px'/>
          </div>

        </div>

        <div className="row mt-5">
          <div className="col-lg-4 patient__profile__show__skeleton__mb">
            <Skeleton width='50%'/>
            <Skeleton className='mt-2' width='70%'/>
          </div>
          <div className='col-lg-4'>
            <Skeleton width='50%'/>
            <Skeleton className='mt-2' width='70%'/>
          </div>
          <div className='col-lg-4'>
            <Skeleton width='50%'/>
            <Skeleton className='mt-2' width='70%'/>
          </div>
        </div>




      </div>
      ):(
    <div className='container-fluid physician__profile__editPhysician'>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className="physician__profile__editPhysician__title">Termine seu cadastro</h2>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className="physician__profile__editPhysician__info__title">Informações médicas</h2>
        </div>
      </div>
      <div className="row">
        <div className='col-lg-12'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='col-lg-8'>
                <CustomInput
                  label="CRM"
                  inputName="physician.crm"
                  inputPlaceholder="000000"
                  error={errors.physician?.crm}
                  register={register}
                />
              </div>
              <div className='col-lg-4'>
                <CustomSelect
                  label="UF"
                  inputName="physician.uf"
                  error={errors.physician?.uf}
                  register={register}
                >
                  {StatesBrasil.map(state => (
                    <option value={state.value}>{state.value}</option>
                  ))}
                </CustomSelect>
              </div>
              <div className='col-lg-6'>
                <CustomSelect
                  label="Especialidade"
                  inputName="physician.specialty"
                  error={errors.physician?.specialty}
                  register={register}
                >
                  {Specialties.map(specialty => (
                    <option value={specialty}>{specialty}</option>
                  ))}
                </CustomSelect>
              </div>

              <div className='col-lg-6' >
                <CustomMultSelect
                  label='Lista de patologias atendidas'
                  listOptions={Diseases}
                  inputName={'physician.diseases'}
                  inputPlaceholder=''
                  value={diseases}
                  register={register}
                  setValue={setValue}
                />


              </div>

              <div className='col-lg-12'>
                <CustomTextArea
                  label="Sobre"
                  inputName="physician.about"
                  inputPlaceholder="Conte um pouco sobre sua vida profissional e a área que atua. Esta informação ficará visível para pacientes quando o seu perfil for aprovado."
                  error={errors.physician?.about}
                  register={register}
                />
              </div>

              <div className='col-lg-4'>
                <CustomInput
                  label="Preço da consulta"
                  inputName="physician.price"
                  error={errors.physician?.price}
                  register={register}
                />
              </div>

              <div className='col-lg-12'>
                <CustomCheckbox
                  inputName="physician.prescribes"
                  label="Tenho experiência na prescrição de produtos à base de cannabis."
                  register={register}
                  error={errors.physician?.prescribes}
                />
              </div>

              <div className='col-lg-4'>
                <CustomInput
                  label="Nome do(a) Secretário(a)"
                  inputName="physician.secretary_name"
                  error={errors.physician?.secretary_name}
                  register={register}
                />
              </div>

              <div className='col-lg-4'>
                <CustomInput
                  label="Email do(a) Secretário(a)"
                  inputName="physician.secretary_email"
                  error={errors.physician?.secretary_email}
                  register={register}
                />
              </div>

              <div className='col-lg-4' lg={4}>
                <CustomSelect
                  label="Opções de email"
                  inputName="physician.secretary_option"
                  error={errors.physician?.secretary_option}
                  register={register}
                >
                  <option value="physician">Enviar apenas para mim</option>
                  <option value="secretary">
                    Enviar apenas para secretária
                  </option>
                  <option value="both">Enviar email para ambos</option>
                </CustomSelect>
              </div>

              <div className='col-lg-12'>
                <ButtonPrimary
                  btnType="submit"
                  text={loading ? 'Carregando...' : 'Continuar'}
                >
                </ButtonPrimary>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    )
      }
      </>
  )
}
