import React, { useState, useEffect } from 'react'
import { Alert, Spinner } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import Order from './Order'
import api from '~/services/api'
import ModalFinishRegistration from '~/components/ModalFinishRegistration/index'

export default function Orders() {
  const [orders, setOrders] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)

  const getOrders = async () => {
    try {
      setLoading(true)
      const res = await api.get('/orders')
      setOrders(res.data)
      setLoading(false)
    } catch (e) {
      if (e.response.status === 406) {
        setLoading(false)
        setShowModal(true)
      } else {
        throw e
      }
    }
  }

  useEffect(() => {
    getOrders()
  }, [])

  return (
    <>
    <div className='container-fluid orders'>
      {loading && orders.length === 0 ? (
        <div className='container-fluid mt-5 orders__skeleton'>
          <div className='row'>
            <div className="col-lg-12">
              <Skeleton width='30%'/>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-12">
              <Skeleton width='100%'/>
            </div>
          </div>
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="row orders__skeleton__header">
              <div className="col-lg-2 orders__skeleton__header__img">
                <Skeleton width='100px' height='100px'/>
              </div>
              <div className="col-lg-10">
                <div>
                  <Skeleton width='70%'/>
                  <Skeleton className='mt-3' width='60%'/>
                  <Skeleton className='mt-3' width='50%'/>
                </div>
              </div>
            </div>

            <div className="row mt-3">

                <div className="col-lg-4 offset-lg-8">
                  <Skeleton width='100%'/>
                  <Skeleton className='mt-3' width='100%'/>
                  <Skeleton className='mt-3' width='100%'/>
                </div>

            </div>

            <div className="row mt-5">

              <div className="col-lg-4 mt-2">
                <Skeleton width='100%'/>
              </div>
              <div className="col-lg-4 mt-2">
                <Skeleton width='100%'/>
              </div>
              <div className="col-lg-4 mt-2">
                <Skeleton width='100%'/>
              </div>

            </div>

          </div>
        </div>
      </div>): (
        <div>
          <div className="mb-4">
            <h3 className="mb-2 orders__title">Pedidos</h3>
            {orders.length > 0 &&
            orders.map(order => (
              <Order refresh={getOrders} className="mb-3" data={order} />
            ))}

            {!loading && orders.length === 0 && (
              <Alert variant="warning my-3 p-3">
                {' '}
                Você ainda não tem pedidos ativos. Assim que fizer um pedido ele
                estará listado aqui.
              </Alert>
            )}
          </div>
          <div className="d-none">
            <h3 className="text--rb">Concluídos</h3>
          </div>
        </div>
      )}

    </div>

      <ModalFinishRegistration
        show={showModal}
        handleClose={() => {}}
        backdrop="static"
      />
    </>
  )
}
