import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import { Creators as AuthActions } from '~/store/ducks/auth'
import { Creators as ErrorActions } from '~/store/ducks/error'

import Yup from '~/helpers/yup'
import api from '~/services/api'
import UploadFile from '~/components/UploadFile'
import { Container } from './styles'
import { Checkbox, Input, Select, AutoComplete } from '~/components/Form'
import Button from '~/components/Button'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import Diseases from '~/helpers/Diseases.json'
import brasilapi from '~/services/brasilapi'

let parseFormats = ['DD/MM/YYYY']

const EditProfileSchema = Yup.object().shape({
  isResponsible: Yup.boolean(),
  user: Yup.object().shape({
    email: Yup.string()
      .email('Digite um email válido')
      .required('Digite um email'),
  }),
  profile: Yup.object().shape({
    full_name: Yup.string().required('Digite o nome.'),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida'),
    gender: Yup.string().required('Selecione o gênero'),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string()
      .removeSpecials()
      .required('Qual o telefone?'),
    rg: Yup.string().required('Digite o RG'),
    cpf: Yup.string()
      .removeSpecials()
      .required('Digite o CPF'),
  }),
  address: Yup.object().shape({
    cep: Yup.string().required('Digite o CEP do o endereço'),
    state: Yup.string().required('Digite o estado'),
    city: Yup.string().required('Digite a cidade'),
    street: Yup.string().required('Qual a rua?'),
    number: Yup.string(),
    complement: Yup.string(),
    district: Yup.string().required('Digite o bairro'),
  }),
  ward: Yup.object().shape({
    isResponsible: Yup.boolean(),
    full_name: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible ? schema.required() : schema
    }),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida')
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema : schema.nullable()
      }),
    gender: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible ? schema.required('Escolha o gênero') : schema
    }),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string()
      .removeSpecials()
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema.required('Qual o telefone?') : schema
      }),
    rg: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible ? schema.required('Digite o RG') : schema
    }),
    cpf: Yup.string()
      .removeSpecials()
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema.required('Digite o CPF') : schema
      }),
  }),
})

export default function Edit({ history }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    // validationSchema: EditProfileSchema,
  })
  const [patient, setPatient] = useState({})
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const isResponsible = watch('isResponsible')
  const diseases = watch('diseases')
  const [defaultDiseases, setDefaultDiseases] = useState([])
  const { id } = useParams()
  const cep = watch('address.cep')
  console.log(cep)
  const [avatarId, setAvatarId] = useState()

  const getPatient = async patientId => {
    setLoading(true)
    const res = await api.get(`/patients/${patientId}`)
    setPatient(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getPatient(id)
  }, [id])

  useEffect(() => {
    register({ name: 'diseases' })
  })

  useEffect(() => {
    if (patient.profile) {
      setValue('isResponsible', patient.isResponsible)
      if (patient.isResponsible) {
        setValue('ward', patient.wards[0]?.profile)
        setValue(
          'ward.birthdate',
          moment(patient.wards[0]?.profile?.birthdate).format('DD/MM/YYYY'),
        )
        setValue('diseases', patient.wards[0]?.profile?.diseases)
        setDefaultDiseases(patient.wards[0]?.profile?.diseases)
      } else {
        setValue('diseases', patient.profile?.diseases)
        setDefaultDiseases(patient.profile?.diseases)
      }
      setValue('profile', patient.profile)
      setValue(
        'profile.birthdate',
        moment(patient.profile?.birthdate).format('DD/MM/YYYY'),
      )
      setValue('address', patient.addresses[0])
      setValue('user.email', patient.email)
    }
  }, [patient?.profile])

  const onSubmit = async data => {
    setLoading(true)
    try {
      data.role = data.isResponsible ? 'responsible' : 'patient'
      // if (data.address.cep) data.address.cep = data.address.cep.replace('-', '')
      if (data.profile.cpf) {
        data.profile.cpf = data.profile.cpf.replace(/'.'/g, '')
        data.profile.cpf = data.profile.cpf.replace(/-/g, '')
      }
      if (avatarId) data.profile.avatar_id = avatarId
      if (data.isResponsible) {
        data.ward.diseases = diseases ? diseases : defaultDiseases
      } else {
        data.profile.diseases = diseases ? diseases : defaultDiseases
      }
      await api.put(`/users/${patient.id}`, data)
      setLoading(false)
      dispatch(AuthActions.getSessionRequest())
      const to = patient.profile?.cpf ? '/paciente/perfil' : '/paciente'
      history.push(to)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoading(false)
    }
  }

  const getAddressByCep = async (value) => {
    try {
      setLoading(true)
      const res = await brasilapi.get(`/api/cep/v1/${value}`)
      if (!res.data.errors) {
        setValue('address.state', res.data.state)
        setValue('address.city', res.data.city)
        setValue('address.street', res.data.street)
        setValue('address.district', res.data.neighborhood)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (cep && cep.length === 9) {
      getAddressByCep(cep.replace('-', ''))
    }
  }, [cep])

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="text--rb mb-3">Informações Pessoais</h2>
        </Col>
      </Row>
      <Row className="m-2">
        <Col>
          {patient.profile?.full_name ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <p className="text--rb">Adicione uma foto de perfil</p>
                  <UploadFile callback={setAvatarId} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Checkbox
                    className="my-3"
                    name="isResponsible"
                    label="Sou o responsável legal do paciente"
                    ref={register}
                    error={errors.isResponsible}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Input
                    type="text"
                    label="Nome Completo"
                    name="profile.full_name"
                    placeholder="Nome"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.full_name}
                    ref={register}
                  />
                </Col>
                <Col lg={6}>
                  <Input
                    type="text"
                    label="Data de nascimento"
                    name="profile.birthdate"
                    placeholder="__/__/____"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.birthdate}
                    ref={register}
                    mask="99/99/9999"
                  />
                </Col>
                <Col lg={6}>
                  <Select
                    type="text"
                    label="Gênero"
                    name="profile.gender"
                    size="md"
                    icon="icon-chevron-down"
                    className="my-3"
                    error={errors.profile?.gender}
                    ref={register}
                    fluid
                  >
                    <option value="m">Masculino</option>
                    <option value="f">Feminino</option>
                    <option value="-">Prefiro não informar</option>
                  </Select>
                </Col>

                <Col lg={6}></Col>

                <Col lg={3}>
                  <Input
                    type="text"
                    label="Celular"
                    name="profile.mobile_number"
                    placeholder="(11) 99999-9999"
                    mask="(99) 99999-9999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.mobile_number}
                    ref={register}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    type="text"
                    label="Telefone Fixo"
                    name="profile.phone_number"
                    placeholder="(11) 99999-9999"
                    mask="(99) 99999-9999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.phone_number}
                    ref={register}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    type="text"
                    label="RG"
                    name="profile.rg"
                    placeholder="9999999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.rg}
                    ref={register}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    type="text"
                    label="CPF"
                    name="profile.cpf"
                    placeholder="123.456.789-10"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.profile?.cpf}
                    ref={register}
                    mask="999.999.999-99"
                  />
                </Col>

                <Col className="my-3" xs={12}>
                  <input
                    type="checkbox"
                    name="ward.isResponsible"
                    ref={register}
                    className="d-none"
                    checked={isResponsible}
                  />
                </Col>

                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="Nome Completo do Paciente"
                    name="ward.full_name"
                    placeholder="Nome"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.full_name}
                    ref={register}
                  />
                </Col>
                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="Data de nascimento do Paciente"
                    name="ward.birthdate"
                    placeholder="__/__/____"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.birthdate}
                    ref={register}
                    mask="99/99/9999"
                  />
                </Col>
                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Select
                    type="text"
                    label="Gênero do Paciente"
                    name="ward.gender"
                    size="md"
                    icon="icon-chevron-down"
                    className="my-3"
                    error={errors.ward?.gender}
                    ref={register}
                    fluid
                  >
                    <option value="m">Masculino</option>
                    <option value="f">Feminino</option>
                    <option value="-">Prefiro não informar</option>
                  </Select>
                </Col>

                <Col
                  className={`${isResponsible ? '' : 'd-none'}`}
                  lg={6}
                ></Col>

                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="Celular do Paciente"
                    name="ward.mobile_number"
                    placeholder="(11) 99999-9999"
                    mask="(99) 99999-9999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.mobile_number}
                    ref={register}
                  />
                </Col>

                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="Telefone Fixo do Paciente"
                    name="ward.phone_number"
                    placeholder="(11) 99999-9999"
                    mask="(99) 99999-9999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.phone_number}
                    ref={register}
                  />
                </Col>

                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="RG do Paciente"
                    name="ward.rg"
                    placeholder="9999999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.rg}
                    ref={register}
                  />
                </Col>

                <Col className={`${isResponsible ? '' : 'd-none'}`} lg={6}>
                  <Input
                    type="text"
                    label="CPF do Paciente"
                    name="ward.cpf"
                    placeholder="123.456.789-10"
                    mask="999.999.999-99"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.ward?.cpf}
                    ref={register}
                  />
                </Col>

                <Col lg={6}>
                  <AutoComplete
                    value={
                      diseases
                        ? diseases
                        : Array.isArray(defaultDiseases)
                        ? defaultDiseases
                        : []
                    }
                    id="diseases"
                    label="Patologia"
                    multiple={true}
                    name="profile.diseases"
                    options={Diseases}
                    onChange={(e, value) => setValue('diseases', value)}
                    className="my-3"
                  />
                </Col>

                <Col className={`my-3`} xs={12}></Col>

                <Col lg={4}>
                  <Input
                    type="text"
                    label="CEP"
                    name="address.cep"
                    placeholder="00000-000"
                    mask="99999-999"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.adresss?.cep}
                    ref={register}
                  />
                </Col>

                <Col lg={4}>
                  <Select
                    type="text"
                    label="UF"
                    name="address.state"
                    size="md"
                    className="my-3"
                    error={errors.address?.state}
                    ref={register}
                    fluid
                  >
                    {StatesBrasil.map(state => (
                      <option value={state.value}>{state.label}</option>
                    ))}
                  </Select>
                </Col>

                <Col lg={4}>
                  <Input
                    type="text"
                    label="Cidade"
                    name="address.city"
                    placeholder=""
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.city}
                    ref={register}
                  />
                </Col>

                <Col lg={4}>
                  <Input
                    type="text"
                    label="Bairro"
                    name="address.district"
                    placeholder=""
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.district}
                    ref={register}
                  />
                </Col>

                <Col lg={6}>
                  <Input
                    type="text"
                    label="Logradouro"
                    name="address.street"
                    placeholder=""
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.street}
                    ref={register}
                  />
                </Col>

                <Col lg={2}>
                  <Input
                    type="text"
                    label="Número"
                    name="address.number"
                    placeholder=""
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.number}
                    ref={register}
                  />
                </Col>

                <Col lg={6}>
                  <Input
                    type="text"
                    label="Complemento"
                    name="address.complement"
                    placeholder=""
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.address?.complement}
                    ref={register}
                  />
                </Col>

                <Col lg={6}></Col>

                <Col lg={6}>
                  <Input
                    type="text"
                    label="Email"
                    name="user.email"
                    placeholder=""
                    size="md"
                    fluid
                    className="mt-5 my-3"
                    error={errors.user?.email}
                    ref={register}
                  />
                </Col>

                <Col xs={12}>
                  <Button
                    type="submit"
                    disabled={loading}
                    className="primary md my-3"
                  >
                    {loading ? 'Carregando...' : 'Continuar'}
                  </Button>
                </Col>
              </Row>
            </form>
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}
