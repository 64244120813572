import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Multiselect } from 'react-widgets'
import Skeleton from 'react-loading-skeleton'


import { Creators as AuthActions } from '~/store/ducks/auth'
import { Creators as ErrorActions } from '~/store/ducks/error'

import Yup from '~/helpers/yup'
import api from '~/services/api'
import UploadAvatar from '~/components/UploadAvatar'
import {CustomInput, CustomSelect, CustomCheckbox} from '~/components'
import {ButtonPrimary} from '~/components/Buttons/PrimaryButton'

import StatesBrasil from '~/helpers/StatesBrasil.json'
import Diseases from '~/helpers/Diseases.json'
import { getAddressByCep } from '~/shared/utils'

let parseFormats = ['DD/MM/YYYY']

const EditProfileSchema = Yup.object().shape({
  isResponsible: Yup.boolean(),
  user: Yup.object().shape({
    email: Yup.string()
      .email('Digite um email válido')
      .required('Digite um email'),
  }),
  profile: Yup.object().shape({
    full_name: Yup.string().required('Digite seu nome.'),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Você não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida'),
    gender: Yup.string().required('Selecione seu gênero'),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string()
      .removeSpecials()
      .required('Qual seu telefone?'),
    rg: Yup.string().required('Digite seu RG'),
    cpf: Yup.string()
      .removeSpecials()
      .required('Digite seu CPF'),
  }),
  address: Yup.object().shape({
    cep: Yup.string().required('Digite o CEP do seu endereço'),
    state: Yup.string().required('Digite o estado que você mora'),
    city: Yup.string().required('Digite a cidade que você mora'),
    street: Yup.string().required('Qual a rua que você mora?'),
    number: Yup.string(),
    complement: Yup.string(),
    district: Yup.string().required('Digite o bairro que você mora'),
  }),
  ward: Yup.object().shape({
    isResponsible: Yup.boolean(),
    full_name: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible ? schema.required() : schema
    }),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Você não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida')
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema : schema.nullable()
      }),
    gender: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible ? schema.required('Escolha seu gênero') : schema
    }),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string().removeSpecials(),
    rg: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible ? schema.required('Digite seu RG') : schema
    }),
    cpf: Yup.string()
      .removeSpecials()
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema.required('Digite seu CPF') : schema
      }),
  }),
})

export default function Edit({ history }) {
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    validationSchema: EditProfileSchema,
  })
  const { session } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [isResponsible, setIsResponsible] = useState(false)
  const cep = watch('address.cep')
  const [diseases, setDiseases] = useState([])
  const [clicked, setClicked] = useState(false)


  useEffect(() => {
    if (cep && cep.length === 9) {
      setLoading(true)
      getAddressByCep(cep).then(res => {
        if (!res.data.errors) {
          setValue('address.state', res.data.state)
          setValue('address.city', res.data.city)
          setValue('address.street', res.data.street)
          setValue('address.district', res.data.neighborhood)
        }
      }).finally(()=> setLoading(false))
    }
  }, [cep])

  useEffect(() => {
    setIsResponsible(session.roles.includes('responsible'))
    if (session.roles.includes('responsible') && session.ward) {
      setValue('ward', session.ward[0])
      setValue(
        'ward.birthdate',
        moment(session.ward[0]?.birthdate).format('DD/MM/YYYY'),
      )
      if (session.ward[0]?.diseases) {
        setDiseases(session.ward[0]?.diseases)
      }
    } else {
      if (session.profile.diseases) {
        setDiseases(session.profile.diseases)
      }
    }

    setValue('profile', session.profile)
    setValue(
      'profile.birthdate',
      moment(session.profile?.birthdate).format('DD/MM/YYYY'),
    )
    setValue('address', session.addresses[0])
    setValue('user.email', session.email)
  }, [session.profile])

  const onSubmit = async data => {
    setLoading(true)
    try {
      data.role = data.isResponsible ? 'responsible' : 'patient'
      if (data.isResponsible) {
        data.ward.diseases = diseases
      } else {
        data.profile.diseases = diseases
      }
      await api.put(`/users/${session.id}`, data)
      setLoading(false)
      dispatch(AuthActions.getSessionRequest())
      const to = session.profile?.cpf ? '/paciente/perfil' : '/paciente'
      history.push(to)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoading(false)
    }
  }

  return (
    <>
      {!session.roles[0] === '' || !session.roles[0] ? (

        <div className='p-5 patient__profile__edit__skeleton'>
          <div className="row">
            <div className="col-lg-12">
              <Skeleton width='50%'/>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-12">
              <Skeleton width='50%'/>
            </div>
          </div>

          <div className='row mt-5'>
            <div className="col-lg-2 col-6 pr-0 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton className='rounded-circle ' width='90px' height='90px'/>
            </div>
            <div className='col-lg-10 col-6 pl-0'>
              <Skeleton className='mt-4 patient__profile__edit__skeleton__mobile__button' width='15%' height='40px' />
            </div>
          </div>


          <div className="row mt-4">
            <div className="col-lg-6 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>
            <div className="col-lg-6">
              <Skeleton width='70%'/>
            </div>
          </div>

          <div className="row mt-4 patient__profile__edit__skeleton__mobile__botton">
            <div className="col-lg-12">
              <Skeleton width='49%'/>
            </div>
          </div>

          <div className="row mt-4 patient__profile__edit__skeleton__mobile__botton">
            <div className="col-lg-3 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>

            <div className="col-lg-3 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>

            <div className="col-lg-3 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>

            <div className="col-lg-3 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>
          </div>

          <div className="row mt-4 patient__profile__edit__skeleton__mobile__botton">
            <div className="col-lg-4 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>
            <div className="col-lg-4 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>
            <div className="col-lg-4 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>
          </div>

          <div className="row mt-4 patient__profile__edit__skeleton__mobile__botton">
            <div className="col-lg-4 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>
            <div className="col-lg-4 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>
            <div className="col-lg-4 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>
          </div>

          <div className="row mt-4 patient__profile__edit__skeleton__mobile__botton">
            <div className="col-lg-6 patient__profile__edit__skeleton__mobile__botton">
              <Skeleton width='100%'/>
            </div>
          </div>


        </div>
      ) : (<div className='container-fluid patient__profile__edit'>
        <div className='row'>
          <div className='col-lg-12'>
            <h1 className="patient__profile__edit__title">Termine seu cadastro</h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className="patient__profile__edit__info__title">Informações Pessoais</h2>
          </div>
        </div>
        <div>
          <div>
            {session.profile.full_name ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                  <div className='col-lg-12'>
                    <p>Adicione uma foto de perfil</p>
                    <UploadAvatar />
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-lg-6'>
                    <CustomInput
                      label='Nome completo'
                      inputName="profile.full_name"
                      error={errors.profile?.full_name}
                      register={register}
                    />


                  </div>
                  <div className='col-lg-4'>
                    <CustomInput
                      label="Data de nascimento"
                      inputName="profile.birthdate"
                      inputPlaceholder="__/__/____"
                      error={errors.profile?.birthdate}
                      register={register}
                      mask="99/99/9999"

                    />

                  </div>

                  <div className='col-lg-6'>
                    <CustomSelect
                      label="Gênero"
                      inputName="profile.gender"
                      error={errors.profile?.gender}
                      register={register}
                      inputClass='gender__select'
                    >
                      <option value="m">Masculino</option>
                      <option value="f">Feminino</option>
                      <option value="-">Prefiro não informar</option>
                    </CustomSelect>
                  </div>

                </div>
                <div className="row">
                  <div className='col-lg-3'>
                    <CustomInput
                      label="Celular"
                      inputName="profile.mobile_number"
                      inputPlaceholder="(11) 99999-9999"
                      mask="(99) 99999-9999"
                      error={errors.profile?.mobile_number}
                      register={register}
                    />

                  </div>

                  <div className='col-lg-3'>
                    <CustomInput
                      label="Telefone Fixo"
                      inputName="profile.phone_number"
                      inputPlaceholder="(11) 99999-9999"
                      mask="(99) 99999-9999"
                      error={errors.profile?.phone_number}
                      register={register}
                    />
                  </div>

                  <div className='col-lg-3'>
                    <CustomInput
                      label="RG"
                      inputName="profile.rg"
                      inputPlaceholder="9999999"
                      error={errors.profile?.rg}
                      register={register}

                    />

                  </div>

                  <div className='col-lg-3'>
                    <CustomInput
                      label="CPF"
                      inputName="profile.cpf"
                      inputPlaceholder="123.456.789-10"
                      error={errors.profile?.cpf}
                      register={register}
                      mask="999.999.999-99"
                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='my-3' ></div>
                  <div className='col-lg-4'>
                    <CustomInput
                      label="CEP"
                      inputName="address.cep"
                      inputPlaceholder="00000-000"
                      error={errors.address?.cep}
                      register={register}
                      mask='99999-999'
                    />
                  </div>

                  <div className='col-lg-4'>
                    <CustomSelect
                      label="UF"
                      inputName="address.state"
                      error={errors.address?.state}
                      register={register}
                    >
                      {StatesBrasil.map(state => (
                        <option value={state.value}>{state.label}</option>
                      ))}

                    </CustomSelect>
                  </div>

                  <div className='col-lg-4'>
                    <CustomInput
                      label="Cidade"
                      inputName="address.city"
                      error={errors.address?.city}
                      register={register}
                    />
                  </div>

                  <div className='col-lg-4'>
                    <CustomInput
                      label="Bairro"
                      inputName="address.district"
                      error={errors.address?.district}
                      register={register}
                    />

                  </div>

                  <div className='col-lg-6'>
                    <CustomInput
                      label="Logradouro"
                      inputName="address.street"
                      error={errors.address?.street}
                      register={register}

                    />
                  </div>

                  <div className='col-lg-2'>
                    <CustomInput
                      label="Número"
                      inputName="address.number"
                      error={errors.address?.number}
                      register={register}

                    />
                  </div>

                  <div className='col-lg-6'>
                    <CustomInput
                      label="Complemento"
                      inputName="address.complement"
                      error={errors.address?.complement}
                      register={register}
                    />
                  </div>

                </div>

                <div className="row">
                  <div className='col-lg-6'>
                    <CustomInput
                      label="Email"
                      inputName="user.email"
                      error={errors.user?.email}
                      register={register}
                    />
                  </div>
                </div>

                <div className="row my-5">
                  <div className='col-lg-12'>
                    <CustomCheckbox
                      inputName="isResponsible"
                      register={register}
                      callBack={() => setIsResponsible(false)}
                      inputChecked={!isResponsible}
                      inputValue={false}
                      label="Busco tratamento para mim"
                    />
                    <CustomCheckbox
                      inputName="isResponsible"
                      register={register}
                      inputValue={true}
                      callBack={() => setIsResponsible(true)}
                      inputChecked={isResponsible}
                      label="Sou responsável por um paciente"
                    />
                  </div>
                </div>

                <div className={`row ${isResponsible ? '' : 'd-none'}`}>
                  <div className='col-lg-12'>
                    <h2 className="patient__profile__edit__info__title">
                      Informações do Paciente sob sua responsabilidade
                    </h2>
                  </div>

                  <div className="my-3">
                    <input
                      type="checkbox"
                      name="ward.isResponsible"
                      ref={register}
                      className="d-none"
                      checked={isResponsible}
                    />
                  </div>

                  <div className='col-lg-6'>
                    <CustomInput
                      label="Nome Completo do Paciente"
                      inputName="ward.full_name"
                      inputPlaceholder="Nome"
                      error={errors.ward?.full_name}
                      register={register}
                    />

                  </div>
                  <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                    <CustomInput
                      label="Data de nascimento do Paciente"
                      inputName="ward.birthdate"
                      inputPlaceholder="__/__/____"
                      error={errors.ward?.birthdate}
                      register={register}
                      mask="99/99/9999"
                    />
                  </div>
                  <div  className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                    <CustomSelect
                      label="Gênero do Paciente"
                      inputName="ward.gender"
                      error={errors.ward?.gender}
                      register={register}
                    >
                      <option value="m">Masculino</option>
                      <option value="f">Feminino</option>
                      <option value="-">Prefiro não informar</option>
                    </CustomSelect>
                  </div>

                  <div
                    className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}
                  ></div>

                  <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                    <CustomInput
                      label="Celular do Paciente"
                      inputName="ward.mobile_number"
                      inputPlaceholder="(11) 99999-9999"
                      mask="(99) 99999-9999"
                      error={errors.ward?.mobile_number}
                      register={register}
                    />
                  </div>

                  <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                    <CustomInput
                      label="Telefone Fixo do Paciente"
                      inputName="ward.phone_number"
                      inputPlaceholder="(11) 99999-9999"
                      mask="(99) 99999-9999"
                      error={errors.ward?.phone_number}
                      register={register}
                    />
                  </div>

                  <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                    <CustomInput
                      label="RG do Paciente"
                      inputName="ward.rg"
                      inputPlaceholder="9999999"
                      error={errors.ward?.rg}
                      register={register}

                    />
                  </div>

                  <div className={`col-lg-6 ${isResponsible ? '' : 'd-none'}`}>
                    <CustomInput
                      label="CPF do Paciente"
                      inputName="ward.cpf"
                      inputPlaceholder="123.456.789-10"
                      mask="999.999.999-99"
                      error={errors.ward?.cpf}
                      register={register}

                    />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-lg-12'>
                    <h2 className="patient__profile__edit__info__title">Sobre o tratamento</h2>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <label htmlFor="diseases" style={{ 'font-size': '16px' }}>
                      Patologia
                    </label>
                    <Multiselect
                      id="diseases"
                      className={`${errors.diseases && 'border-danger border'}`}
                      style={{borderRadius: '4px'}}
                      data={Diseases}
                      value={
                        diseases
                      }
                      onChange={value => setDiseases(value)}
                    />

                    {diseases.length === 0 && clicked && (
                      <span className="text-danger">
                      Campo Patologia é obrigatório
                    </span>
                    )}
                  </div>
                </div>

                <div className='row'>
                  <div className='col-sm-3'>
                    <ButtonPrimary
                      btnType={
                        diseases
                          ? diseases.length > 0
                          ? 'submit'
                          : 'button'
                          : 'button'
                      }
                      disabled={loading}
                      callBack={()=>setClicked(true)}
                      text={loading ? 'Carregando...' : 'Continuar'}
                      width='w-100'
                    >

                    </ButtonPrimary>
                  </div>
                </div>
              </form>
            ) : null}
          </div>
        </div>
      </div>)}

    </>
  )
}
