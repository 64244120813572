import { call, put } from 'redux-saga/effects'
import api from '~/services/api'
import { push } from 'connected-react-router'

import { setToken, removeToken, isAuthenticated } from '~/services/auth'
import { Creators as AuthActions } from '~/store/ducks/auth'
import { Creators as ErrorActions } from '~/store/ducks/error'
import TagManager from 'react-gtm-module'

export function* login(action) {
  try {
    const response = yield call(api.post, '/sessions', action.post)
    if (response){
      if (response.data){
        localStorage.setItem('roles', response.data.session.roles[0])
      }
    }
    yield put(AuthActions.storeSessionSuccess(response.data))
    setToken(response.data.token)

    const urlRedirect = localStorage.getItem('@DrCannabis:URL_AFTER_LOGIN')
    if (urlRedirect) {
      yield put(push(urlRedirect))
      localStorage.removeItem('@DrCannabis:URL_AFTER_LOGIN')
    } else {
      yield put(push('/dashboard'))
    }

    const tagManagerArgs = {
      gtmId: 'GTM-59529VR',
      dataLayer: {
        event: 'login',
        userId: response.data.session.profile.id,
        role: response.data.session.roles[0],
        name: response.data.session.profile.full_name,
        isRegisteredUser: true,
      }
    }
    TagManager.dataLayer(tagManagerArgs);

  } catch (error) {
    if (error.response) {
      yield put(ErrorActions.setError('Login ou senha inválidos.'))
    } else {
      yield put(ErrorActions.setError('', error))
    }
  }
}

export function* getSession() {
  try {
    if (isAuthenticated()) {
      const response = yield call(api.get, '/sessions')
      yield put(AuthActions.getSessionSuccess(response.data))
    }
  } catch (error) {
    removeToken()
    yield put(
      ErrorActions.setError('Autenticação expirou, logue-se novamente.'),
    )
    yield put(AuthActions.logout())
  }
}
