import React from 'react'

import { Container } from 'react-bootstrap'
import { Section } from './styles'

export default function Terms() {
  return (
    <Section>
      <Container classname=" dark-blue my-5">
        <h1>Termos de Uso</h1>

        <h3 class="mt-5 mb-3">Aceitação do Acordo</h3>

        <p>
          Por favor, leia este Acordo de Termos de Uso (“Contrato”)
          cuidadosamente antes de usar o conteúdo e serviços no site do DR.C
          SERVIÇOS E CONTEÚDOS INFORMATIVOS E EDUCACIONAIS LTDA. (
          <a href="http://www.drcannabis.com.br" className="lavender">
            Dr. Cannabis
          </a>
          ) com e todos os microsites incluídos nele (coletivamente, referido
          como o “Site”). Ao utilizar o Site, você concorda que leu e aceitou os
          termos deste Contrato. Se você não concordar com os termos deste
          Contrato, Você pode descontinuar o uso do Site. Qualquer uso que
          contradiga os termos deste Contrato não é autorizado. O termo "Você"
          refere-se ao usuário, médico, leitores, assinantes, profissionais de
          saúde ou pessoa física ou jurídica que acessa o Site.
        </p>

        <h3 class="mt-5 mb-3">Escopo</h3>

        <p>
          Este Contrato rege o uso do Site, qualquer conteúdo, incluindo,
          publicações, publicações digitais, mensagens, textos, arquivos, dados,
          informações, imagens, gráficos, fotografias, clipes de áudio, sons,
          vídeos ou outros materiais e o design e organização desses itens
          (coletivamente, referidos como o "Conteúdo") que podem estar
          disponíveis através do Site e quaisquer serviços que possam ser
          fornecidos através do Site ("Serviços"). Este Contrato autoriza o uso
          do Site somente para seus fins pessoais e comerciais. Se você fizer
          uma cópia de qualquer Conteúdo para uso pessoal, você concorda em não
          remover quaisquer direitos autorais e outros avisos de propriedade
          contidos no Conteúdo original. Você não pode copiar, reproduzir,
          publicar, transmitir, vender, licenciar, modificar, exibir
          publicamente, executar publicamente, distribuir ou de qualquer forma
          explorar o Website sem o consentimento prévio por escrito da Dr.
          Cannabis. O Site é projetado e comercializado para uso adulto ou uso
          com supervisão de um adulto. Pessoas com menos de 18 (dezoito) anos de
          idade não estão autorizados ou autorizados a usar o Site sem a
          supervisão de seus pais ou responsável legal.
        </p>

        <h3 class="mt-5 mb-3">Plataforma Dr. Cannabis</h3>

        <p>
          É uma plataforma digital que permite o contato direto entre usuários,
          médicos, pacientes e prestadores de serviços interessados ou não em
          contratar os Serviços, bem como interagir e trocar informações sobre o
          uso medicinal do Cannabis Sativa para doenças como epilepsia,
          esclerose múltipla, autismo, crônicas entre outras e não se limitando
          a elas (“Plataforma”).
        </p>

        <h3 class="mt-5 mb-3">Conteúdo publicado por Você</h3>

        <p>
          O Site oferece comentários de histórias, experiências, galerias de
          fotos e outras áreas interativas onde Você pode expressar opiniões e
          compartilhar ideias e informações.
        </p>

        <p>
          A Dr. Cannabis é uma empresa especializada em conteúdo sobre cannabis
          medicinal e acolhimento a todos os atores envolvidos na terapêutica
          canábica, que conecta leitores, anunciantes, pacientes, profissionais,
          desenvolvendo troca de informações, disseminação de conteúdo sobre a
          utilização da Cannabis para fins exclusivamente medicinais. Você
          reconhece e concorda que todas as observações, sugestões, ideias,
          matérias informativas, gráficos ou outras informações comunicadas e
          submetidas por Você (coletivamente, "Matérias") à Dr. Cannabis se
          tornam propriedade do Dr. Cannabis e podem ser usadas nas publicações
          da Dr. Cannabis (após autorização expressa e específica para esse
          fim). Você reconhece e concorda que a Dr. Cannabis não é obrigada a
          tratar tais Matérias como confidenciais. A Dr. Cannabis se reserva o
          direito de divulgar suas informações pessoalmente identificáveis, em
          caso de exigência legal e/ou para cumprir com ordens judiciais. Você
          reconhece e concorda que Dr. Cannabis não é responsável por quaisquer
          ideias, incluindo sem limite, produto, serviço, Matéria ou ideias de
          publicidade, comunicadas por Você à Dr. Cannabis. Você é inteiramente
          responsável por todos as Matérias e conteúdo que Você publica, envia
          por e-mail ou disponibiliza através do Site. Você concorda em
          indenizar, defender e isentar a Dr. Cannabis, de e contra todas e
          quaisquer reivindicações, responsabilidades, danos, perdas, custos,
          despesas ou honorários (incluindo honorários advocatícios razoáveis)
          que outros possam incorrer como resultado ou decorrentes de todos os
          conteúdos ou Matérias publicadas por Você neste Site. Você também
          concorda que sob nenhuma circunstância a Dr. Cannabis será responsável
          de qualquer forma por qualquer Matéria ou por qualquer perda ou dano
          de qualquer tipo incorrido como resultado do uso de quaisquer Matérias
          publicadas, enviadas por e-mail ou disponibilizadas por Você através
          do Site. Ao enviar ou postar envios no Site, você declara e garante:
          <strong>(i)</strong> Você tem todos os direitos necessários,
          incluindo, mas não se limitando a, direitos autorais, aos envios que
          Você fornece e os direitos de usá-lo, conforme estabelecido neste
          Contrato; todas as informações fornecidas são verdadeiras, precisas,
          atuais e completas e não violam os termos deste Contrato; e,{' '}
          <strong>(ii)</strong> as Matérias não causarão danos a qualquer pessoa
          ou entidade.
        </p>
        <p>
          A Dr. Cannabis não terá qualquer obrigação de fiscalizar as
          informações fornecidas por Você ao Site, mas poderá excluir a
          informações que forem comprovadamente inverídicas ou ofensivas, a
          pedido do interessado.
        </p>
        <p>
          É vedado a Você, paciente, médico ou outro, <strong>(i)</strong> a
          utilização do Site ou a divulgação ou submissão de informações que de
          qualquer forma possa implicar em violação de normas aplicáveis no
          Brasil, de direitos da Dr. Cannabis e de terceiros ou aos bons
          costumes, incluindo, sem limitação, a violação de direitos
          intelectuais, autorais e de privacidade, ou a produção e divulgação de
          Conteúdo ilegal, imoral, inapropriado ou ofensivo;
          <strong>(ii)</strong> a utilização do Site para finalidade diversa
          daquela intencionada pela Dr. Cannabis;
          <strong>(iii)</strong> a utilização do Site relacionando-o a
          atividades que desrespeitem direitos de propriedade intelectual, ou
          qualquer outra violação a direitos de terceiros, comprometendo-se o
          usuário a não divulgar, enviar por e-mail, ou disponibilizar de
          qualquer outro modo, Conteúdo, informações e/ou anúncios:{' '}
          <strong>(a)</strong>
          ilícitos; <strong>(b)</strong> protegidos por direitos autorais,
          segredo comercial, industrial ou de terceiros; a menos que Você tenha
          permissão do titular de tais direitos para divulgar o tais Conteúdos,
          informações e anúncios;
          <strong>(c)</strong> nocivos, abusivos, difamatórios, pornográficos,
          libidinosos ou que de qualquer forma representem assédio, invasão de
          privacidade ou risco a menores; <strong>(d)</strong> que representem
          assédio, degradação, intimidação ou ódio em relação a um indivíduo ou
          grupo de indivíduos com base na religião, sexo, orientação sexual,
          raça, origem étnica, idade ou deficiência; <strong>(e)</strong> que
          incluam informações pessoais ou que permitam a identificação de
          terceiro sem seu expresso consentimento; <strong>(f)</strong> falsos,
          fraudulentos, enganosos ou que representem venda casada ou promoção
          enganosa; <strong>(g)</strong> que representem ou contenham marketing
          de associação ilícito, referência a link, spam, correntes ou esquemas
          de pirâmide;
          <strong>(h)</strong> que representem ou contenham qualquer forma de
          propaganda, campanhas promocionais e/ou de caráter comercial; e{' '}
          <strong>(i)</strong> que divulguem, mencionem ou façam apologia a
          quaisquer serviços ilegais ou venda de quaisquer itens cujo comércio e
          propaganda sejam proibidos ou restritos por lei.
        </p>

        <h3 class="mt-5 mb-3">Serviços</h3>

        <p>
          Os serviços oferecidos neste Site, através de terceiros (médicos,
          clínicas, laboratórios) consistem em atendimento médico, venda e
          informações sobre medicamentos controlados à base da cannabis
          (“Serviços”). Os Serviços da Plataforma Dr. Cannabis estarão
          disponíveis apenas para as pessoas que tenham capacidade legal para
          utilizá-lo. Não poderão utilizá-los, portanto, pessoas que não tenham
          capacidade, incluindo, porém não se limitando a menor de idade, ou
          pessoas que tenham sido inabilitadas pelo Dr. Cannabis, por razões
          específicas temporária ou definitivamente.
        </p>
        <p>
          A utilização do Site e dos Serviços nele disponibilizados são
          permitidas a Você no estado em que se encontra, em caráter pessoal e
          unicamente para fins lícitos, sempre relacionados à utilização
          medicinal de produtos à base da substância <em>Cannabis</em>.
        </p>

        <h3 class="mt-5 mb-3">Consultas e compra de medicamentos</h3>

        <p>
          Através da Plataforma Dr. Cannabis, o paciente poderá marcar
          consultas, consultar conteúdo e informações, efetuar a compra de
          medicamentos direto ou tendo a plataforma como intermediário para
          compra em site ou outro canal de venda de parceiros habilitados.
        </p>

        <p>
          Todos os dados pessoais fornecidos por Você ao utilizar o nosso site
          ou criar o seu cadastro para acessar os nossos Serviços são tratados e
          processados conforme as normas legais, em especial a Lei 13.709/18
          (Lei Geral de Proteção de Dados Pessoais - LGPD), conforme descrito na
          Política de Privacidade disponível em nosso site - QUE DEVE SER LIDA
          EM CONJUNTO COM ESTE TERMO.
        </p>

        <h3 class="mt-5 mb-3">Formas de Pagamento</h3>

        <p>
          A forma de pagamento dos medicamentos difere a cada fornecedor de
          medicamento e estará expressamente informada na plataforma ou outros
          meios de comunicação eletrônica com os pacientes.
        </p>

        <h3 class="mt-5 mb-3">Comunicações Eletrônicas</h3>

        <p>
          Você consente em receber comunicações eletrônicas e concorda que todos
          acordos, avisos, divulgações e outras comunicações que fornecemos a
          Você de forma eletrônica, via e-mail e no Site. Para entender melhor
          como tratamos os seus dados pessoais, as finalidades e as bases legais
          para o tratamento, recomendamos que consulte a nossa Política de
          Privacidade disponível em nosso Site.
        </p>

        <h3 class="mt-5 mb-3">Alteração dos Termos deste Contrato</h3>

        <p>
          O Dr. Cannabis reserva-se o direito, a seu exclusivo critério, de
          alterar os termos deste Contrato. A versão mais atual dos Termos
          substituirá todos as versões anteriores. O Dr. Cannabis recomenda a
          Você, a revisão periódica dos termos deste Contrato para que Você se
          mantenha atualizado. O Dr. Cannabis poderá, por mera liberalidade,
          informar à Você sobre alterações significativas neste Contrato,
          através de avisos na página principal do Site.
        </p>

        <h3 class="mt-5 mb-3">
          Links para sites de terceiros/serviços de terceiros
        </h3>

        <p>
          O Dr. Cannabis pode conter links para outros sites ("Sites
          Vinculados"). Os Sites Vinculados não estão sob o controle do Dr.
          Cannabis e, portanto, o Dr. Cannabis não é responsável pelo conteúdo
          de qualquer link de terceiro, incluindo, sem limitação, qualquer link
          contido em um Site Vinculado. O Dr. Cannabis está fornecendo esses
          links para você apenas como uma conveniência, e a inclusão de qualquer
          link não implica o endosso do Dr. Cannabis ao Site Vinculado ou
          qualquer associação com seus operadores. Alguns serviços
          disponibilizados via Dr. Cannabis são fornecidos por parceiros e
          terceiros. Ao utilizar qualquer produto, serviço ou funcionalidade
          proveniente do site de terceiro no domínio Dr. Cannabis, você
          reconhece e concorda que o Dr. Cannabis pode compartilhar tais
          informações e dados com qualquer terceiro com quem o Dr. Cannabis
          tenha uma relação contratual, fornecimento de serviço ou
          funcionalidade, conforme descrito com detalhes na Política de
          Privacidade disponível em nosso site.
        </p>

        <h3 class="mt-5 mb-3">Usuários Internacionais</h3>

        <p>
          O Serviço é controlado, operado e administrado pelo Dr. Cannabis de
          nosso escritório dentro do território brasileiro. Se Você acessa o
          Serviço de um local fora do território brasileiro, Você é responsável
          pelo cumprimento de todas as leis locais. Você concorda que não usará
          o Conteúdo do Dr. Cannabis em País ou localidade em que o Conteúdo do
          Dr. Cannabis seja, de qualquer maneira, proibida por lei, restrições
          ou regulamentos.
        </p>

        <h3 class="mt-5 mb-3">Dos Direitos do Dr. Cannabis</h3>

        <p>
          O Dr. Cannabis poderá, a seu critério, a qualquer tempo e sem a
          necessidade de comunicação prévia à Você:
          <strong>(i)</strong> suspender, modificar ou encerrar as atividades do
          Site; <strong>(ii)</strong> encerrar ou suspender, total ou
          parcialmente, o acesso do usuário ao Site, quando referido acesso ou
          cadastro violar as condições estabelecidas neste Contrato;
          <strong>(iii)</strong> excluir, total ou parcialmente, as informações
          cadastradas por usuário que não estejam em consonância com as
          disposições deste Contrato ou com a Política de Privacidade disponível
          em nosso site; <strong>(iv)</strong> acrescentar, excluir ou modificar
          os Conteúdos oferecidos no Site.
        </p>

        <h3 class="mt-5 mb-3">Vigência e Rescisão</h3>

        <p>
          Este Contrato vigerá por prazo indeterminado. O Dr. Cannabis
          reserva-se o direito, a seu exclusivo critério, encerrar seu acesso ao
          Site e à serviços relacionados ou qualquer parte dele, a qualquer
          momento, sem aviso prévio.
        </p>

        <h3 class="mt-5 mb-3">Vínculo</h3>

        <p>
          Você concorda que não existe nenhuma vínculo societário, joint
          venture, relação de emprego ou qualquer relação de agência entre você
          e o Dr. Cannabis como resultado deste acordo ou uso do Site. Se
          qualquer parte deste Contrato for considerada inválida ou inexequível
          de acordo com a lei aplicável, incluindo, mas não se limitando a,
          isenções de garantia e limitações de responsabilidade estabelecidas
          acima, a cláusula inválida ou inexequível será considerada substituída
          por uma disposição válida e exequível que mais se aproxima da intenção
          do original provisão e o restante do acordo continuará em vigor.
        </p>

        <h3 class="mt-5 mb-3">Integralidade e Validade deste Contrato</h3>

        <p>
          A menos que especificado de outra forma neste instrumento particular,
          este Contrato constitui o contrato integral entre Você e o Dr.
          Cannabis em relação ao Site e substitui todos os anteriores. Uma
          versão impressa deste Contrato e de qualquer aviso dado em formulário
          eletrônico será admissível em processos judiciais ou administrativos
          baseados ou relacionados a este Contrato na mesma medida e sujeito às
          mesmas condições que outros negócios documentos e registros
          originalmente gerados e mantidos em formato impresso.
        </p>

        <h3 class="mt-5 mb-3">Utilização do Conteúdo</h3>

        <p>
          Os elementos e/ou ferramentas encontrados no Site, não podem ser
          usados, copiados, reproduzidos, distribuídos, transmitidos,
          difundidos, exibidos, vendidos, licenciados ou, de outro modo,
          explorados para quaisquer fins, sem o consentimento prévio e por
          escrito da Dr. Cannabis. Desta forma, Você se compromete a não usar,
          reproduzir ou distribuir quaisquer elementos e/ou ferramentas que não
          sejam expressamente permitidos pelo Dr. Cannabis – inclusive o uso,
          reprodução ou distribuição para fins comerciais das informações e/ou
          Conteúdos extraídos do Site, sob pena de arcar com sanções cíveis,
          penais e criminais cabíveis. Caso Você faça qualquer cópia, seja ela
          via download ou impressão, dos elementos e/ou ferramentas do Dr.
          Cannabis para uso exclusivamente pessoal, deverá preservar todos os
          direitos de propriedade intelectual inerentes ao conteúdo copiado e/ou
          extraído do Site. Você concorda em não burlar, desativar ou, de alguma
          forma, interferir em recursos e/ou ferramentas relacionados à
          segurança do site, sob pena de incorrer nas medidas judiciais
          cabíveis.
        </p>

        <h3 class="mt-5 mb-3">Privacidade das Informações</h3>

        <p>
          As informações ou os dados pessoais cedidos por Você à Dr. Cannabis
          são armazenados em servidores ou meios magnéticos de alta segurança. A
          Dr. Cannabis tomará todas as medidas cabíveis para honrar seu
          compromisso de confidencialidade e segurança junto a Você, mas não se
          responsabilizará por qualquer prejuízo que possa ser derivado da
          violação dessas medidas por parte de terceiros que utilizem de meios
          indevidos, fraudulentos ou ilegais para acessar as informações
          armazenadas nos servidores ou nos bancos de dados utilizados pela Dr.
          Cannabis. Caso ocorram incidentes com relação aos dados pessoais
          tratados pela Dr. Cannabis, Você será devidamente informado e todas as
          medidas de segurança elencadas em nossa Política de Privacidade serão
          tomadas, incluindo a comunicação à Autoridade competente. A Dr.
          Cannabis poderá, a seu critério, utilizar ou ceder os dados fornecidos
          por Você e as informações relativas ao uso da Plataforma, para
          quaisquer finalidades, incluindo, mas não se limitando a, fins
          comerciais, publicitários, educativos e jornalísticos, utilizando,
          quando necessário, nomes fictícios. A Dr. Cannabis também poderá
          utilizar os dados dos usuários, pacientes, médicos e parceiros para
          análises, estudos, elaboração de relatórios, com o objetivo manter ou
          melhorar o funcionamento e o desenvolvimento da Plataforma e dos
          Serviços oferecidos, conforme explicado com detalhes na Política de
          Privacidade disponível em nosso Site. A Dr. Cannabis, com base nas
          hipóteses legais descritas na Política de Privacidade, manterá em seu
          banco de dados todas as informações coletadas dos usuários que
          excluirem seus cadastros. O login e a senha de cada usuário servem
          para garantir a privacidade e a sua segurança. A Dr. Cannabis
          recomenda a Você que não compartilhem essas informações com terceiros.
          A Dr. Cannabis não se responsabiliza por eventuais danos ou prejuízos
          causados a Você pelo compartilhamento dessas informações.
        </p>

        <p>
          A Dr. Cannabis segue padrões comuns de segurança utilizados pelas
          empresas que trabalham com tráfego e armazenamento de dados para
          garantir a segurança de seus usuários. No entanto, nenhum método de
          transmissão ou retenção de dados eletrônicos é plenamente seguro e
          pode estar sujeito a ataques externos. No entanto, adotamos todas as
          medidas de prevenção dos riscos e mitigação dos danos em caso de
          incidentes, conforme descrito na Política de Privacidade disponível em
          nosso Site.
        </p>

        <h3 class="mt-5 mb-3">Lei e Foro Aplicáveis</h3>

        <p>
          Este Contrato será interpretado exclusivamente segundo a legislação
          brasileira. As partes elegem o Foro da Comarca de São Paulo, Estado de
          São Paulo, como o único competente para dirimir qualquer litígio
          resultante deste Contrato.
        </p>

        <h5 class="mt-5 mb-3">Contate-nos</h5>
        <p>Dr. Cannabis: contato@drcannabis.com.br</p>
        <p class="mt-5">
          <em>Em vigor a partir de 13 de abril de 2018.</em>
        </p>
      </Container>
      <Container classname=" dark-blue my-5">
        <h1>Termos e Condições de Uso Telemedicina</h1>

        <p>
          DR. C SERVIÇOS E CONTEÚDOS INFORMATIVOS E EDUCACIONAIS LTDA., com sede
          na cidade de São Paulo, Estado de São Paulo, na Rua Claudio Soares, nº
          72, 10º andar, unidade 1013, Edifício Ahead, Pinheiros, CEP 05414-012,
          neste ato representada na forma de seu contrato social (“Dr. C.”),
          pessoa jurídica de direito privado responsável pela plataforma
          doravante denominada Filazero (“Plataforma” ou “Filazero”).
        </p>
        <p>
          Por intermédio destes Termos e Condições Gerais de Uso (“Termos”), a
          Dr. C. apresenta aos usuários (“Usuários”) as condições essenciais
          para o uso dos serviços oferecidos na Plataforma.
        </p>

        <h3 class="mt-5 mb-3">Definições</h3>

        <p>
          Para fins do presente Termo, sem prejuízo das demais nomenclaturas
          nomeadas ao longo do presente instrumento, os termos abaixo deverão
          ter o seguinte significado:
        </p>
        <p>
          “Telemedicina”. É o exercício da Medicina à distância, cujas
          intervenções, diagnósticos, decisões de tratamentos e recomendações
          estão baseadas em dados, documentos e outras informações transmitidas
          através de sistemas de telecomunicação. Em nosso sistema,
          disponibilizamos a teleconferência.
        </p>
        <p>
          “Usuário”. Qualquer pessoa física ou jurídica que acessar o site ou
          que efetue o cadastro como Usuário Paciente ou como Médico;
        </p>
        <p>
          “Médico” e/ou “Profissional da Saúde”. Pessoa física, terceiro
          independente na prestação de serviços de saúde, cadastrado como
          “Médico” na Dr. C, devidamente inscrito e ativo em seu Conselho
          Regional de Classe (CRM se médico, COREN se enfermeiro, CRP se
          psicólogo, CRN se nutricionista e CRMV se veterinário) do Estado da
          Federação em que atua, habilitado a atender chamadas de
          Teleconferência com seu paciente.
        </p>
        <p>
          “Usuário Paciente”. Pessoa física, capaz de realizar Teleconferência
          em consulta, com seu Profissional de Saúde cadastrado como Médico na
          Dr. C.
        </p>
        <p>
          “Usuário Pessoa Jurídica”. Pessoa jurídica de direito, independente na
          prestação de serviços de saúde, cadastrado como Usuário Pessoa
          Jurídica na Dr C, devidamente inscrito no Conselho Regional de Classe
          (CRM, se hospitais, clínicas médicas, instituições, operadoras de
          saúde, dentre outras pertinentes ao serviço médico; CRP se clínica de
          psicologia; CRN se serviço de prestação nutricional) do(s) Estado(s)
          da Federação em que atua, habilitado a disponibilizar acesso a sua
          Equipe de Saúde, habilitada a atender chamadas de Teleconferência com
          seus pacientes.
        </p>
        <p>
          “Teleconferência”. trata-se da forma de comunicação entre o Usuário
          Paciente e o usuário saúde através da internet;
        </p>
        <p>
          “Filazero”. É uma plataforma disponibilizada pela Dr. C disponível na
          nuvem que conecta as pessoas aos serviços das empresas de forma
          eficiente, eliminando a espera improdutiva nas filas em diversos
          segmentos de mercado. Além disso, melhora a experiência dos clientes
          com o atendimento e auxilia as empresas a melhorar a qualidade se seus
          serviços e gerenciar seus recursos.
        </p>
        <p>
          “Minha Conta”. Conta de acesso e credencial definida pelo Usuário,
          mediante cadastro de login e senha, pessoal e intransferível, que
          permite acesso à área restrita e às funcionalidades exclusivas no
          Site;
        </p>
        <p>
          “Agenda ou Agendamento”. São as datas e horários disponibilizados pelo
          Médico e/ou Profissional de Saúde, para atender via Teleconferência o
          Usuário Paciente. dentro destas, o Usuário Paciente inicia a
          teleconferência com o seu Médico.
        </p>
        <p>
          “Equipe de Saúde”. Qualquer Médico e/ou Profissional de Saúde inserido
          no sistema através do Usuário Pessoa Jurídica e diretamente vinculado
          a ele.
        </p>
        <p>
          “Ficha de Saúde”. Produto da Teleconferência entre Usuário Paciente e
          seu respectivo Usuário saúde, que deverá fazer parte do Prontuário de
          Saúde Eletrônico.
        </p>
        <p>
          “Conteúdo”: todo e qualquer texto, gráficos, imagens, música, áudio,
          vídeo, informação ou outros materiais disponibilizados na Plataforma,
          independentemente de ser Conteúdo de Usuário ou Conteúdo da Dr. C.
        </p>
        <p>
          “Conteúdo de Médico e/ou de Profissional de Saúde”: todo e qualquer
          conteúdo que um Médico e/ou Profissional de Saúde postar, transferir,
          publicar, apresentar ou transmitir para ser disponibilizado através da
          Plataforma.
        </p>
        <p>
          “Conteúdo da Dr. C”: todo e qualquer conteúdo que a Dr. C
          disponibiliza através do Site, incluindo qualquer conteúdo licenciado
          de terceiros, mas excluindo Conteúdo de Usuários.
        </p>
        <p>“Link”, Terminologia para endereço de Internet.</p>
        <p>
          “Serviços”. Significam o serviço fornecido pelo Filazero, que permite
          a descoberta de unidade de negócio de diversas empresas, permite o
          agendamento online de atendimento nessas empresas através da emissão
          de um ticket, e acompanhamento desse ticket com a previsibilidade do
          atendimento, assim como a possibilidade de avaliar tal atendimento.
        </p>
        <p>
          Por intermédio destes Termos e Condições Gerais de Uso (“Termos”), a
          Dr. C apresenta aos usuários (“Usuários”) as condições essenciais para
          o uso dos serviços oferecidos na Plataforma.
        </p>

        <h3 class="mt-5 mb-3">Objetivo</h3>

        <p>
          A Plataforma disponibiliza Consultório Virtual Filazero, por meio do
          endereço eletrônico único para cada clínica ou médico, desenvolvido
          exclusivamente para atendimento médico e consultas na área da saúde,
          por meio do qual é possível que os Usuários e o Médico e/ou
          Profissional de Saúde realizem consultas por meio de Teleconferência,
          assegurando o sigilo e a privacidade exigidos pelo Código de Ética
          Médica viabilizando que os Usuários tenham acesso à serviços de
          médicos independentemente do local onde estejam.
        </p>
        <p>
          A Plataforma disponibiliza um canal eletrônico de comunicação segura
          entre Usuário Paciente e o Médico, acima definidos, possibilitando:
          (i) disponibilizando agenda para comunicação pós-consulta entre
          Usuário Paciente e Médico, nos termos da Telemedicina, através de
          teleconferência, o qual dentro das opções, o Usuário Paciente inicia o
          contato pela plataforma com o Médico; (ii) postagem de arquivos de
          foto, áudio e vídeo; e (iii) gravação da teleconferência e geração de
          Ficha de Saúde disponibilizado ao Médico para arquivamento em
          Prontuário Eletrônico.
        </p>
        <p>
          O Usuário reconhece e concorda que a Dr. C não é prestadora de
          serviços médicos e de saúde, mas apenas fornecedora do aplicativo
          Filazero ou Site para possibilitar o agendamento e a comunicação pré,
          durante e após a consulta entre Usuário Paciente e seu Médico, e que
          todos os serviços profissionais serão prestados por Médicos e/ou
          Profissionais de Saúde independentes, livremente cadastrados em nossa
          plataforma, como Médicos e/ou Profissionais de Saúde, sem vínculo
          empregatício ou qualquer representação por parte da Dr. C.
        </p>
        <p>
          Os Serviços Filazero para Usuário Pessoa Jurídica destinam-se tanto
          para empresas contratantes e seus colaboradores como para clínicas que
          queiram disponibilizar sua equipe de Médicos e/ou Profissionais de
          Saúde para a realização de Serviços. Durante sua assinatura Filazero,
          o Filazero lhe concede uma licença limitada, não exclusiva e não
          transferível de acesso ao serviço Filazero para gerir as informações
          inerentes a gestão de atendimento da empresa que possui contrato
          firmado não podendo estender direito a empresas alheias.
        </p>

        <h3 class="mt-5 mb-3">Condições de Utilização</h3>

        <p>
          São os termos e condições que deverão ser observados pelo Usuário na
          utilização do Site e, principalmente de suas funcionalidades e
          ferramentas. Ao visitar nosso site o Usuário declara ter lido e aceito
          estes Termos e Condições de Uso. O aceite dos Termos e Condições de
          Uso irá implicar no reconhecimento de que o Usuário é capaz ou tem
          poderes para representar e vincular legalmente o Usuário Paciente e
          que leu e entendeu todos os termos constantes nestes documentos. Caso
          o Usuário tenha qualquer dúvida sobre os Termos e Condições de Uso,
          recomendamos que esse entre em contato com a Dr. C antes de aceitar,
          estar sujeito e se vincular aos mesmos.
        </p>

        <h3 class="mt-5 mb-3">Cadastramento de Usuário Paciente</h3>

        <p>
          Para ter acesso à área restrita do site, o Usuário deverá cadastrar-se
          para abertura de conta de acesso como Usuário Paciente, mediante a
          inclusão dos seguintes dados:
        </p>
        <p>
          – Usuário Paciente: Nome completo; Data de nascimento; Registro Geral
          (RG); Cadastro de Pessoa Física (CPF); dados de endereço residencial.
        </p>
        <p>
          É de conhecimento do Usuário Paciente que os dados cadastrados na Dr.
          C serão armazenados e utilizados por ela para identificação da
          utilização dos serviços e produtos oferecidos no Site, ressaltando-se
          que a criação de cada Conta de Acesso pressupõe o consentimento
          expresso de cada Usuário Paciente sobre coleta, uso, armazenamento e
          tratamento de dados pessoais, e profissionais pela Dr. C e/ou por
          terceiros por ela contratados para realizar qualquer procedimento ou
          processo relacionado à utilização dos serviços no Site.
        </p>
        <p>
          A cada Usuário Paciente será permitida a criação de apenas uma conta
          de acesso denominada Minha Conta e a Dr. C se reserva o direito de
          suspender ou cancelar quaisquer Contas de Acesso em duplicidade.
        </p>
        <p>
          Ao completar a sua conta de acesso, o Usuário Paciente declara que as
          informações fornecidas são completas, verdadeiras, atuais e precisas,
          sendo de total responsabilidade do Usuário Paciente a atualização dos
          dados de sua Conta de Acesso sempre que houver modificação de nome,
          endereço ou qualquer outra informação relevante. A Dr C poderá
          recusar, suspender ou cancelar a Conta de Acesso do Usuário Paciente
          sempre que suspeitar que as informações fornecidas são falsas,
          incompletas, desatualizadas ou imprecisas ou ainda nos casos indicados
          nas leis e regulamentos aplicáveis, nestes Termos, mesmo que
          previamente aceito.
        </p>
        <p>
          O Usuário Paciente, no momento da criação de sua Conta de Acesso,
          determinará seu nome de usuário e sua senha de acesso. É de sua
          exclusiva responsabilidade a manutenção do sigilo de sua senha de
          acesso relativa à sua Conta de Acesso, devendo o Usuário Paciente
          comunicar imediatamente a Dr. C em caso de perda, divulgação ou roubo
          da senha ou ainda de uso não autorizado de sua Conta, não devendo o
          Usuário Paciente em hipótese alguma divulgar a senha, emprestar ou
          conceder a pessoa estranha e/ou familiares.
        </p>
        <p>
          Menores (idade inferior a 18 anos) não poderão utilizar o Site, a
          menos que sejam representados e/ou assistidos por seus pais ou
          responsáveis legais e por estes autorizados no momento da criação da
          Conta. A efetivação de uma Conta em nome de um menor pressupõe a
          representação deste menor por uma pessoa maior de 18 anos, que será
          reputada responsável civil e criminalmente por qualquer ação
          realizada, violação cometida ou declaração falsa, incompleta,
          desatualizada ou imprecisa prestada pelo menor de 18 anos.
        </p>

        <h3 class="mt-5 mb-3">Senhas e Acesso a Contas</h3>

        <p>
          O Usuário que criou a conta Filazero e cuja forma de pagamento é
          cobrada pelo serviço é designado como o titular da conta nos presentes
          Termos de uso. O titular da conta tem acesso e controle sobre a conta
          Filazero. O controle do titular da conta é exercido por meio da senha
          do titular, portanto, para manter controle exclusivo sobre a conta, o
          titular não deverá revelar sua senha a ninguém. Você é responsável por
          atualizar e manter a veracidade e acuidade das informações fornecidas
          ao Filazero relativas à Minha Conta.
        </p>
        <p>
          Para simplificar o acesso à Minha Conta e ajudar a administrar o
          serviço Filazero, o Filazero emprega tecnologias que nos permitem
          reconhecer você como o titular da conta e lhe dar acesso direto à
          Minha Conta sem exigir que você digite sua senha ou qualquer outra
          informação de identificação pessoal quando você volta a acessar o
          serviço Filazero, o que inclui acessar o Filazero por meio de
          aparelhos ou do site.
        </p>
        <p>
          Exerça cautela com respeito a comunicados que solicitem o envio de
          dados de cartão de crédito ou outras informações sobre Minha Conta.
          Responder a comunicados dessa natureza com informações pessoais poderá
          resultar no roubo de seus dados pessoais. Sempre acesse informações
          pessoais sobre Minha Conta diretamente no site do Filazero e não
          clicando em links em emails ou outros tipos de comunicados
          eletrônicos, mesmo se o comunicado lhe parecer oficial. O Filazero
          reserva o direito de suspender qualquer conta a qualquer momento, com
          ou sem aviso prévio ao titular da conta, para proteger a si mesma e
          empresas associadas de atividades suspeitas de serem fraudulentas. O
          Filazero não será obrigada a reembolsar ou dar descontos a assinantes
          cujas contas foram suspensas por um representante do Filazero ou por
          processos automatizados implementados pelo Filazero.
        </p>

        <h3 class="mt-5 mb-3">Dados pessoais, privacidade e segurança</h3>

        <p>
          A Dr. C dispõe de uma Política específica para regular a coleta,
          guarda e utilização de dados pessoais, bem como a sua segurança:
          Política de Privacidade e Segurança. Essa Política específica integra
          inseparavelmente estes Termos e Condições de Uso, ressaltando-se que
          os dados de utilização do Site serão arquivados nos termos da
          legislação em vigor.
        </p>

        <h3 class="mt-5 mb-3">Forma de Utilização</h3>

        <p>
          Agenda: Dias e horários que seu Médico estará disponível para a
          realização da Teleconferência. O Usuário Paciente escolherá um horário
          dentre os selecionados para agendar sua Teleconferência.
        </p>
        <p>
          Teleconferência: No horário disponibilizado pelo seu Médico, o mesmo
          estará online, para receber sua solicitação de Teleconferência. Após o
          aceite do Médico inicia-se a comunicação. Serão disponibilizados
          ilimitados minutos para a comunicação entre os Usuários, o médico
          deverá fazer a gestão do seu tempo a fim de não atrasar as consultas
          seguintes.
        </p>
        <p>
          A Teleconferência será realizada livremente pelo Médico de forma
          autônoma, nos dias e horários que melhor lhe aprouver, conforme
          disponibilização de Agenda ao Usuário Paciente, sem qualquer tipo de
          subordinação e alteridade, por parte da Dr. C.
        </p>
        <p>
          Todos e quaisquer exames não agendáveis deverão ser realizados dentro
          do prazo de validade estabelecidos pelo Médico e/ou Profissional de
          Saúde, respeitando o prazo máximo de até 30 dias.
        </p>

        <h3 class="mt-5 mb-3">
          Pagamento pelos Serviços Contratados no Site e Taxa de Intermediação
        </h3>

        <p>
          A remuneração pela contratação do Prestador será paga eletronicamente
          pelo Usuário Paciente por meio da Plataforma de empresa de meios de
          pagamento contratada.
        </p>
        <p>
          Para poder utilizar os serviços da empresa de meio de pagamentos
          contratada pela Dr. C, o Usuário Paciente deverá previamente aceitar
          seus termos e condições de uso, os quais poderão ser modificados
          esporadicamente pela Dr. C, sendo obrigação do Usuário Paciente
          atualizar-se quanto às modificações que ocorrerem em tais termos e
          condições no momento da efetivação do pagamento.
        </p>
        <p>
          Em razão dos custos da Dr. C com despesas administrativas, bem como a
          necessidade de constante melhoria na infraestrutura e funcionalidades
          do sistema, a Dr. C cobra uma taxa de intermediação sobre o valor
          total de cada consulta agendada e realizada pelo Médico e/ou
          Profissional de Saúde, que desde já concorda com o pagamento de tal
          taxa.
        </p>
        <p>
          O valor da taxa referida acima será de R$ 50,00 (cinquenta reais) ou o
          valor equivalente a 10% (dez por cento) sobre o valor de cada consulta
          cobrada pelo Médico e/ou Profissional de Saúde, prevalecendo o maior
          valor apurado, já incluídos nesse valor, os custos referentes à
          transação comercial. A empresa responsável pelo meio de pagamento
          escolhido e adotado na plataforma destinará todos os valores
          referentes às taxas sobre as consultas para a Dr. C. A DR. C não
          interfere na decisão dos valores de honorários cobrados pelos Médicos
          e/ou Profissionais de Saúde aos Usuários Pacientes.
        </p>
        <p>
          Além da taxa acima, será repassada para o Médico e/ou Profissional de
          Saúde, taxa bancária de transferência entre contas (DOC e/ou TED), no
          valor de R$ 1,50 (um real e cinquenta centavos) cada repasse, que
          ocorrerá às segundas-feiras, a cada ciclo de 32 (trinta e dois) dias
          após o pagamento realizado pelo Paciente.
        </p>
        <p>
          Até o dia 05 (cinco) de cada mês, a Dr. C emitirá, Nota Fiscal de
          Serviços em face do Médico e/ou Profissional de Saúde, com o valor do
          pagamento de taxas efetuadas no mês anterior. A Nota Fiscal a ser
          emitida para o Usuário Paciente deverá ser emitida pelo Médico e/ou
          Profissional de Saúde.
        </p>
        <p>
          O Usuário Paciente concorda em pagar por todos e quaisquer serviços
          intermediados por meio do Filazero utilizando seu nome, cartão de
          crédito ou outras formas de pagamento. Todos os pagamentos feitos
          através de cartão de crédito estarão sujeitos à autorização e
          aprovação da administradora do cartão.
        </p>
        <p>
          A Plataforma Dr. C jamais será responsável por possíveis interrupções
          nos serviços de pagamentos de empresas contratadas, nem pelas falhas
          e/ou danos e prejuízos que o uso serviços possam gerar ao Usuário
          Paciente.
        </p>
        <p>
          Toda discrepância, erro, omissão e/ou inconveniente referente ao
          sistema, forma e/ou instituição de pagamento será de exclusiva
          responsabilidade do Usuário Paciente e das instituições de pagamento,
          não cabendo imputação alguma à Dr. C. Ao optar por utilizar o serviço
          de controle de pagamentos, a Dr.C não tem como evitar ocasionais
          falhas das referidas instituições e dos sistemas bancários e/ou
          administradoras de cartão de crédito.
        </p>
        <p>
          A Dr.C não se responsabiliza por falhas e/ou danos e prejuízos
          causados pelo uso dos serviços do site da empresa de meio de
          pagamentos contratada. Caso ocorram problemas devidos ao seu uso, o
          Usuário deverá informar a empresa pelos meios previstos neste Acordo,
          não gerando obrigação para que a Plataforma Dr. C.
        </p>
        <br />
        <p>
          Com exceção às disposições expressas nesses Termos de uso, quaisquer
          alterações aos preços e taxas de intermediação dos serviços passarão a
          vigorar mediante o envio de um comunicado por e- mail para o Médico
          e/ou Profissional de Saúde.
        </p>
        <p>
          Para Usuários Pacientes, os preços a serem praticados serão
          previamente combinados entre o Usuário Paciente e o Médico e/ou
          Profissionais de Saúde.
        </p>

        <h3 class="mt-5 mb-3">Promoções</h3>

        <p>
          A Dr. C poderá, a qualquer momento, por motivos puramente comerciais e
          a seu exclusivo critério, criar e manter campanhas promocionais
          oferecendo seus produtos na Plataforma.
        </p>

        <h3 class="mt-5 mb-3">Manutenção da Plataforma</h3>

        <p>
          A Dr C se reserva ao direito de efetuar eventuais manutenções em seus
          sistemas, visando melhoria na qualidade do serviço prestado. Caso haja
          a necessidade de suspensão de serviços de forma programada, a Dr. C
          avisará com antecedência todos os seus clientes através de anúncio no
          site{' '}
          <a className="text-link" href="www.drcannabis.com.br">
            www.drcannabis.com.br
          </a>{' '}
          ou por email.
        </p>

        <h3 class="mt-5 mb-3">Garantias</h3>

        <p>
          Apesar dos melhores esforços da Dr. C no sentido de fornecer
          informações precisas, atualizadas, corretas e completas, o Site poderá
          conter erros técnicos, inconsistências ou erros tipográficos. O Site,
          seu Conteúdo, suas funcionalidades e ferramentas são disponibilizados
          pela Dr. C tal quais expostos e oferecidos na Internet, sem qualquer
          garantia, expressa ou implícita, quanto aos seguintes itens: (i)
          atendimento, pelo Site ou por seu Conteúdo das expectativas dos
          Usuários; (ii) continuidade do acesso ao Site ou a seu Conteúdo; (iii)
          adequação da qualidade do Site ou de seu conteúdo para um determinado
          fim e (iv) a correção de defeitos, erros ou falhas no Site ou em seu
          Conteúdo. A Dr. C se reserva o direito de unilateralmente modificar, a
          qualquer momento e sem aviso prévio, o Site bem como a configuração, a
          apresentação, o desenho, o conteúdo, as funcionalidades, as
          ferramentas ou qualquer outro elemento do Site, inclusive o seu
          cancelamento.
        </p>

        <h3 class="mt-5 mb-3">Responsabilidades</h3>

        <p>
          A Dr. C engaja seus melhores esforços para informar, atender e
          proteger o Usuário Paciente. O Usuário Paciente é o único responsável
          pela utilização do Site, de suas ferramentas e funcionalidades. Em
          nenhuma hipótese, a Dr. C será responsabilizada por qualquer dano
          emergente, indireto, punitivo ou expiatório, lucros cessantes ou
          outros prejuízos monetários relacionados a qualquer reclamação, ação
          judicial ou outro procedimento tomado em relação à utilização do Site,
          seu Conteúdo, funcionalidades e/ou ferramentas. Notadamente, fica
          excluída a responsabilidade da Dr. C sobre as seguintes
          circunstâncias, entre outras: (i) danos e prejuízos que o Usuário
          Paciente possa experimentar pela indisponibilidade ou funcionamento
          parcial do Site e/ou de todos ou alguns de seus serviços, informações,
          Conteúdos, funcionalidade e/ou ferramentas, bem como pela incorreção
          ou inexatidão de qualquer destes elementos; (ii) indisponibilidade do
          seu Médico; (iii) danos e prejuízos que o Usuário possa experimentar
          em sites de internet acessíveis por links incluídos no Site; (iv)
          danos e prejuízos que o Usuário possa experimentar em decorrência do
          uso do Site em desconformidade com estes Termos; (v) danos e prejuízos
          que o Usuário possa experimentar em decorrência do uso do Site em
          desconformidade com a lei, com os costumes ou com a ordem pública;
          (vi) danos e prejuízos que o Usuário possa experimentar em decorrência
          de falhas no Site, inclusive decorrentes de falhas no sistema, no
          servidor ou na conexão de rede, ou ainda de interações maliciosas como
          vírus, softwares que possam danificar o equipamento ou acessar
          informações do equipamento do Usuário; e (vii) danos e prejuízos que o
          Usuário possa experimentar em decorrência da prestação de serviço de
          seu Médico; não conseguir conciliar o desejo de se comunicar pela
          Teleconferência com seu Médico, dentro dos períodos demarcados por ele
          (nomeado Agenda), por motivos de indisponibilidade do Médico; entre
          outros. O Usuário Paciente toma ciência que apenas o Médico da sua
          escolha poderá ter acesso ao seu prontuário de saúde e dados pessoais
          referentes à sua saúde, isentando qualquer responsabilidade da Dr. C,
          caso tenha feito opção de Médico erroneamente.
        </p>
        <p>
          Ainda, é de responsabilidade do Usuário Paciente a escolha do seu
          Médico, isentando a Dr. C de qualquer responsabilidade pelos danos
          pessoais que estes lhe causarem.
        </p>

        <h3 class="mt-5 mb-3">Links para Outros Websites</h3>

        <p>
          O Site pode conter links para sites de terceiros, os quais são
          inseridos apenas para conveniência do Usuário. A inclusão de tais
          links não implica qualquer vínculo, monitoramento ou responsabilidade
          da Dr. C sobre os sites, respectivos conteúdos ou titulares. O acesso
          aos sites vinculados a tais links não é regido por esses Termos e
          Condições de Uso e não se encontra protegido pelas Políticas.
        </p>
        <p>
          A Dr. C recomenda que o Usuário consulte os Termos e Condições de
          Uso estabelecidos por cada site vinculado aos links inseridos no Site.
          A Dr. C não será responsável, direta ou indiretamente, por quaisquer
          danos ou prejuízos causados ou relacionados à utilização de qualquer
          informação, conteúdo, bens ou serviços disponibilizados no Site ou em
          qualquer site de terceiros acessado por meio dos links
          disponibilizados no Site.
        </p>

        <h3 class="mt-5 mb-3">Aplicações de Internet ou vírus de computador</h3>

        <p>
          Em virtude de dificuldades técnicas, aplicações de Internet ou
          problemas de transmissão, é possível ocorrer cópias inexatas ou
          incompletas das informações contidas no Site. Vírus de computador ou
          outros programas danosos também poderão ser baixados inadvertidamente
          do Site. A Dr. C não será responsável por qualquer aplicação, vírus de
          computador ou outros arquivos danosos ou invasivos ou programas que
          possam prejudicar ou afetar a utilização do computador ou outro bem do
          Usuário devido ao acesso, utilização ou navegação no Site, ou ainda
          pelo download de qualquer material nele contido, sendo recomendada a
          instalação de aplicativos antivírus ou protetores adequados.
        </p>

        <h3 class="mt-5 mb-3">Condições gerais de uso</h3>

        <p>
          Ao aceitar estes Termos de Uso, o Usuário tem o direito não exclusivo,
          intrasferível, não sub- licenciável e limitado para acessar e usar os
          serviços do Site, unicamente para uso pessoal e não comercial. Todos
          os direitos e licenças não previstos expressamente nestes Termos de
          Uso estão reservados à Dr. C.
        </p>
        <p>
          O Site ou qualquer segmento dele não poderá ser reproduzido,
          duplicado, copiado, vendido, revendido, visitado ou explorado de outro
          modo com qualquer finalidade comercial sem o expresso consentimento
          por escrito da Dr. C. Não é permitido ao Usuário enquadrar ou utilizar
          técnicas de enquadramento para anexar qualquer marca comercial,
          logotipo ou outras informações exclusivas (como imagens, texto, layout
          ou formato de páginas) da Dr. C sem o expresso consentimento por
          escrito desta. Também não lhe é permitido usar quaisquer meta
          tags (identificadores de documentos em HTML) ou outro tipo de “texto
          oculto” utilizando o nome da Dr. C ou Filazero sem o expresso
          consentimento por escrito desta. O uso não autorizado resulta na
          suspensão da permissão ou licença concedida pela Dr. C.
        </p>
        <p>
          Salvo especificação em contrário da Dr. C, mediante licença fornecida
          separadamente, o direito de uso de qualquer software, dado, documento
          ou outro material que o Usuário acessar ou baixar por meio do Site Dr.
          C está sujeito a estes Termos do Uso.
        </p>
        <p>
          A Dr. C concede ao Usuário direito restrito, revogável e não exclusivo
          para criar um hyperlink com a página inicial do Site Dr. C contanto
          que o link não retrate a Dr. C ou seus respectivos produtos ou
          serviços em matéria falsa, enganadora ou depreciativa ou, de outro
          modo, em matéria ofensiva de outrem. Não é permitido ao Usuário usar
          qualquer logotipo ou outros gráficos ou marcas comerciais da Dr. C
          como parte do mencionado link sem a expressa permissão por escrito
          desta.
        </p>
        <p>
          O acesso ao Site Dr. C e sua regular utilização não concede ao Usuário
          qualquer direito ou prerrogativa sobre qualquer propriedade
          intelectual ou outro Conteúdo da Dr. C nele inserido.
        </p>

        <h3 class="mt-5 mb-3">Direitos Autorais</h3>

        <p>
          Todo o Conteúdo da Dr. C incluído no Site – como textos, gráficos,
          logotipos, ícones de botão, imagens, clipes de áudio, downloads
          digitais, compilação de dados e software – é de propriedade da Dr. C
          ou de seus respectivos fornecedores e está protegido pelas leis e
          tratados de direitos autorais nacionais e internacionais, assim como
          outras leis e tratados de propriedade intelectual.
        </p>

        <h3 class="mt-5 mb-3">
          Isenção de Responsabilidade por Garantias e Limitação de
          Responsabilidade
        </h3>

        <p>
          O Site Dr. C e a totalidade das informações, Conteúdos, materiais,
          produtos e serviços incluídos ou disponibilizados de outro modo ao
          Usuário por meio deste site são fornecidos pela Dr. C. no “estado em
          que se encontram” e à base de sua “disponibilidade atual”.
        </p>
        <p>
          A Dr. C não se responsabiliza por qualquer informação repassada pelo
          Usuário por meio do Site Dr. C, sendo de total responsabilidade do
          Usuário a veracidade das informações divulgadas.
        </p>
        <p>
          A Dr. C não presta declarações ou garantias de nenhuma espécie,
          expressas ou implícitas, quanto ao funcionamento deste site ou quanto
          às informações, Conteúdo, materiais, produtos ou serviços incluídos ou
          disponibilizados de outro modo ao Usuário por meio do Site Dr. C.
        </p>
        <p>
          O Usuário expressamente concorda que o uso que fizer deste Site será
          de seu próprio e exclusivo risco. A Dr. C não garante que este Site,
          as informações, Conteúdos, materiais, produtos ou serviços incluídos
          ou disponibilizados de outro modo ao Usuário por meio do Site ou
          Aplicativo Filazero, dos servidores do Site ou de e-mails enviados a
          partir desta sejam isentos de vírus ou de outros componentes danosos.
        </p>
        <p>
          Em nenhuma circunstância, a Dr. C poderá ser responsabilizada por
          qualquer dano direito, indireto, incidental, especial, consequencial
          ou punitivo, incluindo, mas não se limitando, a perdas e danos, lucros
          cessantes, perda de uma chance e danos intangíveis (ainda que a mesma
          tenha sido alertada sobre a possibilidade de ocorrência de tais
          danos), relacionado ao uso do Site Dr. C, nem com relação à
          incapacidade e/ou impossibilidade de usá-lo.
        </p>

        <h3 class="mt-5 mb-3">
          Políticas do Site, Modificações e Divisibilidade
        </h3>

        <p>
          A Dr. C reserva-se no direito de introduzir mudanças no Site, nas
          políticas e procedimentos, bem como nestes Termos de Uso, a seu
          exclusivo critério e a qualquer momento. Se qualquer das condições
          contidas nestes Termos do Site for considerada inválida, sem efeito ou
          inexequível por qualquer razão, a referida condição será considerada
          divisível e não afetará a validade e exequibilidade das demais
          condições.
        </p>
        <p>
          A Dr. C poderá alterar esses Termos de uso periodicamente, inclusive a
          Política de privacidade, Contrato de licença de usuário final e os
          Termos de redes sociais. Essas alterações entrarão em vigor
          imediatamente, porém, para assinantes atuais, as alterações entrarão
          em vigor no ato da sua publicação. A Dr. C procurará publicar versões
          anteriores dos Termos de uso, se houver, publicadas nos últimos 12
          meses. Essas versões anteriores poderão ser consultadas no site da Dr.
          C
          <a className="ml-1" href="www.drcannabis.com.br/termos">
            (www.drcannabis.com.br/termos).
          </a>
        </p>

        <h3 class="mt-5 mb-3">Privacidade</h3>

        <p>
          Informações pessoais que identifiquem os indivíduos estão sujeitas à
          nossa Política de privacidade, e seus termos estão incorporados neste
          documento.
        </p>

        <h3 class="mt-5 mb-3">Segurança das Informações</h3>

        <p>
          Todas as informações geradas no envio, recebimento, emissão e
          arquivamentos de informações (fotos, áudios, vídeos e qualquer outro
          Conteúdo) são de responsabilidade de ambas as partes que deverão
          manter o sigilo das informações e seguir o Código de Ética Médica
          vigente no Brasil.
        </p>
        <p>
          Mesmo a Dr. C garantindo o sigilo de todas as informações transitadas
          dentro do Site, alertamos que as trocas de informações feitas através
          da internet não são consideradas totalmente seguras onde há riscos
          envolvidos. A Dr. C protege as informações do paciente, seguindo o
          Código de Ética Médica, entretanto, os Usuários deverão tomar todo o
          cuidado com informações e qualquer Conteúdo, os quais deverão ser
          monitorados pelos Usuários. Recomendamos aos Usuários a não usar
          computadores públicos e apagar os históricos de conversas.
        </p>

        <h3 class="mt-5 mb-3">Prazo de Vigência </h3>

        <p>
          Estes Termos e Condições de Uso e as Políticas têm prazo de vigência
          indeterminado e permanecerão em vigor enquanto o Site estiver ativo. A
          redação aplicável é aquela da atualização imediatamente anterior ao
          acesso.
        </p>
        <p>
          Da mesma forma, o acesso e a utilização do Site e dos recursos por ele
          oferecidos têm, em princípio, duração indeterminada, a exclusivo
          critério da Dr. C. A Dr. C reserva-se, no entanto, o direito de
          suspender e/ou cancelar, de forma unilateral e a qualquer momento, o
          acesso ao Site ou a algumas de suas partes ou a alguns de seus
          recursos, sem necessidade de prévio aviso.
        </p>

        <h3 class="mt-5 mb-3">Atualizações destes Termos</h3>

        <p>
          A Dr. C poderá unilateralmente revisar, aprimorar, modificar e/ou
          atualizar, a qualquer momento, qualquer cláusula ou disposição
          contidas nestes Termos. A versão atualizada valerá para o uso do Site
          e para as Compras realizadas a partir de sua divulgação pela Dr. C. A
          continuidade de acesso ou utilização deste Site, depois da divulgação
          de quaisquer modificações, confirmará a aceitação dos novos Termos e
          Condições de Uso.
        </p>

        <h3 class="mt-5 mb-3">Confidencialidade</h3>

        <p>
          Cada uma das partes, Dr. C, Médico e/ou Profissional de Saúde, Usuário
          Paciente e Usuário Pessoa Jurídica, por si e por seus funcionários e
          prepostos comprometem-se a manter como confidenciais todos os dados
          recebidos, mais especialmente quanto aos dados de Usuário Paciente,
          nos termos da legislação civil vigente, bem como nos termos dos
          códigos de ética das classes profissionais envolvidas, de todas as
          informações recebidas e conhecimentos não públicos, recebidos em
          decorrência desse Contrato, objetivando sua execução, não podendo
          torná-las acessíveis a quaisquer terceiros sem autorização judicial,
          vigendo a presente cláusula por prazo indeterminado e irretratável,
          ainda que deixe de existir qualquer relação entre as partes.
        </p>

        <h3 class="mt-5 mb-3">Lei aplicável e Foro de Eleição</h3>

        <p>
          Site é controlado, operado e administrado pela Dr. C na Cidade de São
          Paulo, Estado de São Paulo, podendo ser acessado por qualquer
          dispositivo conectado à Internet, independentemente de sua localização
          geográfica. Em vista das diferenças que podem existir entre as
          legislações locais e nacionais, ao acessar o Site, o Usuário concorda
          que a legislação aplicável para fins destes Termos e Condições de Uso
          será aquela vigente na República Federativa do Brasil. A Dr. C e o
          Usuário concordam que o Foro Central da Capital – São Paulo, será o
          único competente para dirimir qualquer questão ou controvérsia oriunda
          ou resultante do uso do Site, renunciando expressamente a qualquer
          outro, por mais privilegiado que seja, ou venha a ser.
        </p>

        <p class="mt-5">Links Recomendados:</p>
        <ul>
          <li>
            <a href="http://site.cfp.org.br/">Código de Ética Médica</a>
          </li>
          <li>
            <a href="http://www.crpsp.org/site/">
              Ofício nº 1.756/20 do Conselho Federal de Medicina
            </a>
          </li>
        </ul>

        <h5 class="mt-5 mb-3">Contate-nos</h5>
        <p>Dr. Cannabis: contato@drcannabis.com.br</p>
        <p class="mt-5">
          <em>Última atualização em 13 de novembro de 2020.</em>
        </p>
      </Container>
      <Container classname=" dark-blue my-5">
        <h1>Política de Privacidade</h1>

        <h3 class="mt-5 mb-3">O que é o Dr. Cannabis?</h3>
        <p>
          Dr. Cannabis é uma plataforma inteligente que reúne e fornece
          informações do ecossistema da cannabis medicinal, bem como, conecta
          pacientes a médicos envolvidos com o tema, com o intuito de auxiliar
          nas dúvidas de pacientes, médicos e qualquer outro perfil de pessoa
          física adulta que esteja interessada no assunto (“Usuário”).
        </p>
        <p>
          A divulgação das informações do Dr. Cannabis, está disponível nas
          redes sociais, dentre elas, mas não se limitando a:{' '}
        </p>
        <ul>
          <li>Website (drcannabis.com.br);</li>
          <li>Página do Facebook (facebook.com/drcannabis); </li>
          <li>Instagram</li>
        </ul>
        <p>
          A plataforma Dr. Cannabis conta com um Chat Bot interativo no
          Messenger, doravante denominado “Doctor C”.
        </p>

        <h3 class="mt-5 mb-3">Informações Importantes</h3>
        <p>
          A. O Dr.Cannabis é um hub de conteúdo relacionado estritamente aos
          princípios e uso medicinais da cannabis e seus derivados, promovendo
          esclarecimentos e aproximação daqueles interessados em se atualizar
          sobre o assunto.
        </p>
        <p>
          B. O Dr. Cannabis informa no momento não fornece e não comercializa
          nenhum derivado da planta cannabis ou a planta in natura. Informa
          ainda, que em breve pretende comercializar os derivados da Cannabis
          para fins estritamente terapêuticos e medicinais, no Brasil e no
          território estrangeiro.
        </p>
        <p>
          C. O Dr. Cannabis não divulgará informação sobre a utilização médica e
          terapêutica dos derivados da planta cannabis de forma sensacionalista
          ou de conteúdo inverídico.{' '}
        </p>
        <p>
          D. Visando respeitar a legislação vigente bem como os padrões éticos
          estabelecidos pelo Código de Ética Médica, pelo Conselho Federal de
          Medicina, bem como pelos Conselhos Regionais de Medicina, os Usuários
          e outros profissionais de saúde se comprometem a não divulgar ou
          deixar serem divulgados publicamente quaisquer dados identificáveis de
          pacientes que sejam de alguma forma citados dentro da Plataforma Dr.
          Cannabis, salvo quando o paciente indicar, expressamente que concorda
          em compartilhar seus dados com determinado médico ou com outros
          Usuários.{' '}
        </p>
        <p>
          E. O Usuário será o único responsável pela sua conduta e por quaisquer
          dados transmitidos, textos, imagens, informações, gráficos e outros
          conteúdos ou materiais que eventualmente postarem e enviarem através
          da plataforma Dr. Cannabis.
        </p>
        <p>
          F. O Usuário se compromete a não compartilhar seus dados de acesso à
          plataforma Dr. Cannabis com terceiros. Desta forma, é de
          responsabilidade do Usuário manter seu login e senha em sigilo. Caso o
          Usuário identifique o uso indevido de sua conta, deverá relatar
          imediatamente o ocorrido aos administradores do Site.
        </p>

        <p>
          H. Ao utilizar a plataforma Dr. Cannabis, o Usuário concorda em
          utilizar o conteúdo informativo e educativo disponibilizado pelo Dr.
          Cannabis na mais absoluta observância às regras e princípios éticos,
          sem utilizar para fins diversos, podendo o Dr. Cannabis, a seu
          exclusivo critério - caso verifique o uso indevido da plataforma -
          revogar ou limitar a utilização do site pelo Usuário.
        </p>
        <p>
          I. Se O Usuário tem menos de 18 anos, por favor, peça permissão a seus
          pais ou responsáveis antes de oferecer qualquer informação pessoal ao
          nosso website. Caso você seja menor de 18 anos e não tenha o
          consentimento dos seus pais ou responsáveis, você não está autorizado
          a nos enviar suas informações pessoais.
        </p>
        <h3 class="mt-5 mb-3">
          Qual o tipo de informação que o Doctor C. captura?{' '}
        </h3>
        <p>
          O Chat Bot Doctor C. é dotado de uma NLP (programação
          neuro-linguística) que o permite “aprender” e capturar informações,
          tendo por base:
        </p>
        <p>Dados que o usuário digita enquanto se comunica com o Chat Bot;</p>
        <p>
          Dados disponibilizados ao Chat Bot pelo ambiente, como por exemplo, as
          informações públicas da conta de Facebook do Usuário.{' '}
        </p>
        <p>
          Estes dados poderão conter: nome completo, endereço de e-mail, gênero,
          IP, informações do navegador usado, nome do usuário, informações
          demográficas e quaisquer outras informações necessárias para melhoria
          da interação entre a aplicação e seus Usuários.{' '}
        </p>

        <h3 class="mt-5 mb-3">Objeto</h3>
        <ol>
          <li>
            Esta Política de Privacidade pretende esclarecer o compromisso da
            Sociedade DR.C SERVIÇOS E CONTEÚDOS INFORMATIVOS E EDUCACIONAIS
            LTDA. (Dr. Cannabis”) em garantir a proteção de dados pessoais dos
            usuários do Portal da Dr. Cannabis (https://drcannabis.com.br/),
            coletivamente o "Website".
          </li>
          <li>
            Para navegar com segurança em algumas áreas restritas e exclusivas –
            como as áreas de médicos e pacientes, bem como para determinado
            conteúdo –, é necessário preencher um cadastro de usuário.
          </li>
          <li>
            Esta Política de Privacidade é aplicável somente para o Website, e
            não a quaisquer outros websites que o Usuário possa acessar a partir
            do Website ou qualquer website de parceiros da Dr. Cannabis, cada um
            dos quais podem ter a coleta de dados, armazenamento e práticas de
            uso e políticas que diferem materialmente desta Política de
            Privacidade.{' '}
          </li>
          <li>
            As informações fornecidas no preenchimento de cadastro do Usuário
            são de total responsabilidade dos usuários e não serão repassadas a
            terceiros pela Dr. Cannabis. O Website as solicita livremente como
            forma de acesso a áreas restritas do Website, não as usando para
            qualquer outro fim. O e-mail cadastrado no Website pode ser
            utilizado para envio de novidades, caso haja concordância expressa
            nesse sentido por parte do Usuário. O envio do boletim pode ser
            cancelado a qualquer momento pelo Usuário cadastrado.{' '}
          </li>
          <li>
            Informações sobre navegação no Website, tais como o endereço IP,
            cookies e as páginas acessadas, podem ser armazenadas. O Website não
            autoriza nem repassa informação a terceiros, exceto em casos
            excepcionais, por exemplo, em caso de determinação judicial.
          </li>
          <li>
            Todas as informações sobre dados pessoais e de navegação são
            armazenadas em banco de dados próprio e reservado da Dr. Cannabis.
            Eventuais alterações nesse cadastro só podem ser efetuadas mediante
            solicitação do Usuário.
          </li>
          <li>
            Algumas informações pessoais são obtidas no preenchimento de
            cadastro para publicar comentários, conteúdos, materiais ou
            solicitar o envio de novidades e conteúdo para o Usuário. Assim,
            para que o Usuário acesse determinadas áreas do Website, o Website
            poderá exigir que o Usuário forneça certas informações que o
            identifiquem pessoalmente ("Informações Pessoais"), tais como seu
            endereço de e-mail, número de telefone, senha do Dr. Cannabis, sexo
            e data de nascimento.
          </li>
          <li>
            O Usuário pode optar por não fornecer quaisquer Informações
            Pessoais. Nesse caso, o usuário ainda pode acessar e usar muito do
            Website, todavia, não será capaz de acessar e usar as partes do
            Website que exigem Informações Pessoais do Usuário.
          </li>
          <li>
            A Dr. Cannabis também coleta outras informações, algumas das quais
            podem ser Informações Pessoais que você pode fornecer
            voluntariamente para a Dr. Cannabis quando optar por usar algumas
            das ferramentas interativas do Website e serviços, tais como a busca
            de prestadores de serviços de saúde e agendamentos com eles.{' '}
          </li>
          <li>
            Durante o registro, o Website pergunta nome, endereço de e-mail e
            outras Informações Pessoais do Usuário. Quanto mais dados
            corretamente forem fornecidos, melhor é a possibilidade de adequação
            da experiência de navegação aos interesses do Usuário. Ao usuário é
            proibido de se cadastrar com expressões impróprias, injuriosas ou
            caluniosas, com uso de marcas de produtos ou serviços de terceiros,
            denominações sociais ou institucionais, expressões publicitárias,
            nomes ou pseudônimos de personalidades públicas, de pessoas famosas
            ou registrados por terceiros; em geral, contrários à lei e à ordem
            pública.
          </li>
          <li>
            O Usuário que desrespeitar o estabelecido nesta Política de
            Privacidade pode ter o seu acesso ao Website bloqueado sem aviso
            prévio e a critério exclusivo da Dr. Cannabis.{' '}
          </li>
          <li>
            A Dr. Cannabis possui parceiros que a auxiliam em tarefas como a
            agendamento de consultas e intermediador de pagamentos.
            Eventualmente, eles podem solicitar informações e dados, mas nenhuma
            informação pessoal que permita identificação do Usuário será
            fornecida. Informações solicitadas diretamente pelos parceiros ao
            Usuário estão sujeitas a suas próprias regras e práticas de uso de
            dados, isentando a Dr. Cannabis de quaisquer responsabilidades.
          </li>
          <li>
            Ao visitar uma página do Website, um ou mais cookies ou outros
            dispositivos são enviados ao seu computador. Cookies são arquivos de
            computador de pequeno porte que são transferidos para o disco rígido
            do seu computador e contêm informações como o ID do Usuário,
            preferências do Usuário, listas de páginas visitadas e as atividades
            realizadas durante a navegação no Website.{' '}
          </li>
          <li>
            A maioria dos navegadores é configurada para aceitar cookies, mas
            você pode redefinir os padrões de seu navegador para que recuse
            todos os cookies ou indique quando um dispositivo desse tipo está
            sendo enviado. No entanto, alguns recursos e serviços do Website
            podem não funcionar adequadamente se os cookies estiverem
            desativados.
          </li>
          <li>
            As informações coletadas pelo Website são utilizadas para
            personalizar conteúdo ou serviços oferecidos. Sabendo mais sobre o
            usuário, o Dr. Cannabis pode fornecer conteúdo e serviços mais
            relevantes e proporcionar uma melhor experiência online. Os dados
            também podem servir para produzir dados estatísticos gerais com
            finalidade informativa.{' '}
          </li>
          <li>
            Em nenhuma hipótese as informações pessoais individuais serão
            comercializadas ou fornecidas a terceiros, exceto em estrito
            cumprimento de ordens judiciais ou procedimentos jurídicos similares
            ou quando autorizada pelo Usuário.
          </li>
          <li>
            A Dr. Cannabis pode usar os dados de contato do Usuário para enviar
            ao Usuário informações e conteúdo sobre o Dr. Cannabis, seus
            produtos ou serviços, por SMS e afins, para contatá-lo quando
            necessário, inclusive para lembrá-lo de futuras consultas. Você
            concorda que o Dr. Cannabis pode usar Informações Pessoais para
            permitir que seus médicos marquem consultas com outros médicos em
            seu nome através dos Serviços.
          </li>
          <li>
            O Usuário declara e concorda que uma transmissão de dados na
            internet não é 100% segura. Assim, embora o Dr. Cannabis faça tudo o
            que está a seu alcance para proteger informações pessoais de
            Usuários que acessam o Portal, não é possível garantir total
            segurança de cada informação fornecida. Portanto, é uma decisão
            pessoal a utilização do serviço nessas condições.
          </li>
          <li>
            Quaisquer informações fornecidas em fóruns ou em áreas de
            comentários são publicadas e permanecem acessíveis a qualquer
            Usuário que visite a página. Por isso, a sugestão é de que se evite
            menção a dados como número de telefone, ou endereço para
            correspondência que podem possibilitar contatos indesejados por
            terceiros. Crianças devem ser instruídas a não preencher formulários
            sem a autorização de um responsável.
          </li>
          <li>
            O Dr. Cannabis poderá também compartilhar Informações Pessoais e
            dados de tráfego com nossos parceiros de negócios que nos assistem
            através da realização de serviços essenciais (como hospedagem,
            realização de faturamento, ou de armazenamento de dados e segurança)
            relacionados com a operação do Website. Os parceiros de negócios
            serão obrigados a manter os mesmos padrões de segurança e
            confidencialidade a que o Dr. Cannabis se comprometeu nesta Política
            de Privacidade. Por fim, podemos transferir informações sobre você
            para outra empresa em conexão com uma venda, fusão ou aquisição, por
            ou de Dr. Cannabis. Neste caso, a Dr. Cannabis vai usar os esforços
            razoáveis para notificar o Usuário antes que as informações sejam
            transferidas e tornem-se sujeitas a uma política de privacidade
            diferente.{' '}
          </li>
          <li>
            Salvo disposição em contrário nesta Política de Privacidade, a Dr.
            Cannabis vai manter suas Informações Pessoais privadas e não irá
            compartilhá-las com terceiros, a menos que acredite de boa - fé que
            a divulgação de suas Informações Pessoais ou quaisquer outras
            informações que coletamos sobre o usuário seja necessária para: (1)
            cumprir uma ordem judicial ou outro processo legal; (2) proteger os
            direitos de propriedade, ou segurança da Dr. Cannabis ou de outra
            parte; (3) fazer valer os nossos Termos de Uso, ou (4) para
            responder reclamações de que qualquer postagem ou outros conteúdos
            violam os direitos de terceiros.
          </li>
          <li>
            Médicos, profissionais de saúde em geral, seus empregados, e seus
            agentes devem estar particularmente conscientes das suas obrigações
            de confidencialidade do paciente/Usuário, incluindo sem limitação as
            suas obrigações sob o Código de Ética específico de sua atividade,
            tanto na comunicação com a Dr. Cannabis como na resposta a uma
            avaliação dos seus serviços postada em nosso Website. O Dr. Cannabis
            não tem, e não aceitará quaisquer obrigações de confidencialidade em
            relação a qualquer comunicação que não as expressamente declaradas
            nesta Política de Privacidade.
          </li>
          <li>
            Qualquer informação que você possa revelar em uma postagem de
            avaliação ou discussão on-line ou fóruns é intencionalmente aberta
            ao público e não é de qualquer maneira privada. Você deve pensar
            cuidadosamente antes de divulgar qualquer informação pessoalmente
            identificável em qualquer fórum público. O que você escreveu pode
            ser visto e / ou coletado por terceiros e pode ser usado por outras
            pessoas de maneira que são incapazes de controlar ou prever.
          </li>
          <li>
            A Dr. Cannabis usa as informações que recebe sobre o Usuário em
            relação aos serviços e recursos fornecidos ao Usuário. Desta forma,
            a Dr. Cannabis podemos usar as informações que recebe:
          </li>
          <li>para proteger os direitos ou propriedades do Dr. Cannabis;</li>
          <li>
            para fornecer recursos e serviços de localização para facilitar o
            acesso aos profissionais de saúde;
          </li>
          <li>
            para fazer sugestões aos Usuários, como: sugerir o reagendamento da
            consulta; sugerir um novo profissional de saúde em casos de
            cancelamento; sugerir exame de rotina; sugerir médicos com base em
            avaliação de usuários; relembrar a existência de consulta; sugerir a
            avaliação do profissional de saúde pós consulta; para realizar e
            divulgar pesquisas de mercado; para fornecimento de dados sobre
            consultas e procuras por profissionais de saúde;
          </li>
          <li>
            para operações internas, que incluem correção de erros, análise de
            dados, testes, pesquisa, desenvolvimento e melhoria do serviço.
          </li>
          <li>
            O Dr. Cannabis armazena os dados do Usuário pelo tempo necessário
            para fornecer seus melhores serviços para o Usuário, inclusive as
            descritas acima. Normalmente, as informações associadas à sua conta
            serão mantidas até sua conta ser excluída. Para certas categorias de
            dados, também podemos lhe contar sobre práticas de retenção de dados
            específicos.
          </li>
          <li>
            Caso o Usuário solicite ao Dr. Cannabis a desativação de sua conta,
            suas informações não serão excluídas, uma vez que o Usuário pode ter
            interesse em reativar sua conta posteriormente. Caso o Usuário
            queira a exclusão permanente de todos os dados existentes, deverá
            contatar contato@drcannabis.com.br.
          </li>
          <li>
            O Dr. Cannabis reserve-se no direito de, a qualquer momento,
            adicionar, alterar, atualizar ou modificar esta Política de
            Privacidade, portanto, revise-o frequentemente. Se fizermos isso,
            vamos notificá-lo aqui, assim como por um aviso no nosso Website e /
            ou enviando um e-mail a você, juntamente com uma descrição de
            quaisquer alterações (material ou não) e, quando apropriado, um link
            para alteração da política para que você possa revê-la. Em todos os
            casos, o uso de informações que coletamos está sujeito à Política de
            Privacidade em vigor no momento em que essas informações são
            coletadas. O Website pode conter links para sites de terceiros com
            os quais o Dr. Cannabis não tem nenhuma afiliação.
          </li>
          <li>
            Se você tiver quaisquer comentários, dúvidas ou preocupações sobre
            esta Política de Privacidade entre em contato conosco através do
            email: contato@drcannabis.com.br.
          </li>
        </ol>
      </Container>
    </Section>
  )
}
