import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import api from '~/services/api'
import { Container } from './styles'
import Button from '~/components/Button'
import { Input } from '~/components/Form'
import { transform } from '~/shared/utils'

export default function TemplateAnvisa({ next, data }) {
  const { register, errors, getValues, triggerValidation, setValue } = useForm()
  const [basePrescription, setBasePrescription] = useState('')

  const goAskPhysician = async () => {
    const prescription = {
      status: 'askPhysician',
    }
    await api.put(`/prescriptions/${data.id}`, prescription)
    next('askPhysician')
  }

  const goSendAnvisa = async () => {
    const result = await triggerValidation('protocol')
    if (result) {
      const values = getValues()
      const prescription = {
        status: 'sendAnvisa',
        anvisa_protocol: values.protocol,
      }
      await api.put(`/prescriptions/${data.id}`, prescription)
      next('sendAnvisa')
    }
  }

  useEffect(() => {
    setValue('protocol', data.anvisa_protocol)
  }, [])


  useEffect(()=> {
    if (data.file_id) {
      let url = `/files/${data.file_id}`
      if (url) {
        transform(url).then(r => setBasePrescription(r))
      }
    }
  }, [data.file_id])


  return (
    <Container>
      <Alert variant="success">
        <p>
          Preparamos um gabarito com o passo a passo para você solicitar a
          autorização Anvisa. Esta etapa é pessoal e intransferível, deve ser
          feita por você ou um responsável.
        </p>
        <br />
        <p>
          Se tiver dúvidas, entre em contato por e-mail
          pacientes@drcannabis.com.br ou whatsapp (11) 99999-5734 e iremos te
          auxiliar. Ao terminar, copie e cole o seu protocolo Anvisa aqui.
        </p>
        <p> Ao terminar, copie e cole o seu protocolo Anvisa aqui.</p>
      </Alert>
      <h4 className="mb-3 text-center">Gabarito e Protocolo Anvisa</h4>
      <div className="d-flex flex-column align-items-center justify-content-center mt-2">
        <a href={basePrescription} target="_blank" rel="noopener" download={`prescrição-${data.patient.profile.full_name}`}>
          <Button className="primary my-3">Baixe sua prescrição!</Button>
        </a>
        <a target="_blank" rel="noopener" href={`/template/anvisa/${data.id}`}>
          <Button className="white mt-3">Visualizar Gabarito</Button>
        </a>
      </div>
      <div className="d-flex justify-content-center">
        <form>
          <Input
            type="text"
            label="Protocolo"
            name="protocol"
            size="md"
            className="my-3"
            error={errors.protocol}
            ref={register({ required: true })}
          />
        </form>
      </div>
      <div className="d-flex justify-content-between">
        <Button className="primary" onClick={goAskPhysician}>
          Voltar
        </Button>
        <Button className="primary" onClick={goSendAnvisa}>
          Próximo
        </Button>
      </div>
    </Container>
  )
}
