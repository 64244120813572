import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem;

  .file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    img {
      width: 60px;
    }
    p {
      margin-top: 0.5rem;
      text-align: center;
    }
  }


  .product {
    width: 200px;
    height: 200px;
  }

  .item {
    flex: 1 1 auto;
    min-height: 140px;
    padding: 1.25rem 0;
    display: flex;
  }
  @media screen and (max-width: 575px) {
    .item {
      flex-direction: column;
      padding: 0.3rem;
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    margin: 6px 0;
    width: 100%;
    td {
      height: 30px;
    }
  }
`
