import React from 'react'

const ButtonPrimary = ({
 text = '',
 btnClass = '',
 width = '',
 callBack,
 disabled = false,
 controls,
 target = '#modal',
 toggle,
 dismiss,
 icon,
 btnType = 'button',
}) => {
  return (
    <button
      className={`btn button__primary text-white mt-3 ${btnClass} ${width}`}
      type={btnType}
      onClick={callBack}
      disabled={disabled}
      data-toggle={toggle}
      data-target={target}
      aria-controls={controls}
      data-dismiss={dismiss}
    >
      {icon}
      {text}
    </button>
  )
}

export { ButtonPrimary }
