import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import Pagination from 'react-js-pagination'

import { Container, Product } from './styles'
import api from '~/services/api'
import Sprites from '~/assets/icons/sprites.svg'
import ProductImage from '~/assets/images/product-sample.jpg'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Brand() {
  const { slug } = useParams()
  const [medicines, setMedicines] = useState({ data: [] })
  const [loading, setLoading] = useState({ data: [] })
  const [brand, setBrand] = useState({})

  const getData = async (brandSlug, page = 1) => {
    setLoading(true)
    const resBrand = await api.get(`/brands/${brandSlug}`)
    setBrand(resBrand.data)
    const resMedicine = await api.get(`/medicines?brand=${brandSlug}`, {
      params: { page },
    })
    setMedicines(resMedicine.data)
    setLoading(false)
  }

  useEffect(() => {
    getData(slug)
  }, [slug])

  const handlePageChange = page => {
    getData(slug, page)
  }

  return (
    <Container>
      <h2 className="text--rm">{brand.name}</h2>
      <Row>
        <Col>{loading && <Skeleton count={5} />}</Col>
      </Row>
      {!loading && (
        <>
          <Row className="mt-3">
            {medicines.data.map(medicine => (
              <Col lg={4}>
                <Link to={`/produtos/${medicine.slug}`}>
                  <Product className="d-flex flex-column mb-4">
                  <img
                    src={medicine?.photo?.uuidUrl}
                    style={{ cursor: 'pointer' }}
                    alt=""
                  />
                  <div className="d-flex flex-column p-3 pr-4">
                    <h4 className="text--rm text--black-dark mt-2 mt-md-3 mb-3">
                      {medicine.name}
                    </h4>
                    <p className="text--rr text--black-dark mb-5">
                      {medicine.currency} {medicine.price}
                    </p>
                    <Link
                      to={`/admin/produtos/editar/${medicine.slug}`}
                      className="anchor text--sm text-decoration-none d-flex align-items-center justify-content-end"
                    >
                      Editar
                      <span className="ml-1">
                        <svg className="icon">
                          <use xlinkHref={`${Sprites}#icon-angle-right`} />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </Product>
                </Link>
              </Col>
            ))}
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-3">
              <Pagination
                activePage={medicines.page}
                itemsCountPerPage={medicines.perPage}
                totalItemsCount={medicines.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}
