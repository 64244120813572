import React from 'react'
import PropTypes from 'prop-types'

import ProfileIcon from '~/assets/icons/profile.svg'
import { RenderImg } from '~/components/RenderImg/RenderImg'

const ProfileCard = function({ data, physician }) {
  return (
    <div className='container profile__card'>
      <div className=" profile__card__img-box">
        {data &&
          data.profile &&
          (data.profile.avatar ? (
            <RenderImg
              photo_id={data?.profile?.avatar.id}
              photo_uuid={data?.profile?.avatar.uuidUrl}
              isPrivate={data?.profile?.avatar.private}
              imgSecond={ProfileIcon}
              classNameImg="align-self-center rounded-circle"
              alt="Usuário"
            />
          ) : (
            <img
              className="align-self-center rounded-circle"
              src={ProfileIcon}
              alt="Usuário"
            />
          ))}
      </div>
      <div className='profile__card__description'>
        <div className="mx-3">
          <h3 className="profile__card__description__name">
            {!physician?.crm
              ? ''
              : data.profile.gender === 'm'
              ? 'Dr. '
              : data.profile.gender === 'f'
              ? 'Dra. '
              : ''}
            {data?.profile?.full_name}
          </h3>
          <h5 className="profile__card__description__crm">
            {physician?.crm && `CRM: ${physician?.crm}`}{' '}
            {physician?.uf && physician?.uf != 'XX' && (
              <span>{physician?.uf}</span>
            )}
            {!physician &&
              data?.addresses?.length > 0 &&
              data?.addresses[0]?.state}
          </h5>
          {physician?.online_consultation && (
            <h5 className="profile__card__description__telemed">Este médico faz consultas online</h5>
          )}
        </div>
      </div>
    </div>
  )
}

ProfileCard.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProfileCard
