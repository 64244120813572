import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { SectionPillars, SectionCreators } from './styles'
import VivianeImg from '~/assets/images/viviane.jpg'
import { Helmet } from 'react-helmet'

export default function About() {
  const title = 'Dr. Cannabis - Sobre nós'
  const description = 'A Dr. Cannabis é a sua aliada para encontrar e proporcionar mais qualidade de vida ' +
    'através da terapêutica canábica. Veja!'
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta
          name="description"
          content={description}
        />
        <meta
          itemprop="description"
          content={description}
        />
        <meta
          name="og:description"
          content={description}
        />
        <meta
          name="twitter:description"
          content={description}
        />
      </Helmet>
      <SectionPillars>

        <Container fluid="lg">
          <Row>
            <Col xs={12}>
              <h1 className="title text--sm text--black text-center mb-3 mt-5">
                Sobre a Dr. Cannabis
              </h1>
            </Col>
            <Col xs={10} className="m-auto">
              <div className="separator" />
            </Col>
            <Col xs={12} className=" mb-0 mb-md-5">
              <h5 className="text text--grey subtitle">
                Com tantas vidas sendo impactadas por doenças crônicas, a Dr.
                Cannabis surge com a missão de apresentar a terapia canabinoide
                como uma alternativa de tratamento. Facilitando o acesso a
                informações, médicos e produtos que proporcionam mais conforto e
                saúde para quem precisa.
              </h5>
            </Col>
            <Col xs={12} className=" mt-5 mb-5">
              <h1 className="subtitle text--sm text--black">Nossos pilares</h1>
            </Col>
          </Row>
        </Container>
        <div className="banner notebook">
          <Container>
            <Row>
              <Col xs={12} md={6}>
                <h2 className="title text--sm text--white mb-3">
                  A informação é o melhor remédio.
                </h2>
                <h5 className="text text-white subtitle">
                  Conhecimento é uma ferramenta poderosa. Desmistificar
                  informações sobre cannabis medicinal pode representar o início
                  de uma vida com mais saúde e qualidade para milhões de
                  pessoas. Nesta jornada, a ciência é a maior aliada. Todo o
                  conteúdo da Dr. Cannabis tem respaldo científico. Médicos
                  podem contar com um repositório completo de artigos que
                  sustentam o uso da cannabis como recurso terapêutico.
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="banner medicine">
          <Container>
            <Row>
              <Col md={{ span: 6, offset: 6 }}>
                <h2 className="title text--sm text--white mb-3">
                  Conexão entre você e tudo que precisa
                </h2>
                <p className="text text--white text-justify">
                  A Dr. Cannabis faz a ponte entre médicos e pacientes. Assim,
                  quem precisa pode acessar tratamentos efetivos de forma
                  apropriada e segura.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="banner woman">
          <Container>
            <Row>
              <Col xs={12} md={4}>
                <h2 className="title text--sm text--white mb-3">
                  Saúde e bem-estar é com a Dr. Cannabis
                </h2>
                <p className="text text--white text-justify">
                  Tudo que somos e fazemos é em prol do cuidado às pessoas.
                  Queremos facilitar o acesso e fazer parte da vida de quem
                  busca e de quem proporciona mais bem-estar e saúde.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </SectionPillars>
      <SectionCreators className="mb-5">
        <Container>
          <Row>
            <Col xs={12} className="">
              <h1 className="subtitle text--sm text--black mb-5 mt-5">
                Equipe
              </h1>
            </Col>
            <div className="col-12">
              <div className="card">
                <div className="card-header bg-transparent d-flex justify-content-center border-0">
                  <img
                    className="rounded-circle img-fluid img-border-avatar"
                    src={VivianeImg}
                    alt=""
                  />
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <h4 className="title text--sm text--black mt-2 mb-4">
                      Viviane Sedola
                    </h4>
                  </div>
                  <p className="text text--grey-dark text-justify">
                    É fundadora e CEO da <b>Dr Cannabis</b>. Usa sua influência
                    para trabalhar com os principais veículos de comunicação e
                    contatos políticos para reduzir o atrito e a burocracia no
                    uso medicinal da cannabis. Foi homenageada pela revista High
                    Times como uma das 50 mulheres mais influentes do mercado de
                    cannabis no mundo.
                  </p>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </SectionCreators>
    </>
  )
}
