import React, { useState } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import moment from 'moment'

import { Container } from './styles'
import ProfileCard from '~/components/ProfileCard'
import Button from '~/components/Button/index'
import { useEffect } from 'react'
import api from '~/services/api'
import { Checkbox, TextArea } from '~/components/Form/index'
import PrescriptionItem from '../Prescriptions/PrescriptionItem'
import { useDispatch, useSelector } from 'react-redux'
import { Creators as ErrorActions } from '~/store/ducks/error'

export default function Physician() {
  const { register, setValue, getValues, watch } = useForm()
  const { id } = useParams()
  const [physician, setPhysician] = useState({})
  const [loading, setLoading] = useState(true)
  const [comments, setComments] = useState({ data: [] })
  const [loadingComment, setLoadingComments] = useState(true)
  const [loadingPrescription, setLoadingPrescription] = useState(true)
  const [prescriptions, setPrescriptions] = useState({ data: [] })
  const history = useHistory()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const contacted = watch('contacted')
  const dispatch = useDispatch()

  const getData = async physicianId => {
    try {
      setLoading(true)
      const res = await api.get(`/physicians/${physicianId}`)
      setPhysician(res.data)
      setValue('contacted', res.data.contacted)
      setLoading(false)
      await getComments(res.data.user.id)
      await getPrescriptions(res.data.user.id)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoading(false)
    }
  }

  const getPrescriptions = async physicianId => {
    setLoadingPrescription(true)
    const res = await api.get(`/prescriptions?physician=${physicianId}`)
    setPrescriptions(res.data)
    setLoadingPrescription(false)
  }

  const getComments = async userId => {
    setLoadingComments(true)
    const res = await api.get(`/user/${userId}/comments`)
    setComments(res.data)
    setLoadingComments(false)
  }

  const sendComment = async () => {
    try {
      setLoadingComments(true)
      const { content } = getValues()
      const data = { content }
      await api.post(`/user/${physician.user.id}/comment`, data)
      await getComments(physician.user.id)
      setLoadingComments(false)
      setValue('content', '')
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoadingComments(false)
    }
  }

  const deleteComment = async commentId => {
    setLoadingComments(true)
    await api.delete(`/user/${commentId}/comment`)
    await getComments(physician.user.id)
    setLoadingComments(false)
  }

  const updateContacted = async physicianId => {
    try {
      setLoading(true)
      const data = { contacted: !contacted }
      await api.put(`/physician/${physicianId}/contacted`, data)
      setLoading(false)
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
      setLoading(false)
    }
  }

  const formatToArray = data => {
    return data.split(',')
  }

  useEffect(() => {
    getData(id)
  }, [id])

  const changeStatus = async status => {
    setLoading(true)
    await api.get(`/physician/${physician.id}/status?status=${status}`)
    await getData(id)
    setLoading(false)
  }

  const deletePhysician = async () => {
    setLoading(true)
    await api.delete(`/users/${physician.user.id}`)
    await getData(id)
    setLoading(false)
    history.push('/admin/medicos')
  }

  return (
    <Container>
      <Row>
        <Col lg={8} className="my-4">
          <ProfileCard data={physician.user} physician={physician} />
        </Col>
        <Col>
          <Link to={`/admin/medico/editar/${physician.user?.id}`}>
            <Button className="primary my-2 mr-1">Editar Pessoal</Button>
          </Link>
          <Link to={`/admin/medico/editar-medico/${physician.user?.id}`}>
            <Button className="primary my-2">Editar Profissional</Button>
          </Link>
          <Checkbox
            onClick={() => updateContacted(physician.id)}
            className="my-3"
            name="contacted"
            label="Contato realizado"
            ref={register}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <h4>Sobre</h4>
          <p>{physician.about}</p>
          <p>
            Já prescreveu: <b>{physician.prescribes ? 'Sim' : 'Não'}</b>
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="mb-4">
          <h4>DrCannabis</h4>
          <p>
            Status:{' '}
            <b>
              {physician.status === 'approved'
                ? 'Aprovado'
                : physician.status === 'pending'
                ? 'Pendente'
                : 'Inativo'}
            </b>
          </p>
          <p>
            Cadastrado em:{' '}
            <b>{moment(physician.created_at).format('DD/MM/YYYY')}</b>
          </p>
          <p>
            Aprovado em em:{' '}
            <b>{moment(physician.evaluation_date).format('DD/MM/YYYY')}</b>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col className="mb-4">
              <h4>Especialidade</h4>
              <Button className="outline-primary">{physician.specialty}</Button>
            </Col>
          </Row>
          {physician.diseases?.length > 0 && (
            <Row>
              <Col className="mb-4">
                <h4>Enfermidades</h4>
                {Array.isArray(physician.diseases)
                  ? physician.diseases?.map(disease => (
                      <Button className="outline-primary mr-1 mb-1">
                        {disease}
                      </Button>
                    ))
                  : formatToArray(physician.diseases).map(disease => (
                      <Button className="outline-primary mr-1 mb-1">
                        {disease}
                      </Button>
                    ))}
              </Col>
            </Row>
          )}
          <Row>
            <Col className="mb-4">
              <h4>Contato</h4>
              <p>{physician.user?.email}</p>
              {physician.user?.profile?.mobile_number && (
                <p>{physician.user?.profile?.mobile_number}</p>
              )}
              {physician.user?.profile?.phone_number && (
                <p>{physician.user?.profile?.phone_number}</p>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <h4>Endereço</h4>
              {physician.user?.addresses.map(address => (
                <>
                  <p>
                    {address?.street && `${address?.street}, `}
                    {address?.number && `${address?.number} - `}
                    {address?.complement}
                  </p>
                  <p>{address?.cep}</p>
                  <p>
                    {address?.city} - {address?.state}
                  </p>
                </>
              ))}
              {physician.user?.addresses.length === 0 &&
                'Endereço não cadastrado'}
            </Col>
          </Row>
        </Col>
        <Col>
          <div className="d-flex flex-column">
            {physician.status !== 'pending' && (
              <Button
                className="border-success btn-success md mb-3"
                onClick={() => changeStatus('PENDING')}
              >
                Aguardando aprovação
              </Button>
            )}
            {physician.status !== 'approved' && (
              <Button
                className="primary md mb-3"
                onClick={() => changeStatus('APPROVED')}
              >
                Aprovar
              </Button>
            )}
            {physician.status !== 'disapproved' && (
              <Button
                className="outline-danger md"
                onClick={() => changeStatus('DISAPPROVED')}
              >
                Reprovar
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <h4 className="my-3">Comentários</h4>
        </Col>
        {comments.data.map(
          comment =>
            comment.owner &&
            comment.owner.profile && (
              <Col xs={12}>
                <div className="comment">
                  <div className="d-flex align-items-center justify-content-between">
                    <b>{comment.owner?.profile?.full_name}</b>
                    <Button
                      className="delete"
                      onClick={() => deleteComment(comment.id)}
                    >
                      deletar
                    </Button>
                  </div>
                  <p className="my-2">{comment.content}</p>
                  <p className="text-right">
                    {moment(comment.created_at).format('DD/MM/YYYY HH:mm')}
                  </p>
                </div>
              </Col>
            ),
        )}
        <Col xs={12}>
          <TextArea
            type="text"
            label="Novo Comentário"
            name="content"
            placeholder="Comentário aqui."
            fluid
            className="mt-3"
            ref={register}
          />
          <div className="d-flex justify-content-end">
            <Button className="primary mb-3" onClick={sendComment}>
              Enviar
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        {!loadingPrescription &&
          prescriptions.data?.map(prescription => (
            <Col lg={12} className="mt-3" key={prescription.id}>
              <Link to={`/admin/prescricoes/${prescription.id}`}>
                <PrescriptionItem prescription={prescription} />
              </Link>
            </Col>
          ))}
      </Row>
      <Row>
        <Col>
          <Button
            className="outline-danger md my-3"
            onClick={() => setShowConfirmDelete(true)}
          >
            Deletar Médico
          </Button>
          <Alert show={showConfirmDelete} variant="danger">
            <Alert.Heading>Deseja mesmo deletar esse Médico?</Alert.Heading>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={deletePhysician} className="danger">
                Deletar
              </Button>
            </div>
          </Alert>
        </Col>
      </Row>
    </Container>
  )
}
