import React, { useState, useEffect } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

import { SectionProduct } from './styles'
import Sprites from '~/assets/icons/sprites.svg'
import ProductSample from '~/assets/images/product-sample.jpg'
import Button from '~/components/Button'
import Products from '~/components/Products'
import api from '~/services/api'

import { useSelector } from 'react-redux'
import { ModalCalculator } from '~/components/ModalCalculator/ModalCalculator'
import { formatValue } from '~/shared/utils'
import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function Product(props) {
  const [medicine, setMedicine] = useState({})
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [requestFinish, setRequestFinish] = useState(false)
  const [medicines, setMedicines] = useState([])
  const { session } = useSelector(state => state.auth)

  useEffect(() => {
    const fetch = async () => {
      const res = await api
        .get(`/medicines`)
        .finally(() => setRequestFinish(true))
      setMedicines(res.data.data.slice(0, 3))
    }
    fetch()
  }, [])

  const getMedicine = async () => {
    setLoading(true)
    const res = await api.get(`/medicines/${id}`)
    setMedicine(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getMedicine()
  }, [id])

  const validateLink = () => {
    let str = medicine.links
    if (str) {
      if (str.match(/https:/) || str.match(/http:/)) {
        return str
      } else {
        return `https://${str}`
      }
    }
  }
  return (
    <>
      <ModalCalculator modalId="calculatorModal" medicine={medicine} />

      <SectionProduct>
        <Container fluid="lg">
          <Row className="mb-3">
            <Col xs={12}>
              <h1 className="text--sm text--black mb-4 mt-3">
                {medicine.name}
              </h1>

              <svg className="fav icon mobile d-none position-absolute">
                <use xlinkHref={`${Sprites}#icon-heart-o`} />
              </svg>
            </Col>
            <Col sm={6} md={4}>
              {requestFinish &&
                (medicine.photo ? (
                  <img
                    src={medicine.photo.uuidUrl}
                    alt={medicine.name}
                    className="img mb-3 m-sm-0"
                  />
                ) : (
                  <img
                    className="img mb-3 m-sm-0"
                    src={ProductSample}
                    alt={medicine.name}
                  />
                ))}
            </Col>
            <Col sm={6} md={5}>
              <div className="d-flex justify-content-between flex-column h-100">
                <div className="d-flex">
                  <h2 className="text--rm text--black-dark mt-2">
                    {formatValue(medicine.price, medicine.currency)}
                  </h2>
                  {medicine.price_previous && (
                    <p className="pl-3 pt-3 text--black-dark text--rm value">
                      <strike>
                        {medicine.currency} {medicine.price_previous}
                      </strike>{' '}
                      <span className="value value secondary ml-3">
                        {(
                          (medicine.price * 100) / medicine.price_previous -
                          100
                        ).toFixed(2)}
                        %
                      </span>
                    </p>
                  )}
                </div>
                <div className="d-flex flex-column order-1 order-sm-0">
                  <h4 className="text--rm text--black-dark mb-2">
                    Apresentação
                  </h4>
                  <p className="text-desc text--black-dark">
                    {medicine.presentation}
                  </p>
                  {medicine.is_controlled && (
                    <table className="mt-3">
                      <tr>
                        <td>
                          <div className="mb-2">
                            <p>{medicine.spectrum}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="mb-2">
                            <b>CBD:</b>{' '}
                            {medicine.cbdmg && `${medicine.cbdmg} mg`}{' '}
                            {medicine.cbdp && `/ ${medicine.cbdp} %`}
                          </div>
                        </td>
                        {(medicine.thcmg || medicine.thcp) && (
                          <td>
                            <div className="mb-2">
                              <b>THC:</b>{' '}
                              {medicine.thcmg && `${medicine.thcmg} mg /`}
                              {medicine.thcp} %
                            </div>
                          </td>
                        )}
                      </tr>
                      <tr>
                        {medicine.cbnmg && (
                          <td>
                            <div className="mb-2">
                              <b>CBN:</b> {medicine.cbnmg} mg / {medicine.cbnp}{' '}
                              %
                            </div>
                          </td>
                        )}
                        {medicine.cbgmg && (
                          <td>
                            <div className="mb-3">
                              <b>CBG: </b> {medicine.cbgmg} mg{' '}
                              {medicine.cbgp && `/ ${medicine.cbgp} %`}
                            </div>
                          </td>
                        )}
                      </tr>
                    </table>
                  )}
                </div>
                <div className="d-flex">
                  <div className="d-flex mt-4 mb-5 mt-sm-0 mb-sm-0 pr-5 pt-3">
                    {medicine.is_controlled ? (
                      <Link to="/comprar-cbd">
                        <Button
                          data-toggle="tooltip"
                          title="Compra permitida mediante prescrição médica e autorização da Anvisa."
                          className="primary md"
                        >
                          Como comprar CBD
                        </Button>
                      </Link>
                    ) : (
                      <a href={validateLink()}>
                        <Button
                          data-toggle="tooltip"
                          title="Compra permitida mediante prescrição médica e autorização da Anvisa."
                          className="primary md"
                        >
                          Comprar
                        </Button>
                      </a>
                    )}

                    {medicine.leaflet && (
                      <a
                        target="_blank"
                        href={medicine.leaflet.uuidUrl}
                        rel="noopener"
                      >
                        <Button className="outline-primary md ml-3">
                          Certificado de Análise
                        </Button>
                      </a>
                    )}
                    {(session.roles[0] === 'administrator' ||
                      session.roles[0] === 'physician') &&
                      medicine.type == 'Óleo' &&
                      medicine.show_calculator && (
                        <Button
                          className="outline-primary md-lg ml-3 py-0"
                          type="button"
                          data-toggle="modal"
                          data-target="#calculatorModal"
                        >
                          <i className="fas fa-calculator mr-2 font-size-19" />
                          <span className="font-weight-normal font-size-19">
                            Calcule a Dosagem
                          </span>
                          <span
                            className="font-weight-normal font-size-12 d-block"
                            style={{ fontSize: 12 + 'px' }}
                          >
                            (Exclusivo para médicos)
                          </span>
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </Col>

            <Col md={3} className="d-none">
              <div className="fav container d-flex align-content-center">
                <p className="text text--black-dark text-center d-flex align-items-center">
                  Favoritar medicamento
                </p>
                <div>
                  <svg className="icon">
                    <use xlinkHref={`${Sprites}#icon-heart-o`} />
                  </svg>
                </div>
              </div>
            </Col>
          </Row>
          {medicine.is_controlled ? (
            <span className="text-danger mt-4">
              <b>Atenção: </b>Compra permitida mediante prescrição médica e
              autorização da Anvisa.
            </span>
          ) : (
            <span className="text-danger mt-4">
              <b>Atenção: </b>este produto não contém cannabis ou derivados,
              portanto não depende de prescrição médica. A sua venda é livre.
            </span>
          )}
          <Row>
            <Col xs={12} className="mt-4 mb-2">
              <h4 className="text--rm text--black-dark mb-2">Descrição</h4>
            </Col>
            <Col>
              <p className="text-desc">{medicine.description}</p>
            </Col>
          </Row>
          {medicine.composition ? (
            <Row>
              <Col xs={12} className="mt-4 mb-2">
                <h4 className="text--rm text--black-dark mb-2">Composição</h4>
              </Col>
              <Col>
                <p className="text-desc">{medicine.composition}</p>
              </Col>
            </Row>
          ):''}
          {medicine.use ? (
          <Row>
            <Col xs={12} className="mt-4 mb-2">
              <h4 className="text--rm text--black-dark mb-2">Uso</h4>
            </Col>
            <Col>
              <p className="text-desc">{medicine.use}</p>
            </Col>
          </Row>
          ):''}
        </Container>
      </SectionProduct>
      {medicine.is_controlled && (
        <section className="section pt-md-4">
          <Container fluid="lg">
            <Row className="mb-md-5">
              <Col xs={12}>
                <h1 className="text--sm text--black text-center mb-4 mb-md-5">
                  Produtos relacionados
                </h1>
              </Col>
              <Products
                products={medicines}
                showTitle={false}
                showButton={false}
              />
            </Row>
          </Container>
        </section>
      )}
    </>
  )
}
