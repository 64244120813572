import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { Container, Brand } from './styles'
import Button from '~/components/Button'
import api from '~/services/api'
import Sprites from '~/assets/icons/sprites.svg'
import BrandImage from '~/assets/images/product-sample.jpg'
import { RenderImg } from '~/components/RenderImg/RenderImg'
import { Checkbox } from '~/components/Form'
import { useForm } from 'react-hook-form'

export default function Medicines() {
  const { register, watch } = useForm({
    validationSchema: {},
  })
  const [brands, setBrands] = useState([])
  const [loading, setLoading] = useState(false)
  const showDisableBrands = watch('showDisableBrands')

  const getBrands = async () => {
    setLoading(true)
    const res = await api.get('/brands')
    setBrands(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getBrands()
  }, [])

  return (
    <Container>
      <Row>
        <Col>
          <h2>Produtos</h2>
          <Checkbox
            className="my-3"
            name="showDisableBrands"
            label="Mostrar marcas inativas"
            ref={register}
          />
        </Col>
        <Col>
          <Link to="/admin/produtos/novo">
            <Button className="primary mr-3">Cadastrar Produto</Button>
          </Link>
          <Link to="/admin/produtos/marca/novo">
            <Button className="blue">Cadastrar Representante</Button>
          </Link>
        </Col>
      </Row>
      <hr className="separator" />
      <Row>
        {loading && <Spinner animation="grow" />}
        {!loading &&
          brands.map(brand => (
            <>
              {showDisableBrands === false && brand.active === true &&
                <Col lg={6} key={brand.id} className="mt-3">
                <Link to={`/admin/produtos/marca/${brand.slug}`}>
                  <Brand>
                    <div className="avatar">
                      {brand.logo ? (
                        <img src={brand?.logo.uuidUrl} alt="" />
                      ) : (
                        <img src={BrandImage} />
                      )}
                    </div>
                    <div className="desc">
                      <div className="d-flex flex-column justify-content-center h-100 p-3">
                        <h5 className="text--rm">{brand?.name}</h5>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <Link
                        to={`/admin/produtos/marca/editar/${brand.slug}`}
                        className="anchor p-3 text--sm text-decoration-none d-flex align-items-center justify-content-end"
                      >
                        Editar
                        <span className="ml-1">
                        <svg className="icon">
                          <use xlinkHref={`${Sprites}#icon-angle-right`} />
                        </svg>
                      </span>
                      </Link>
                      {brand.active ? (
                        <p className="my-3 pl-3 active">Ativo</p>
                      ) : (
                        <p className="my-3 pl-3 inactive">Inativo</p>
                      )}
                    </div>
                  </Brand>
                </Link>
              </Col>
              }
              {
                showDisableBrands === true && (
                  <Col lg={6} key={brand.id} className="mt-3">
                    <Link to={`/admin/produtos/marca/${brand.slug}`}>
                      <Brand>
                        <div className="avatar">
                          {brand.logo ? (
                            <img src={brand?.logo.uuidUrl} alt="" />
                          ) : (
                            <img src={BrandImage} />
                          )}
                        </div>
                        <div className="desc">
                          <div className="d-flex flex-column justify-content-center h-100 p-3">
                            <h5 className="text--rm">{brand?.name}</h5>
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <Link
                            to={`/admin/produtos/marca/editar/${brand.slug}`}
                            className="anchor p-3 text--sm text-decoration-none d-flex align-items-center justify-content-end"
                          >
                            Editar
                            <span className="ml-1">
                        <svg className="icon">
                          <use xlinkHref={`${Sprites}#icon-angle-right`} />
                        </svg>
                      </span>
                          </Link>
                          {brand.active ? (
                            <p className="my-3 pl-3 active">Ativo</p>
                          ) : (
                            <p className="my-3 pl-3 inactive">Inativo</p>
                          )}
                        </div>
                      </Brand>
                    </Link>
                  </Col>
                )
              }
            </>
          ))}
      </Row>
    </Container>
  )
}
