import React from 'react'
import { Link } from 'react-router-dom'

import Button from '~/components/Button'

export default function WhatNow() {
  const redirectBlog = () => {
    return (window.open('https://blog.drcannabis.com.br/', '_blank'))
  }
  return (
    <div className='container-fluid patient__what__Now'>
      <div className='row'>
        <div className='col-lg-12 w-100'>
          <div  className='patient__what__Now__requirements'>
            <h1 className='patient__what__Now__requirements__title'>
              Vamos começar o seu tratamento com cannabis medicinal, você vai
              precisar de:
            </h1>
            <ol className=" mt-2">
              <li>Prescrição médica</li>
              <li>Autorização Anvisa</li>
              <li>Comprar o produto</li>
            </ol>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col-lg-12">
          <div className='text-center'>
            <h2 className="patient__what__Now__title">O que você quer fazer agora?</h2>
          </div>
        </div>

      </div>
      <div className="row mt-4">
        <div className='col-lg-4'>
          <Link to="/paciente/medicos" className="w-100">

              <div className='text-center patient__what__Now__box'>
                <div className="patient__what__Now__box__icon__circle my-3">
                    <i className="far fa-user-md"/>
                </div>
                <h4 className='patient__what__Now__box__title'>Quero uma prescrição</h4>

                <div className="patient__what__Now__box__description">
                  <p>Clique aqui para agendar consultas com médicos prescritores</p>
                </div>

              </div>
          </Link>
        </div>
        <div className='col-lg-4'>
          <Link to="/paciente/prescricao" className="w-100">
              <div className='text-center patient__what__Now__box'>

                  <div className="patient__what__Now__box__icon__circle my-3">
                    <i className="fas fa-file-medical-alt"/>
                  </div>

                  <h4 className='patient__what__Now__box__title' >Já tenho prescrição</h4>

                <div className="patient__what__Now__box__description">
                  <p>
                    Clique aqui para nos enviar o arquivo da sua prescrição e
                    receber a orientação seguinte
                  </p>
                </div>
              </div>
          </Link>
        </div>
        <div className='col-lg-4'>
          <div className='patient__what__Now__box__blog' onClick={() => redirectBlog()}
          >
          <div className='text-center patient__what__Now__box'>
            <div className="patient__what__Now__box__icon__circle my-3">
              <i className="fas fa-info"/>
            </div>
              <h4 className='patient__what__Now__box__title'>Quero mais informação</h4>

            <div className="patient__what__Now__box__description">
              <p>Clique aqui para descobrir tudo sobre o tratamento com cannabis medicinal</p>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}
