import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Alert } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import Yup from '~/helpers/yup'
import { Creators as ErrorActions } from '~/store/ducks/error'
import api from '~/services/api'
import { Container, Patient } from './styles'
import Button from '~/components/Button'
import Thumb from '~/components/Thumb'
import { Input, Checkbox } from '~/components/Form'
import PhotoProfile from '~/assets/images/foto_perfil.svg'
import { RenderImg } from '~/components/RenderImg/RenderImg'

let parseFormats = ['DD/MM/YYYY']

const newPatientSchema = Yup.object().shape({
  isResponsible: Yup.boolean(),
  profile: Yup.object().shape({
    full_name: Yup.string().required('Digite o nome do paciente.'),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Paciente não pode ter nascido no futuro!')
      .nullable()
      .typeError('Insira uma data Válida'),
    gender: Yup.string(),
    phone_number: Yup.string().removeSpecials(),
    mobile_number: Yup.string().removeSpecials(),
    rg: Yup.string(),
    cpf: Yup.string()
      .removeSpecials()
      .required('Digite o CPF do paciente'),
  }),
  ward: Yup.object().shape({
    isResponsible: Yup.boolean(),
    full_name: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return isResponsible
        ? schema.required('Digite o nome do Paciente')
        : schema
    }),
    birthdate: Yup.date()
      .format(parseFormats)
      .max(new Date(), 'Paciente não pode ter nascido no futuro!')
      .typeError('Insira uma data Válida')
      .nullable()
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema : schema.nullable()
      }),
    gender: Yup.string().when('isResponsible', (isResponsible, schema) => {
      return schema
    }),
    cpf: Yup.string()
      .removeSpecials()
      .when('isResponsible', (isResponsible, schema) => {
        return isResponsible ? schema.required('Digite seu CPF') : schema
      }),
  }),
  email: Yup.string()
    .email('Digite um email válido')
    .required('Digite um email'),
})

function SelectPatient(props) {
  const { onHide } = props
  const { register, errors, watch, handleSubmit, setValue } = useForm({
    validationSchema: newPatientSchema,
  })
  const [loading, setLoading] = useState(false)
  const [formVisible, setFormVisible] = useState(false)
  const [patient, setPatient] = useState({})
  const [patientData, setPatientData] = useState({})
  const cpf = watch('cpf')
  const isResponsible = watch('isResponsible')

  useEffect(() => {
    const fetchPatient = async () => {
      setLoading(true)
      const cpfClean = cpf.replace(/[^0-9 ]/g, '')
      const res = await api.get(`/patients?cpf=${cpfClean}`)
      setPatient(res.data[0])
      if (res.data[0]) {
        setPatientData(res.data[0])
      } else {
        setFormVisible(true)
        setValue('profile.cpf', cpf)
      }
      setLoading(false)
    }
    if (cpf?.length === 14) fetchPatient()
  }, [cpf])

  const onSubmit = async data => {
    onHide(data)
  }

  const handleHide = () => {
    if (!formVisible) onHide(patientData)
    else {
      onHide({})
      // setFormVisible(false)
    }
  }

  return (
    <Modal
      {...props}
      onHide={handleHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Informações do Paciente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div style={{ display: !formVisible ? 'block' : 'none' }}>
            <form>
              <Row>
                <Col lg={5}>
                  <Input
                    type="text"
                    name="cpf"
                    label="Digite o CPF do paciente para começar."
                    size="md"
                    fluid
                    ref={register}
                    maxLength="14"
                    onKeyUp={e => {
                      const value = e.target.value
                      if (value.match(/^\d{3}$/) !== null) {
                        e.target.value = value + '.'
                      } else if (value.match(/^\d{3}\.\d{3}$/) !== null) {
                        e.target.value = value + '.'
                      } else if (
                        value.match(/^\d{3}\.\d{3}\.\d{3}$/) !== null
                      ) {
                        e.target.value = value + '-'
                      }
                    }}
                  />
                </Col>
              </Row>
            </form>
            <Row>
              <Col>
                {patient?.profile && (
                  <Patient>
                    <div className="thumb">
                      <Thumb>
                        <div className="inner">
                          {patient.profile?.avatar && (
                            <img src={patient.profile?.avatar?.url} />
                          )}
                          {!patient.profile?.avatar && (
                            <img src={PhotoProfile} />
                          )}
                        </div>
                      </Thumb>
                    </div>
                    <div className="name">
                      {patient.wards.length > 0 && (
                        <p className="my-2">
                          {patient.wards[0].profile.full_name}
                        </p>
                      )}
                      <p className={patient.wards.length > 0 ? 'small' : ''}>
                        {patient.profile.full_name}
                      </p>
                    </div>
                  </Patient>
                )}
              </Col>
            </Row>
          </div>
        </>
        <form
          style={{ display: formVisible ? 'block' : 'none' }}
          id="patientForm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col lg={12}>
              <Checkbox
                className="my-3"
                name="isResponsible"
                label="Paciente tem um responsável"
                ref={register}
                error={errors.isResponsible}
              />
            </Col>

            {isResponsible && (
              <>
                <Col lg={6}>
                  <Input
                    type="text"
                    name="ward.full_name"
                    label="Nome do paciente"
                    className="mb-3"
                    size="md"
                    fluid
                    ref={register}
                    error={errors.ward?.full_name}
                  />
                </Col>
                <Col lg={3}>
                  <Input
                    type="text"
                    name="ward.cpf"
                    label="CPF do paciente"
                    className="mb-3"
                    size="md"
                    fluid
                    ref={register}
                    error={errors.ward?.cpf}
                    mask="999.999.999-99"
                  />
                </Col>

                <Col className="my-3" xs={12}>
                  <input
                    type="checkbox"
                    name="ward.isResponsible"
                    ref={register}
                    className="d-none"
                    checked={isResponsible}
                  />
                </Col>
              </>
            )}
            <Col lg={6}>
              <Input
                type="text"
                name="profile.full_name"
                label={`Nome do ${isResponsible ? 'responsavel' : 'paciente'}`}
                className="mb-3"
                size="md"
                fluid
                ref={register}
                error={errors.profile?.full_name}
              />
            </Col>
            <Col lg={6}>
              <Input
                type="text"
                name="email"
                label={`Email do ${isResponsible ? 'responsavel' : 'paciente'}`}
                className="mb-3"
                size="md"
                fluid
                ref={register}
                error={errors.email}
              />
            </Col>
            <Col lg={3}>
              <Input
                type="text"
                name="profile.cpf"
                label={`CPF do ${isResponsible ? 'responsavel' : 'paciente'}`}
                className="mb-3"
                size="md"
                fluid
                ref={register}
                error={errors.profile?.cpf}
                mask="999.999.999-99"
              />
            </Col>
            <Col className={`my-3`} xs={12}></Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        {patient?.id ? (
          <Button onClick={handleHide}>Salvar</Button>
        ) : (
          <Button form="patientForm" type="submit">
            Salvar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default function AskPatient({ next, data, refresh }) {
  const [modalShow, setModalShow] = useState(false)
  const [patient, setPatient] = useState({})
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { session } = useSelector(state => state.auth)

  const onSubmit = async () => {
    setLoading(true)
    if (patient.profile) {
      try {
        const prescription = {
          user_email: session.email,
          status: 'askMedicine',
          patient,
        }

        let res
        if (data?.id) {
          res = await api.put(`/prescriptions/${data.id}`, prescription)
        } else {
          res = await api.post(`/prescriptions`, prescription)
        }
        await refresh(res.data.id)
        next('askMedicine')
        setLoading(false)
      } catch (e) {
        if (e.response.status === 406)
          toast.error('Esta ação não é permitida')
        dispatch(ErrorActions.setError(e.response))
        setLoading(false)
      }
    } else {
      toast.error('Selecione um paciente primeiro')
    }
  }

  const closeSelectPatient = data => {
    setPatient(data)
    setModalShow(false)
  }

  let patientPreview
  if (patient.id) {
    localStorage.setItem('user_email', patient.email)
    patientPreview = (
      <Patient>
        <div className="thumb">
          <Thumb>
            <div className="inner">
              {patient.profile?.avatar && (
                <RenderImg
                  photo_id={patient.profile?.avatar?.id}
                  photo_uuid={patient.profile?.avatar?.uuidUrl}
                  isPrivate={patient.profile?.avatar?.private}
                />
              )}
              {!patient.profile?.avatar && <img src={PhotoProfile} />}
            </div>
          </Thumb>
        </div>
        <div className="name">
          {patient.wards?.length > 0 && (
            <p className="my-2">{patient.wards[0].profile.full_name}</p>
          )}
          <p className={patient.wards.length > 0 ? 'small' : ''}>
            {patient.profile.full_name}
          </p>
        </div>
      </Patient>
    )
  } else if (patient.profile?.full_name && !patient.id) {
    patientPreview = (
      <Patient>
        <div className="thumb">
          <Thumb>
            <div className="inner">
              <img src={PhotoProfile} />
            </div>
          </Thumb>
        </div>
        <div className="name">
          {patient.ward.full_name && (
            <p className="my-2">{patient.ward.full_name}</p>
          )}
          <p className={patient.ward.full_name ? 'small' : ''}>
            {patient.profile.full_name}
          </p>
        </div>
      </Patient>
    )
  } else {
    patientPreview = <div className="my-3" />
  }

  return (
    <Container>
      <Alert variant="success">
        <p>Selecione o paciente para quem você irá fazer esta prescrição.</p>
      </Alert>

      <h4 className="text-center">Quem é o paciente?</h4>
      <p></p>

      {patientPreview}

      <div className="d-flex justify-content-center my-4">
        <Button className="white" onClick={() => setModalShow(true)}>
          Indique o paciente
        </Button>
      </div>

      <div className="d-flex justify-content-end">
        <Button className="primary" onClick={onSubmit}>
          Próximo
        </Button>
      </div>

      <SelectPatient show={modalShow} onHide={closeSelectPatient} />
    </Container>
  )
}
