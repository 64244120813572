import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Alert } from 'react-bootstrap'

import Thumb from '~/components/Thumb'
import { formatValue } from '~/shared/utils'
import { RenderImg } from '~/components/RenderImg/RenderImg'
import { CreditCard } from '~/components/CreditCard/CreditCard'
import api from '~/services/api'
import { ButtonPrimary } from '~/components/Buttons/PrimaryButton'
import { toast } from 'react-toastify'

export default function Order({ data, refresh, ...rest }) {
  const [status, setStatus] = useState(data?.status ?? 'new')
  const [loading, setLoading] = useState(false)
  const [orderStep, setOrderStep] = useState(0)
  const [hidePayButton, setHidePayButton] = useState(false)

  useEffect(() => {
    switch (status) {
      case 'PURCHASED':
      case 'PAYMENT_ACCEPTED':
        setOrderStep(2)
        break
      case 'IN_TRANSIT':
        setOrderStep(3)
        break
      case 'COMPLETE':
        setOrderStep(4)
        break
      case 'CANCELLED':
        setOrderStep(0)
        break
      default:
        setOrderStep(1)
        break
    }
  }, [status])

  const sendCard = async (cardNumber, cardName, cardCVC, cardExpiry) => {
    setLoading(true)

    const requestData = {
      number: cardNumber,
      name: cardName,
      cvc: cardCVC,
      expiry: cardExpiry,
    }
    const response = await api.post(`/orders/${data.id}/charge`, requestData)
    setStatus(response.data.order_status)
    setLoading(false)
    switch (response.data.order_status) {
      case 'PAYMENT_ACCEPTED':
        toast.success('Pagamento Realizado com sucesso!')
        break
      case 'PAYMENT_REJECTED':
        toast.error('Pagamento rejeitado!')
        break
      case 'PROCESSING_PAYMENT':
        toast.info('Aguarde, seu pagamento está sendo processado!')
        break
    }
  }

  const cancellableOrderStatus = [
    'PENDING_VALIDATION',
    'VALIDATION_FAILED',
    'VALIDATION_SUCCEEDED',
    'WAITING_FOR_PAYMENT',
    'PROCESSING_PAYMENT',
  ]

  const modalClose = async () => {
    await refresh()
  }
  const shipping = data.shipping*1;
  const orderTotal = data.medicines.reduce(
    (acc, medicine) => {
      return acc+(medicine.price*medicine.pivot.quantity) },
    shipping
  );


  return (
    <>
      <div  className="container-fluid orders__order" {...rest}>

        <div className="row orders__order__box mt-4">
          <div className="orders__order__header w-100 px-3 pb-2 d-flex justify-content-between">
            <h2>
              Pedido realizado: {moment(data.created_at).format('DD/MM/YY')}
            </h2>
            <h2>Pedido #{data.id}</h2>
          </div>
          {data.status === 'new' && (
            <Alert variant="success mx-3">
              Estamos analisando a sua documentação, assim que estiver revisada
              pela equipe Dr. Cannabis você receberá um email com as orientações
              de pagamento.
            </Alert>
          )}
          {data.medicines.map(medicine => (
            <div className="col-lg-12  d-flex mt-2 orders__order__content">
              <div className="d-flex orders__order__product__image">
                <Thumb className="lg orders__order__product__image__thumb">
                  <div className="inner">
                    <RenderImg
                      isPrivate={true}
                      alt=""
                      photo_id={medicine.photo_id}
                    />
                  </div>
                </Thumb>
              </div>
              <div className="orders__order__product__info">
                <div className="orders__order__product__info__name">
                  <h4 className="">{medicine?.name}</h4>
                  <p>Vendido por: {medicine?.brand.name}</p>
                </div>
                <div className="orders__order__product__info__quantity d-flex justify-content-between">
                  <div className="">
                    <p className="">
                      {medicine?.pivot.quantity} Unidade(s)
                    </p>
                  </div>
                  <div className="text-center">
                    {formatValue(medicine.price, medicine.currency)}
                  </div>
                  <div className="">
                    {formatValue( medicine.price * medicine.pivot.quantity, medicine.currency )}
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-lg-4 offset-lg-8 orders__order__product__info__shipping font-weight-normal  mb-2 d-flex justify-content-between">
                    <h6>Frete</h6>
                    <span>
                      {formatValue( shipping,
                        medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency
                      )}
                    </span>
                  </div>
                </div>
                <div className="row  mt-2">
                  <div className="col-12 col-lg-4 offset-lg-8 orders__order__product__info__shipping font-weight-normal mb-2 d-flex justify-content-between">
                    <h6>Total do pedido</h6>
                    <span>
                    {formatValue( orderTotal,
                      medicine.shipping_currency ? medicine.shipping_currency : medicine.brand.shipping_currency
                    )}
                    </span>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-4 offset-lg-8 font-weight-normal text-dark d-flex justify-content-between">
                    {(status === 'WAITING_FOR_PAYMENT' ||
                      status === 'PAYMENT_REJECTED') && (
                      <ButtonPrimary
                        callBack={e => setHidePayButton(true)}
                        btnClass={`${hidePayButton ? 'd-none' : 'w-100 btn__show__payment'} `}
                        target={`#card-${data.id}`}
                        toggle="collapse"
                        text='Pagar'
                      >
                        Pagar
                      </ButtonPrimary>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div id={`card-${data.id}`} className="collapse col-lg-12">
            {(status === 'WAITING_FOR_PAYMENT' ||
              status === 'PAYMENT_REJECTED') &&  (
              <CreditCard
                title="Pagar"
                loading={loading}
                action={(cardNumber, cardName, cardCVC, cardExpiry) => {
                  sendCard(cardNumber, cardName, cardCVC, cardExpiry)
                }}
                target={`#card-${data.id}`}
                callBack={e => setHidePayButton(false)}
              />

            )}
          </div>

          <div className="orders__order__footer">
            {data.status !== 'CANCELLED' ? (
              <>
                <div className="d-flex orders__order__footer__steps">
                <span
                  className={`step ${orderStep >= 1 && 'drc-bg text-white'}`}
                >
                  Produtos selecionados
                  {orderStep >= 1 && <i className="fas fa-check pl-2" />}
                </span>
                  <span
                    className={`step ${orderStep >= 2 && 'drc-bg text-white'}`}
                  >
                  Pagamento autorizado
                    {orderStep >= 2 && <i className="fas fa-check pl-2" />}
                </span>
                  <span
                    className={`step ${orderStep >= 3 && 'drc-bg text-white'}`}
                  >
                  Em transporte
                    {orderStep >= 3 && <i className="fas fa-check pl-2" />}
                </span>
                  <span
                    className={`step ${orderStep >= 4 && 'drc-bg text-white'}`}
                  >
                  Produto entregue
                    {orderStep >= 4 && <i className="fas fa-check pl-2" />}
                </span>
                </div>
              </>
            ) : (
              <div className="d-flex steps">
              <span className={`step cancelled`}>
                Pedido cancelado
                <i className="fas fa-times pl-2" />
              </span>
              </div>
            )}
          </div>
        </div>


      </div>
    </>
  )
}
