import React, { useState, useEffect } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useForm, useFormContext, FormContext } from 'react-hook-form'

import pdfIcon from '~/assets/icons/pdfIcon.png'
import { Container, Medicine } from './styles'
import api from '~/services/api'
import Button from '~/components/Button'
import ModalMedicine from './ModalMedicine'
import Thumb from '~/components/Thumb'
import { Input, Select } from '~/components/Form'
import ModalNewOrder from './ModalNewOrder'
import ModalSelectPhysician from '~/components/ModalSelectPhysician'
import { generatePdf, transform } from '~/shared/utils'
import UploadFile from '~/components/UploadFile'
import moment from 'moment'

export default function Prescription() {
  const [prescription, setPrescription] = useState({ medicines: [] })
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [modalShow, setModalShow] = useState(false)
  const formMethods = useForm()
  const { register, watch, setValue } = useForm()
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showModalNewOrder, setShowModalNewOrder] = useState(false)
  const [baseAnvisa, setBaseAnvisa] = useState('')
  const [basePrescription, setBasePrescription] = useState('')
  const [baseProofOfAddress, setBaseProofOfAddress] = useState('')
  const [baseRg, setBaseRg] = useState('')
  const [showLinkAnvisa, setShowLinkAnvisa] = useState(false)
  const [showLinkRg, setShowLinkRg] = useState(false)
  const [showLinkPrescription, setShowLinkPrescription] = useState(false)
  const [isSubmitNemFile, setIsSubmitNemFile] = useState(false)
  const [showModalSelectPhysician, setShowModalSelectPhysician] = useState(
    false,
  )
  const history = useHistory()
  const {
    review_prescription,
    review_anvisa,
    review_document,
    review_address,
  } = watch()

  const getPrescription = async () => {
    setLoading(true)
    const res = await api.get(`/prescriptions/${id}`)
    setPrescription(res.data)
    setValue('review_document', res.data.review_document)
    setValue('review_anvisa', res.data.review_anvisa)
    setValue('review_prescription', res.data.review_prescription)
    setValue('review_address', res.data.review_address)
    setLoading(false)
  }

  useEffect(() => {
    getPrescription()
  }, [])

  useEffect(() => {
    if (!loading) {
      const data = {
        review_address,
        review_prescription,
        review_anvisa,
        review_document,
      }
      api.put(`/prescriptions/${id}`, data)
    }
  }, [review_address, review_prescription, review_anvisa, review_document])

  const createOrder = async () => {
    setShowModalNewOrder(true)
  }

  const deletePrescription = async () => {
    setLoading(true)
    await api.delete(`/prescriptions/${id}`)
    history.push('/admin/prescricoes')
    setLoading(false)
  }

  const saveMedicines = async pivotMedicines => {
    setLoading(true)
    const medicinesData = prescription.medicines.map((medicine, idx) => {
      return { id: medicine.id, ...pivotMedicines.medicines[idx] }
    })
    const prescriptionData = {
      medicines: medicinesData,
    }
    await api.put(`/prescriptions/${prescription.id}`, prescriptionData)
    setLoading(false)
  }

  const closeSelectMedicines = async () => {
    await getPrescription()
    setModalShow(false)
  }

  const closeNewOrder = async () => {
    await getPrescription()
    setShowModalNewOrder(false)
  }

  const closeSelectPhysician = async () => {
    await getPrescription()
    setShowModalSelectPhysician(false)
  }

  useEffect(() => {
    if (prescription) {
      if (prescription.file) {
        let url = `/files/${prescription.file.id}`
        if (url) {
          transform(url).then(r => setBasePrescription(r))
        }
      }
    }
  }, [prescription.file])

  useEffect(() => {
    if (prescription) {
      if (prescription.patient?.documents[0]?.file?.id) {
        let url = `/files/${prescription.patient.documents[0].file.id}`
        if (url) {
          transform(url).then(r => {
              setBaseRg(r)
            }
          )
        }
      }
      if (prescription.patient?.addresses[0]?.file?.id) {
        let url = `/files/${prescription.patient?.addresses[0]?.file?.id}`
        if (url) {
          transform(url).then(r => {
              setBaseProofOfAddress(r)
            }
          )
        }
      }
    }
  }, [prescription.user])

  useEffect(() => {
    if (prescription) {
      if (prescription.anvisa) {
        let url = `/files/${prescription.anvisa.id}`
        if (url) {
          transform(url).then(r => setBaseAnvisa(r))
        }
      }
    }
  }, [prescription.anvisa])

  useEffect(() => {
    if (basePrescription.substr(0, 5) === 'data:') {
      setShowLinkPrescription(true)
    }
  }, [basePrescription])

  useEffect(() => {
    if (baseRg.substr(0, 5) === 'data:') {
      setShowLinkRg(true)
    }
  }, [baseRg])

  useEffect(() => {
    if (baseAnvisa.substr(0, 5) === 'data:') {
      setShowLinkAnvisa(true)
    }
  }, [baseAnvisa])

  return (
    <Container>
      <Row>
        <Col>
          <h2>Prescrição #{prescription.id}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="my-3">
          <Alert show={showConfirmDelete} variant="danger">
            <Alert.Heading>Deseja mesmo deletar essa prescrição?</Alert.Heading>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={deletePrescription} className="danger">
                Deletar
              </Button>
            </div>
          </Alert>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="d-flex flex-column">
          <h4>Paciente:</h4>
          <p className="mt-2">
            {prescription.patient?.profile?.full_name}

            {prescription.patient?.profile?.mobile_number && (

              <a href={`https://api.whatsapp.com/send?phone=+55${prescription.patient?.profile?.mobile_number}`} className={'ml-3'}>
                <i className="fab fa-whatsapp font-rem-1-4" />
              </a>

            )}
          </p>

          <Link
            className="btn-link mt-2"
            to={`/admin/pacientes/${prescription.patient?.id}`}
          >
            Perfil do paciente
          </Link>
          <Link
            className="btn-link mt-2"
            to={`/template/anvisa/${prescription.id}`}
            target="_blank"
          >
            Gabarito Anvisa
          </Link>
        </Col>
        <Col className="d-flex flex-column">
          <h4>Médico:</h4>
          <p className="mt-2">
            {prescription.physician
              ? prescription.physician.profile.full_name
              : prescription.prePhysician
                ? prescription.prePhysician.full_name
                : 'Não informado'}
          </p>
          {prescription.physician && (
            <Link
              className="btn-link mt-2"
              to={`/admin/medicos/${prescription.physician?.physician?.id}`}
            >
              Perfil do médico
            </Link>
          )}
          <a
            className="btn-link mt-2"
            href="#"
            onClick={() => setShowModalSelectPhysician(true)}
          >
            Selecionar Médico
          </a>
        </Col>
        <Col>
          <h4>Criado:</h4>
          <p className="mt-2">{prescription.user?.profile.full_name}</p>
          <p className="mt-2">
            {moment(prescription.created_at).format('DD/MM/YYYY')}
          </p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="d-flex">
            {prescription.file && (
              <a
                href={basePrescription}
                target="_blank"
                rel="noopener"
                className={showLinkPrescription ? '' : 'd-none'}
                download={`${prescription.patient?.profile?.full_name}-${prescription.id}`}
              >
                <div className="file">
                  <img src={pdfIcon} />
                  <p>Prescrição</p>
                </div>
              </a>
            )}
            {prescription.anvisa && (
              <a
                href={baseAnvisa}
                target="_blank"
                rel="noopener"
                className={showLinkAnvisa ? '' : 'd-none'}
                download={`${prescription.patient?.profile?.full_name}-${prescription.id}`}
              >
                <div className="file">
                  <img src={pdfIcon} />
                  <p>ANVISA</p>
                </div>
              </a>
            )}
            {prescription.patient?.documents[0]?.file?.id && (
              <a
                href={baseRg}
                target="_blank"
                rel="noopener"
                className={showLinkRg ? '' : 'd-none'}
                download={`${prescription.patient?.profile?.full_name}-documento-rg`}
              >
                <div className="file">
                  <img src={pdfIcon} />
                  <p>RG</p>
                </div>
              </a>
            )}
            {prescription.patient?.addresses[0]?.file?.id && (
              <a
                href={baseProofOfAddress}
                target="_blank"
                rel="noopener"
                className={showLinkRg ? '' : 'd-none'}
                download={`${prescription.patient?.profile?.full_name}-comprovante-residencia`}
              >
                <div className="file">
                  <img src={pdfIcon} />
                  <p>C.Residência</p>
                </div>
              </a>
            )}
          </div>
        </Col>

        {prescription.orders?.length > 0 && (
          <Col className="my-3">
            <h4>Pedidos:</h4>
            {prescription.orders?.map(order => {

              return (
                <div className="my-2">
                  <Link
                    key={order.id}
                    className="btn-link"
                    to={`/admin/pedidos/${order.id}`}
                  >
                    Pedido {order.id}
                  </Link>
                </div>
              )
            })}
          </Col>
        )}

        <Col>
          <Row>
            <Col>
              <div className="d-flex flex-column align-middle">
                <Button className="primary md mb-3" onClick={createOrder}>
                  Criar Pedido
                </Button>
              </div>
            </Col>
            <Col>
              <div>
                <Button
                  className="outline-danger md"
                  onClick={() => setShowConfirmDelete(true)}
                >
                  Deletar prescrição
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <UploadFile
            btnText="Prescrição"
            assetType="prescription"
            update={true}
            url={`/prescriptions/${id}`}
          />
          <UploadFile
            btnText="Anvisa"
            assetType="anvisa"
            update={true}
            url={`/prescriptions/${id}`}
          />
          <UploadFile
            btnText="RG"
            assetType="identification"
            update={true}
            url={`user/${prescription?.patient?.id}/image`}
            userId={prescription?.patient?.id}
            name="rg"
          />
          <UploadFile
            btnText="C.Residência"
            assetType="address"
            update={true}
            url={`/addresses/${prescription.patient?.addresses[0]?.id}`}
            error={prescription.patient?.addresses[0]?.id}
          />
        </div>
      </div>
      <form>
        <Row>
          <Col lg={3}>
            <Select
              ref={register}
              name="review_prescription"
              label="Prescrição"
              size="md"
              fluid
              icon="icon-chevron-down"
              className="my-3"
            >
              <option value="review">Não revisado</option>
              <option value="ok">Okey</option>
              <option value="fail">Não okey</option>
            </Select>
          </Col>
          <Col lg={3}>
            <Select
              ref={register}
              name="review_anvisa"
              label="Anvisa"
              size="md"
              fluid
              icon="icon-chevron-down"
              className="my-3"
            >
              <option value="review">Não revisado</option>
              <option value="ok">Okey</option>
              <option value="fail">Não okey</option>
            </Select>
          </Col>
          <Col lg={3}>
            <Select
              ref={register}
              name="review_document"
              label="Documento"
              size="md"
              fluid
              icon="icon-chevron-down"
              className="my-3"
            >
              <option value="review">Não revisado</option>
              <option value="ok">Okey</option>
              <option value="fail">Não okey</option>
            </Select>
          </Col>
          <Col lg={3}>
            <Select
              ref={register}
              name="review_address"
              label="Comprovante de Residência"
              size="md"
              fluid
              icon="icon-chevron-down"
              className="my-3"
            >
              <option value="review">Não revisado</option>
              <option value="ok">Okey</option>
              <option value="fail">Não okey</option>
            </Select>
          </Col>
        </Row>
      </form>
      <form onSubmit={formMethods.handleSubmit(saveMedicines)}>
        <Row className="mt-3">
          <Col lg={12}>
            <h3>Produtos:</h3>
            {prescription.medicines && (
              <FormContext {...formMethods}>
                {prescription.medicines.map((medicine, idx) => (
                  <WindowedRow index={idx} item={medicine} />
                ))}
              </FormContext>
            )}
          </Col>
          <Col lg={12} className="d-flex justify-content-between">
            <Button
              className="primary mt-3"
              type="button"
              onClick={() => setModalShow(true)}
            >
              Selecionar produtos
            </Button>

            {prescription.medicines.length > 0 && (
              <Button className="primary mt-3" type="submit" disabled={loading}>
                Salvar Medicamentos
              </Button>
            )}
          </Col>
        </Row>
      </form>

      <ModalMedicine
        show={modalShow}
        onHide={closeSelectMedicines}
        prescriptionId={prescription.id}
      />
      <ModalNewOrder
        show={showModalNewOrder}
        onHide={closeNewOrder}
        prescriptionId={prescription.id}
      />

      <ModalSelectPhysician
        show={showModalSelectPhysician}
        onHide={closeSelectPhysician}
        prescriptionId={prescription.id}
      />
    </Container>
  )
}

const WindowedRow = React.memo(({ index, item }) => {
  const { register, errors } = useFormContext()

  return (
    <div>
      {index !== 0 && <hr className="separator" />}

      <Medicine key={item.id}>
        <div className="d-flex align-items-center">
          <div className="thumb">
            <Thumb>
              <div className="inner">
                <Link
                  to={`/produtos/${item.slug}`}
                >
                  <img src={item.photo?.uuidUrl} alt="" />
                </Link>
              </div>
            </Thumb>
          </div>
          <div className="name">
            <Link
              to={`/produtos/${item.slug}`}
              className="btn-link"
            >
              {item.name}
            </Link>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Row>
            <Col md={2}>
              <div>
                <Input
                  className="my-3"
                  label="Qnt"
                  size="md"
                  fluid
                  name={`medicines[${index}].quantity`}
                  ref={register({ required: true })}
                  error={
                    errors.medicines ? errors.medicines[index]?.quantity : false
                  }
                  defaultValue={item.pivot.quantity}
                  type="number"
                />
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  className="my-3"
                  label="Uso"
                  size="md"
                  fluid
                  ref={register({ required: true })}
                  defaultValue={item.pivot.use}
                  name={`medicines[${index}].use`}
                  error={
                    errors.medicines ? errors.medicines[index]?.use : false
                  }
                />
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  className="my-3"
                  label="Duração"
                  size="md"
                  fluid
                  ref={register({ required: true })}
                  defaultValue={item.pivot.duration}
                  name={`medicines[${index}].duration`}
                  error={
                    errors.medicines ? errors.medicines[index]?.duration : false
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </Medicine>
    </div>
  )
})
