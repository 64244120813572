import React from "react"
import InputMask from 'react-input-mask';


const CustomInput = (
  {
    inputName = '',
    label,
    error,
    inputClass = '',
    register,
    inputType = 'text',
    inputPlaceholder,
    mask,
  })=>{
  return(
    <div className='form-group custom__input'>
      <label className='w-100' htmlFor="">
        {label}
        <InputMask
          className={`form-control ${inputClass} ${error ?  'error' : ''}`}
          name={inputName}
          ref={register}
          type={inputType}
          placeholder={inputPlaceholder}
          mask={mask}
        />
        {error && <span className="error">{error.message}</span>}
      </label>
    </div>
  )
}

export  {CustomInput}
