import { createGlobalStyle } from 'styled-components'

import RobotoBold from '~/assets/fonts/Roboto/Roboto-Bold.ttf'
import RobotoMedium from '~/assets/fonts/Roboto/Roboto-Medium.ttf'
import RobotoRegular from '~/assets/fonts/Roboto/Roboto-Regular.ttf'
import SarabunBold from '~/assets/fonts/Sarabun/Sarabun-Bold.ttf'
import SarabunMedium from '~/assets/fonts/Sarabun/Sarabun-Medium.ttf'
import SarabunRegular from '~/assets/fonts/Sarabun/Sarabun-Regular.ttf'

const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: #fff;
    font-family: Sarabun, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #000;
  }
  button {
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  p, h1, h2, h3, h4, h5, h6, ul, li, ol,
  *,
  *::after,
  *::before {
    line-height: 1;
    margin: 0;
    padding: 0;
    font-weight: unset;
    box-sizing: border-box;
  }

  p, h1, h2, h3, h4, h5, h6, ul, li, ol,
  *,
  *:active,
  *:focus {
    outline: none;
    box-sizing: border-box;
  }

  a {
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
    &.link {
      text-decoration: underline;
      color: #72c7b6;
    }
  }

  .container,
  .container-md,
  .container-lg,
  .container-xl {
    @media only screen and (min-width: 1441px) {
      max-width: 1440px;
    }
  }

  // @font-face {
  //   font-family: robotoBold;
  //   src: url(${RobotoBold}) format("opentype");
  //   font-display: swap;
  // }
  //
  // @font-face {
  //     font-family: Roboto;
  //     src: url(${RobotoRegular}) format("opentype");
  //     font-display: swap;
  // }
  //
  // @font-face {
  //     font-family: robotoMedium;
  //     src: url(${RobotoMedium}) format("opentype");
  //     font-display: swap;
  // }
  //
  // @font-face {
  //     font-family: robotoRegular;
  //     src: url(${RobotoRegular}) format("opentype");
  //     font-display: swap;
  // }

  @font-face {
      font-family: sarabunMedium;
      src: url(${SarabunMedium}) format("opentype");
      font-display: swap;
  }

  @font-face {
      font-family: sarabunBold;
      src: url(${SarabunBold}) format("opentype");
      font-display: swap;
  }

  @font-face {
      font-family: sarabunRegular;
      src: url(${SarabunRegular}) format("opentype");
      font-display: swap;
  }

  @font-face {
      font-family: Sarabun;
      src: url(${SarabunRegular}) format("opentype");
      font-display: swap;
  }

  body {
      font-size: 1rem;
      font-family: sarabunRegular, sans-serif;
      font-weight: 400;
      line-height: 1;
      color: #2D2D2D;
  }

  .text {
    &--black {
        color: #2D2D2D;
    }

    &--black-dark {
        color: #000000;
    }

    &--white {
        color: #FFFFFF;
    }

    &--grey {
        color: #6F6F6F;
    }

    &--grey-dark {
        color: #5C5C5C;
    }

    &--rb {
        font-family: sarabunBold, sans-serif;
        font-weight: 700;
    }

    &--rr {
        font-family: sarabunRegular, sans-serif;
        font-weight: 400;
    }

    &--rl {
        font-family: sarabunRegular, sans-serif;
        font-weight: 300;
    }

    &--rm {
        font-family: sarabunMedium, sans-serif;
        font-weight: 500;
    }

    &--sr {
        font-family: sarabunRegular, sans-serif;
        font-weight: 400;
    }

    &--sm {
        font-family: sarabunMedium, sans-serif;
        font-weight: 500;
    }
  }

  html {
    @media only screen and (min-width: 1601px) {
      font-size: 118.75%;
    };
    @media only screen and (min-width: 1441px) and (max-width: 1600px) {
      font-size: 112.25%;
    };
    @media only screen and (min-width: 1367px) and (max-width: 1440px) {
      font-size: 106.25%;
    };
    @media only screen and (min-width: 1281px) and (max-width: 1366px) {
      font-size: 100%;
    };
    @media only screen and (min-width: 768px) and (max-width: 1280px) {
      font-size: 93.75%;
    };
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      font-size: 87.5%;
    };
    @media only screen and (max-width: 575px) {
      font-size: 81.3%;
    };
  }

  .separator {
    margin: 1.5rem 0;
    height: 1px;
    width: 100%;
    background-color: rgba(111,111,111, 0.2);
  }

  .color {
    &.black {
      color: #2D2D2D !important;
    }
  }

  svg.icon {
    fill: #000000;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #72c7b6;
    border-color: #72c7b6;
  }
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #72c7b6;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }

  .skeleton {
    span {
      margin-bottom: 2px;
    }
  }

  .modal-45w {
    width: 45%;
    max-width: none!important;
  }
`

export const Variables = {
  colorTransparent: 'transparent',
  colorWhite: '#FFFFFF',
  colorBlack: '#2D2D2D',
  colorBlackDark: '#000000',
  colorGreyLighter: '#F2F2F2',
  colorGreyLight: '#C4C4C4',
  colorGreyDark: '#5C5C5C',
  colorGrey: '#6F6F6F',
  colorPrimary: '#72C7B6',
  colorSecondary: '#5DAA60',
  colorTertiary: '#71AA74',
  colorBlue: '#1C2960',

  fontrr: 'sarabunRegular',
  fontrb: 'sarabunBold',
  fontrm: 'sarabunMedium',
  fontsm: 'sarabunMedium',
  fontsr: 'sarabunRegular',

  boxShadow: '0 0 0.5rem 0 #AFAFAF',
}

export default GlobalStyle
