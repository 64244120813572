import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { SectionPillars, SectionCreators } from './styles'
import Button from '~/components/Button/index'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { SectionFaq } from '~/pages/Faq/styles'
// import LowestPrice from '~/assets/icons/menor_preco_branco.png'

export default function About() {
  const { session } = useSelector(state => state.auth)
  const geyUrl = () => {
    if (session) {
      switch (session.roles[0]) {
        case 'administrator':
          return 'https://drcannabis.com.br/admin'
        case 'physician':
          return 'https://drcannabis.com.br/medico'
        case 'patient':
          return 'https://drcannabis.com.br/paciente'
      }
    }
  }
  const title = 'Comprar CBD legalmente é mais fácil com a Dr. Cannabis'
  const description = 'A cannabis medicinal pode mudar vidas. Por isso, a Dr. Cannabis te ajuda em tudo o que você ' +
    'precisa para comprar CBD legalmente no Brasil.'
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta
          name="description"
          content={description}
        />
        <meta
          itemprop="description"
          content={description}
        />
        <meta
          name="og:description"
          content={description}
        />
        <meta
          name="twitter:description"
          content={description}
        />
      </Helmet>

      <SectionPillars>
        <Container fluid="lg">
          <Row>
            <Col xs={12}>
              <h1 className="title text--sm text--black text-center mb-3 mt-5">
                Os 3 passos para comprar CBD importado
              </h1>
            </Col>
            <Col xs={10} className="m-auto">
              <div className="separator"></div>
            </Col>
            <Col xs={12} className=" mb-0 mb-md-5">
              <p className="text text--grey subtitle">
                Antes de comprar CBD para um tratamento de saúde é necessário
                seguir algumas etapas para que a regulamentação vigente seja
                cumprida. Mas não se preocupe! A <b>Dr. Cannabis</b> te ajudará
                a passar por todo o processo com mais conforto, segurança e
                total apoio! Você encontrará médicos que realizam consultas
                online e/ou presencial, terá a orientação necessária para
                receber autorização da Anvisa e também o suporte para comprar
                CBD importado legalmente. Você pode ter a qualidade de vida
                impactada, seguindo as normas e leis brasileiras vigentes.
                Conheça abaixo o que é necessário para comprar CBD e outros
                produtos à base de cannabis, como o óleo de CBG, CBN, cápsulas
                ou cremes com canabinoides.
              </p>
              <div className="d-flex justify-content-center my-3">
                <Link to="/cadastro">
                  <Button
                    id="quero_tratamento"
                    className="quero-tratamento primary md"
                  >
                    Quero tratamento
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="banner notebook">
          <Container>
            <Row>
              <Col xs={12} md={6} className="content">
                <h2 className="title text--sm text--white mb-3">
                  Encontre um médico prescritor e vá à consulta:
                </h2>
                <p className="text text--white ">
                  Temos uma lista de profissionais selecionados e aptos a
                  prescrever produtos à base de cannabis.
                  <a
                    className="mx-2 text-info"
                    href="https://drcannabis.com.br/cadastro"
                  >
                    Cadastre-se
                  </a>
                  gratuitamente ou
                  <a
                    className="mx-2 text-info"
                    href="https://drcannabis.com.br/login"
                  >
                    acesse seu perfil
                  </a>
                  para visualizar os médicos que realizam consultas online e/ou
                  os consultórios mais próximos de sua região. Durante o
                  atendimento, será avaliado se a cannabis é uma opção viável de
                  tratamento para o paciente e o caso diagnosticado.
                </p>
                <div className="d-flex justify-content-center mt-3">
                  <Link to="/paciente/medico">
                    <Button
                      id="quero_medico"
                      className="quero-medico primary md"
                    >
                      Quero um Médico
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="banner medicine">
          <Container>
            <Row>
              <Col>
                <h2 className="title text--sm text--white mb-3">
                  Autorização da Anvisa.
                </h2>
                <p className="text text--white ">
                  Na plataforma <b>Dr Cannabis</b>, o médico prescreve em menos
                  de 5 minutos e o paciente acessa o gabarito com um
                  passo-a-passo simples para receber autorização da Anvisa. A
                  partir daí, nós tomamos conta da burocracia para você comprar
                  CBD.
                </p>
                <p className="text text--white  my-2">
                  O prazo para a análise, resposta com ofício de autorização
                  informado pela agência é, em média, de 10 dias úteis. A
                  autorização tem validade de 24 meses e a quantidade para
                  compra é limitada pela receita médica.
                </p>
                <div className="d-flex justify-content-center mt-3">
                  <Link to="/paciente">
                    <Button
                      id="quero_autorizacao"
                      className="quero-autorizacao primary md"
                    >
                      Quero autorização
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="banner woman">
          <Container>
            <Row>
              <Col xs={12} md={4}>
                <h2 className="title text--sm text--white mb-3">
                  Recebi aprovação da Anvisa, e agora?
                </h2>
                <p className="text text--white ">
                  Com a prescrição médica e a autorização da Anvisa em mãos, é
                  possível comprar CBD legalmente. Faça o upload destes
                  documentos em seu{' '}
                  <a className="mx-2 text-info" href={geyUrl()}>
                    perfil
                  </a>{' '}
                  na Dr. Cannabis. Segundo as normas da Anvisa, só é possível
                  comprar CBD conforme as especificações da receita médica, ou
                  seja, o paciente poderá importar apenas o produto prescrito da
                  marca indicada pelo profissional.
                </p>
                <div className="d-flex justify-content-center mt-3">
                  <Link to="/paciente" className="mt-3">
                    <Button
                      id="quero_comprar"
                      className="quero-comprar primary md"
                    >
                      Quero comprar
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="my-5 banner anvisa">
          <Container>
            <Row className="justify-content-center">
              <Col xs={12}>
                <h2 className="title text--sm mb-3">
                  Já tem autorização da ANVISA?
                </h2>
                <p className="text  my-2">
                  Cadastre-se na <b>Dr Cannabis</b> e acesse a cotação do seu
                  produto! Envie os documentos pela plataforma e deixe o
                  restante conosco.
                </p>
                <p className="text  my-2">
                  <b>Importante:</b> A <b>Dr Cannabis</b> faz a cotação do seu
                  medicamento e acompanha a importação, sem taxas adicionais ao
                  preço final do produto prescrito e autorizado.
                </p>
                <p className="text  my-2">
                  <b>Quer comprar CBD?</b> A <b>Dr Cannabis</b> encontra o
                  fornecedor do produto prescrito por seu médico e autorizado
                  pela Anvisa e te auxilia com a cotação sem nenhum custo
                  adicional e sem compromisso. O produto chega direto ao
                  endereço do paciente cadastrado na Anvisa e de acordo com o
                  prazo estabelecido pelo fornecedor. E nós também acompanhamos
                  a importação dos produtos das marcas parceiras!
                </p>
                <p className="text  my-2">
                  Dica: A cópia do Ofício de autorização emitido pela Anvisa
                  deve ser mantida junto ao produto sempre que o paciente
                  estiver com ele, seja dentro ou fora do Brasil.
                </p>
                <div className="d-flex justify-content-center mt-3">
                  <Link to="/paciente">
                    <Button
                      id="acesse_seu_perfil"
                      className="acesse-seu-perfil primary md"
                    >
                      Acesse seu perfil
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </SectionPillars>
    </>
  )
}
