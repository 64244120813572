import React from 'react'

const CustomSelect =
  (
     {
      label,
      inputName,
      register,
      children,
      inputClass,
       error,
     }
  ) =>{
  return(
    <div className='form-group custom__select'>
      <label className='w-100' htmlFor={inputName}>
        {label}
        <select
          className={`form-control ${inputClass}`}
          name={inputName}
          id={inputName}
          ref={register}
        >
          {children}
        </select>
      </label>
        {error && <span className="error">{error.message}</span>}

    </div>
  )
}

export { CustomSelect }
