import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import api from '~/services/api'
import { Physician, PhysicianList } from './styles'
import Button from '~/components/Button'
import Thumb from '~/components/Thumb'
import { Input, Select } from '~/components/Form'
import PhotoProfile from '~/assets/images/foto_perfil.svg'
import StatesBrasil from '~/helpers/StatesBrasil.json'
import Specialties from '~/helpers/Specialties.json'
import useDebounce from '~/shared/useDebounce'

export default function SelectPhysician(props) {
  const { prescriptionId, onHide } = props
  const { register, formState, handleSubmit, watch, reset } = useForm()
  const [prescription, setPrescription] = useState({})
  const [loading, setLoading] = useState(false)
  const [finishRequest, setFinishRequest] = useState(false)
  const [formVisible, setFormVisible] = useState(false)
  const [physicians, setPhysicians] = useState([])
  const { uf, crm, name } = watch(['uf', 'crm', 'name'])
  const dirty = formState.dirty

  const debouncedSearchCrm = useDebounce(crm, 700)
  const debouncedSearchName = useDebounce(name, 700)

  const fetchPrescriptions = async () => {
    setLoading(true)
    const res = await api
      .get(`/prescriptions/${prescriptionId}`)
      .then((res) => {
          setLoading(false)
          setPrescription(res.data)
        }
      )
  }

  useEffect(() => {
    if (debouncedSearchName || (debouncedSearchCrm && crm.length >= 4)) {
      setLoading(true)
      const fetchPhysicians = async () => {
        const res = await api
          .get(`/prescription/physicians?crm=${crm ?? ''}&name=${name ?? ''}`)
          .finally(() => {
            setFinishRequest(true)
            setLoading(false)
          })
        setPhysicians(res.data)
      }
      if ((name ?? '' !== '') || (crm ?? '' !== '')) {
        fetchPhysicians()
      }
    }
  }, [debouncedSearchCrm, debouncedSearchName])

  useEffect(() => {
    if (prescription.prePhysician) {
      setFormVisible(true)
      reset({ profile: prescription.prePhysician })
    }
  }, [prescription])

  const selectPhysician = async id => {
    setLoading(true)
    const data = {
      physician_id: id,
    }
    await api
      .put(`/prescriptions/${prescriptionId}`, data)
      .then(() => setLoading(false))
    await fetchPrescriptions()
    onHide()
  }

  const onSubmit = async data => {
    if (prescription.prePhysician) {
      setLoading(true)
      await api.put(
        `/preprofiles/${prescription.prePhysician.id}`,
        data.profile,
      )
      setLoading(false)
    } else {
      setLoading(true)
      await api.post(`/preprofiles`, {
        prescription_id: prescriptionId,
        ...data.profile,
      })
      setLoading(false)
    }
    fetchPrescriptions()
    onHide()
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Informações do Médico
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div style={{ display: !formVisible ? 'block' : 'none' }}>
            <form>
              <Row>
                <Col md={3}>
                  <Input
                    type="text"
                    name="crm"
                    label="CRM (apenas números)"
                    size="md"
                    fluid
                    ref={register}
                  />
                </Col>
                <Col>
                  <Input
                    type="text"
                    name="name"
                    label="Nome do médico"
                    size="md"
                    fluid
                    ref={register}
                  />
                </Col>
              </Row>
            </form>
            {loading ? (
              <div className="d-flex w-100 justify-content-center mt-4">
                <div
                  className="custom-spinner-border spinner-border text-secondary"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                {prescription.physician && (
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Physician>
                        <div className="thumb">
                          <Thumb>
                            <div className="inner">
                              {prescription.physician?.profile?.avatar && (
                                <img
                                  src={
                                    prescription.physician?.profile?.avatar
                                      ?.uuidUrl
                                  }
                                />
                              )}
                              {!prescription.physician?.profile?.avatar && (
                                <img src={PhotoProfile} />
                              )}
                            </div>
                          </Thumb>
                        </div>
                        <div className="name">
                          {prescription.physician?.profile.full_name}
                        </div>
                      </Physician>
                    </Col>
                  </Row>
                )}
                <hr className="separator" />
                <PhysicianList>
                  <Row>
                    {physicians.data?.map(physician => (
                      <Col lg={6}>
                        <div
                          className="physician"
                          key={physician.id}
                          onClick={() => selectPhysician(physician.user.id)}
                        >
                          <div className="thumb">
                            <Thumb>
                              <div className="inner">
                                {physician.user?.profile?.avatar && (
                                  <img
                                    src={
                                      physician.user?.profile?.avatar?.uuidUrl
                                    }
                                  />
                                )}
                                {!physician.user?.profile?.avatar && (
                                  <img src={PhotoProfile} />
                                )}
                              </div>
                            </Thumb>
                          </div>
                          <div>
                            <div className="name">
                              {physician.user?.profile?.full_name}
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </PhysicianList>
                {finishRequest && !physicians.data?.length && dirty && (
                  <Row>
                    <Col>
                      <p>
                        Ops... Parece que esse médico não está na Dr. Cannabis.
                      </p>
                      <p>
                        Clique no botão abaixo e informe os dados do
                        profissional para continuar.
                      </p>
                      <Button
                        className="primary mt-2"
                        onClick={() => setFormVisible(true)}
                      >
                        Cadastrar médico
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </div>
        </>
        <form
          style={{ display: formVisible ? 'block' : 'none' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={2}>
              <Select
                name="profile.uf"
                label="UF"
                className="mb-3"
                icon="icon-chevron-down"
                size="md"
                fluid
                ref={register}
              >
                {StatesBrasil.map(state => (
                  <option value={state.value}>{state.label}</option>
                ))}
              </Select>
            </Col>
            <Col md={3}>
              <Input
                type="text"
                name="profile.crm"
                label="CRM"
                className="mb-3"
                size="md"
                fluid
                ref={register}
              />
            </Col>
            <Col md={7}>
              <Input
                type="text"
                name="profile.full_name"
                label="Nome do médico"
                className="mb-3"
                size="md"
                fluid
                ref={register}
              />
            </Col>
            <Col md={6}>
              <Input
                type="text"
                name="profile.email"
                label="Email do médico"
                className="mb-3"
                size="md"
                fluid
                ref={register}
              />
            </Col>
            <Col md={3}>
              <Select
                name="profile.gender"
                label="Gênero"
                className="mb-3"
                icon="icon-chevron-down"
                size="md"
                fluid
                ref={register}
              >
                <option value="m">Masculino</option>
                <option value="f">Femino</option>
                <option value="-">Prefiro não informar</option>
              </Select>
            </Col>
            <Col md={4}>
              <Input
                type="text"
                name="profile.mobile_number"
                label="Celular"
                className="mb-3"
                size="md"
                fluid
                ref={register}
                mask="(99) 99999-9999"
              />
            </Col>
            <Col md={4}>
              <Input
                type="text"
                name="profile.phone_number"
                label="Telefone Fixo"
                className="mb-3"
                size="md"
                fluid
                ref={register}
                mask="(99) 99999-9999"
              />
            </Col>
            <Col md={4}>
              <Select
                name="profile.specialty"
                label="Especialidade"
                className="mb-3"
                size="md"
                fluid
                ref={register}
              >
                {Specialties.map(specialty => (
                  <option value={specialty}>{specialty}</option>
                ))}
              </Select>
            </Col>
            <Col md={12}>
              <Button className="primary float-right">Enviar</Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  )
}
