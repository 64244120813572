import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import { Currency } from '../../../components/Currency'
import Skeleton from 'react-loading-skeleton'
import ProfileCard from '~/components/ProfileCard'
import api from '~/services/api'
import Sprites from '~/assets/icons/sprites.svg'

export default function Physician() {
  const { id } = useParams()
  const [physician, setPhysician] = useState({})
  const [loading, setLoading] = useState(false)

  const getData = async physicianId => {
    setLoading(true)
    const res = await api.get(`/physicians/${physicianId}`)
    setPhysician(res.data)
    setLoading(false)
  }

  const formatToArray = data => {
    return data.split(',')
  }

  useEffect(() => {
    getData(id)
  }, [id])

  const favoritePhysician = async () => {
    if (physician.user?.favoritesPatients.length) {
      await api.delete(`/favorites/${physician.user?.id}`)
    } else {
      await api.post(`/favorites/${physician.user?.id}`)
    }
    await getData(id)
  }

  let address = physician.user?.addresses[0]
  const favorite = physician.user?.favoritesPatients.length ? true : false

  return (
    <>
      {
        loading ? (
            <div className='container-fluid patient__physician__description__skeleton'>
              <div className='row mt-5 patient__physician__description__skeleton__card'>
                <div className='col-lg-1 pr-0'>
                  <Skeleton className='rounded-circle' width='90px' height='90px' />
                </div>
                <div className='col-lg-11 pl-5'>
                  <Skeleton className='' width='60%' />
                  <Skeleton className='mt-2 mx-4' width='30%' />
                  <div className='row'>
                    <div className='col-lg-6'>
                      <Skeleton className='mt-2' width='40%' />
                      <Skeleton className='mt-2' width='80%' />
                    </div>

                  </div>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-lg-12'>
                  <Skeleton width='10%' />
                  <Skeleton className='mt-2' width='100%' height='80px' />
                  <Skeleton className='mt-2' width='20%' />
                </div>
              </div>

              <div className='row mt-4'>
                <div className='col-lg-12'>
                  <Skeleton className='d-block' width='15%' />
                  <Skeleton className='mt-2 patient__physician__description__skeleton__card__full' width='30%' />
                </div>

              </div>

              <div className='row mt-4'>
                <div className='col-lg-12'>
                  <Skeleton className='d-block' width='15%' />
                  <Skeleton className='mt-2 patient__physician__description__skeleton__card__full' width='30%' />
                </div>

              </div>

              <div className='row mt-4'>
                <div className='col-lg-12'>
                  <Skeleton className='d-block' width='15%' />
                  <Skeleton className='mt-2 d-block patient__physician__description__skeleton__card__full' width='30%' />
                  <Skeleton className='mt-2 d-block patient__physician__description__skeleton__card__full' width='20%' />
                  <Skeleton className='mt-2 d-block patient__physician__description__skeleton__card__full' width='25%' />
                </div>

              </div>
            </div>

          ) :
          (
            <div className='container-fluid  patient__physician__description'>
              <div className='row'>
                <div className='my-4 col-lg-8 pl-0'>
                  <ProfileCard data={physician.user} physician={physician} />
                </div>
                <div className='col-lg-4 d-flex align-items-center flex-column justify-content-center'>

                  <button
                    className={`btn patient__physician__description__favorite__button `}
                    onClick={favoritePhysician}
                  >
                    <svg
                      className='patient__physician__description__favorite__icon ml-1'
                      style={{
                        fill: favorite ? '#ff0000' : '#fff',
                        stroke: favorite ? 'none' : '#000',
                      }}
                    >
                      <use xlinkHref={`${Sprites}#icon-heart`} />
                    </svg>
                    Favoritar Médico
                  </button>
                  {physician.online_consultation && (
                    <a href={physician.url_online_consultation} target='_blank' rel='noopener'>
                      <button className='btn mt-2 form-control patient__physician__description__telemed__button'>Marcar
                        consulta online
                      </button>
                    </a>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='mb-4'>
                  <h4 className='patient__physician__description__title about'>Sobre</h4>
                  <p className='patient__physician__description__text'>{physician.about}</p>
                  {physician.price && (
                    <p className='py-2 patient__physician__description__text'>Preço da consulta: <span
                      className='default-green'><Currency value={physician.price} currency='R$' /> </span></p>
                  )}
                </div>
              </div>
              <div className='row'>
                <div>
                  <div className='mb-4'>
                    <h4 className='patient__physician__description__title'>Especialidade</h4>
                    <span className='patient__physician__description__badge__buttons'>{physician.specialty}</span>
                  </div>

                  {physician.diseases?.length > 0 && (
                    <div>
                      <div className='mb-4'>
                        <h4 className='patient__physician__description__title'>Enfermidades</h4>
                        {Array.isArray(physician.diseases)
                          ? physician.diseases?.map(disease => (
                            <span className='patient__physician__description__badge__buttons '>
                              {disease}
                            </span>
                          ))
                          : formatToArray(physician.diseases).map(disease => (
                            <span className='patient__physician__description__badge__buttons '>
                              {disease}
                            </span>
                          ))}
                      </div>
                    </div>
                  )}
                  <div>
                    <div className='mb-4'>
                      <h4 className='patient__physician__description__title'>Contato</h4>
                      {physician.user?.profile?.phone_number && (
                        <p className='patient__physician__description__text'>
                          <NumberFormat
                            value={physician.user?.profile?.phone_number}
                            displayType={'text'}
                            format='(##) #####-####'
                          />
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='mb-4'>
                    <h4 className='patient__physician__description__title'>Endereço</h4>
                    {address ? (
                      <>
                        {address.street && (
                          <p className='patient__physician__description__text'>
                            {address?.street}, {address?.number} -{' '}
                            {address?.complement}
                          </p>
                        )}
                        <p className='patient__physician__description__text'>{address?.cep}</p>
                        <p className='patient__physician__description__text'>
                          {address?.city} - {address?.state}
                        </p>
                      </>
                    ) : (
                      <p className='patient__physician__description__text'>Endereço não cadastrado</p>
                    )}
                  </div>
                </div>
              </div>
            </div>)
      }
    </>
  )
}
