import React, { useState } from 'react'
import { Card } from './styles'
import ProductImage from '~/assets/images/product-sample.jpg'
import { Link, useHistory } from 'react-router-dom'
import { formatValue } from '~/shared/utils'
import Sprites from '~/assets/icons/sprites.svg'

export default function ProductCard({
  product = {},
  ...rest
}) {
  const history = useHistory()
  const [routeState] = useState('/produtos/')
  return (
    <div
      className="d-flex flex-column mb-4 col-12  col-lg-4 p-1"
    >
      <Link
        to={`${routeState}${product.slug}`}
        className="anchor text--sm text-decoration-none d-flex align-items-center justify-content-end"
      >
        <Card
          className="d-flex d-lg-block justify-content-center mx-2"
          key={product.id}
        >

          {product.photo?.uuidUrl ? (
            <img
              src={product.photo?.uuidUrl}
              style={{ cursor: 'pointer' }}
              alt=""
            />
          ) : (
            <img
              src={ProductImage}
              style={{ cursor: 'pointer' }}
              alt=""
              onClick={() =>
                history.push(`${routeState}${product.slug}`)
              }
            />
          )}

          <div className="d-flex flex-column ">
            <h4
              className="name px-3"
              onClick={() =>
                history.push(`${routeState}${product.slug}`)
              }
              style={{ cursor: 'pointer' }}
            >
              {product.name}
            </h4>

            <div className="d-flex justify-content-between">
              <div className="mt-3 px-3">
                <Link className='brand mb-3' to={`/marca/${product.brand?.slug}`}>
                {product.brand?.name}
                </Link>
                <div className="price-tags mt-2">

                  {product.price_previous &&
                  (
                    (product.price * 100) /
                    product.price_previous -
                    100
                  ).toFixed(2) < 0 && (
                    <p className="value previous-value">
                      De: {' '}
                      <strike>
                        {formatValue(
                          product.price_previous,
                          product.currency,
                        )}
                      </strike>{' '}

                    </p>
                  )}
                  <div className='prices-free'>
                  <p className="current-value">
                    {product.price_previous ? 'Por: ' : 'Preço: '}
                    {formatValue(product.price, product.currency)}
                  </p>
                  {product.price_previous &&
                          <span className="value desc">
                            {

                              (
                                (product.price * 100) /
                                product.price_previous -
                                100
                              ).toFixed(2)


                            }
                            %
                          </span>
                  }
                  </div>

                </div>
                <p className="description">
                  {product.presentation}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center see-more justify-content-end pr-2">
              <p>Ver mais</p>
              <span className="ml-1">
                <svg className="icon">
                  <use xlinkHref={`${Sprites}#icon-angle-right`}/>
                </svg>
              </span>
            </div>
          </div>
        </Card>
      </Link>
    </div>
  )
}
