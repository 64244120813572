import React, {useState} from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import Skeleton from 'react-loading-skeleton'
import ProfileCard from '~/components/ProfileCard'
import Button from '~/components/Button'
import {ButtonSecondary} from '~/components/Buttons/SecondaryButton'
import { NewPassword } from '~/pages/NewPassword'

export default function Profile() {
  const { session } = useSelector(state => state.auth)
  moment.locale('pt')

  return (
    <div>
      {
        !session.roles[0] === '' || !session.roles[0] ? (
          <div className='contanier-fluid p-5 patient__profile__show__skeleton'>
            <div className='row mt-5'>
              <div className="col-lg-2 col-12  patient__profile__show__skeleton__center">
                <Skeleton className='rounded-circle ' width='90px' height='90px'/>
              </div>
              <div className='col-lg-6 col-12 patient__profile__show__skeleton__center'>
                <Skeleton className='mt-4' width='90%'/>
                <Skeleton className='mt-2 d-inline-block' width='30%'/>
              </div>
              <div className='col-lg-4 col-12 patient__profile__show__skeleton__center'>
                <Skeleton className='mt-4' width='50%'/>
                <Skeleton className='mt-2 d-block patient__profile__show__skeleton__center__btn' width='50%'/>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-12">
                <Skeleton width='60%'/>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-6 patient__profile__show__skeleton__mb">
                <Skeleton width='70%'/>
                <Skeleton className='mt-2' width='50%'/>
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%'/>
                <Skeleton className='mt-2' width='50%'/>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-6 patient__profile__show__skeleton__mb">
                <Skeleton width='70%'/>
                <Skeleton className='mt-2' width='50%'/>
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%'/>
                <Skeleton className='mt-2' width='50%'/>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-6 patient__profile__show__skeleton__mb">
                <Skeleton width='70%'/>
                <Skeleton className='mt-2' width='50%'/>
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%'/>
                <Skeleton className='mt-2' width='50%'/>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-lg-6 patient__profile__show__skeleton__mb">
                <Skeleton width='70%'/>
                <Skeleton className='mt-2' width='50%'/>
              </div>
              <div className='col-lg-6'>
                <Skeleton width='70%'/>
                <Skeleton className='mt-2' width='50%'/>
              </div>
            </div>



          </div>
        ) : (
          <div className='container-fluid patient__profile__show'>
            <div className='row mt-4'>
              <div className='col-lg-8'>
                <ProfileCard data={session} />
              </div>
              <div className='col-lg-4 patient__profile__show__buttons'>

                <Link className='w-100 text-center' to="/paciente/perfil/editar">
                  <ButtonSecondary
                    text='Editar'
                    btnClass='patient__profile__show__btn'
                  />
                </Link>
                <ButtonSecondary
                  toggle="modal"
                  target="#newPasswordModal"
                  text='Alterar senha'
                  btnClass='patient__profile__show__btn'

                >
                </ButtonSecondary>
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-12 my-5">
                <h1 className="patient__profile__show__title">Informações pessoais</h1>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='mb-4'>
                  <h4>Nome completo</h4>
                  <p className="data">{session.profile?.full_name}</p>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='mb-4'>
                  <h4>Data de Nascimento</h4>
                  <p className="data">
                    {moment(session.profile?.birthdate).format('DD/MM/YYYY')}
                  </p>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='mb-4'>
                  <h4>Gênero</h4>
                  <p className="data">
                    {session.profile?.gender === 'm' ? 'Masculino' : session.profile?.gender === 'f' ? 'Feminino': session.profile?.gender === '-' ? 'Não informado' : ''}
                  </p>
                </div>
              </div>
              {session.wards?.length > 0 && (
                <div className='col-lg-12'>
                  <div className='row'>
                    {session.wards[0].profile?.full_name && (
                      <div className='col-lg-6'>
                        <div className='mb-4'>
                          <h4>Nome do paciente</h4>
                          <p className="data">{session.wards[0].profile.full_name}</p>
                        </div>
                      </div>
                    )}
                    {session.wards[0].profile?.birthdate && (
                      <div className='col-lg-6'>
                        <div className='mb-4'>
                          <h4>Data de Nascimento</h4>
                          <p className="data">
                            {moment(session.wards[0].profile?.birthdate).format(
                              'DD/MM/YYYY',
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                    {session.wards[0].profile?.rg && (
                      <div className='col-lg-6'>
                        <div className='mb-4'>
                          <h4>RG do paciente</h4>
                          <p className="data">{session.wards[0].profile?.rg}</p>
                        </div>
                      </div>
                    )}
                    {session.wards[0].profile?.cpf && (
                      <div className='col-lg-6'>
                        <div className='mb-4'>
                          <h4>CPF do paciente</h4>
                          <p className="data">
                            <NumberFormat
                              value={session.wards[0].profile?.cpf}
                              displayType={'text'}
                              format="###.###.###-##"
                            />
                          </p>
                        </div>
                      </div>
                    )}
                    {session.wards[0].profile?.mobile_number && (
                      <div className='col-lg-6'>
                        <div className='mb-4'>
                          <h4>Contato do paciente</h4>
                          <p className="data">
                            <NumberFormat
                              value={session.wards[0].profile?.mobile_number}
                              displayType={'text'}
                              format="(##) #####-####"
                            />
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {session.addresses?.map(address => (
                <>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>Estado</h4>
                      <p className="data">{address.state}</p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>Cidade</h4>
                      <p className="data">{address.city}</p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>CEP</h4>
                      <p className="data">{address.cep}</p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>Endereço</h4>
                      <p className="data">{address.street}</p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>Número</h4>
                      <p className="data">{address.number}</p>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-4'>
                      <h4>Complemento </h4>
                      <p className="data">{address.complement}</p>
                    </div>
                  </div>
                </>
              ))}
              <div className='col-lg-6'>
                <div className='mb-4'>
                  <h4>Tel residencial</h4>
                  <p className="data">{session.profile?.phone_number}</p>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='mb-4'>
                  <h4>Tel celular</h4>
                  <p className="data">{session.profile?.mobile_number}</p>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='mb-4'>
                  <h4>RG</h4>
                  <p className="data">{session.profile?.rg}</p>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='mb-4'>
                  <h4>CPF</h4>
                  <p className="data">{session.profile?.cpf}</p>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='mb-4'>
                  <h4>Email </h4>
                  <p className="data">{session.email}</p>
                </div>
              </div>
            </div>
            <NewPassword />
          </div>
        )
      }

    </div>
  )
}
