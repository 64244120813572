import React, { useState } from 'react'
import Cards from 'react-credit-cards'
import InputMask from 'react-input-mask'

const CreditCard = ({ title = 'Enviar Pagamento', loading, action , target, callBack}) => {
  const [state, setState] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  })

  const inputFocus = e => {
    const { name } = e.target

    setState(prevState => {
      return {
        ...prevState,
        focus: name,
      }
    })
  }

  const changeInput = e => {
    const { name, value } = e.target

    setState(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const clearInput = () => {
    setState({
      cvc: '',
      expiry: '',
      focus: '',
      name: '',
      number: '',
  })

  }

  return (
    <div id="PaymentForm" className={`collapse credit__card my-3 `}>
      <Cards
        cvc={state.cvc}
        expiry={state.expiry}
        focused={state.focus}
        name={state.name}
        number={state.number}

      />
      <form className="mx-2">
        <div className="form-group">
          <InputMask
            className="form-control"
            mask="9999 9999 9999 9999"
            name="number"
            placeholder="Número do cartão"
            onChange={event => changeInput(event)}
            onFocus={event => inputFocus(event)}
            value={state.number}

          />
        </div>
        <div className="form-group">
          <InputMask
            className="form-control"
            mask=""
            name="name"
            placeholder="Nome (como impresso no cartão)"
            onChange={event => changeInput(event)}
            onFocus={event => inputFocus(event)}
            value={state.name}
          />
        </div>
        <div className="form-group d-flex">
          <InputMask
            className="form-control mr-2"
            mask="99/99"
            name="expiry"
            placeholder="Validade"
            onChange={event => changeInput(event)}
            onFocus={event => inputFocus(event)}
            value={state.expiry}
          />
          <InputMask
            className="form-control"
            mask="999"
            name="cvc"
            placeholder="CVC"
            onChange={event => changeInput(event)}
            onFocus={event => inputFocus(event)}
            value={state.cvc}
          />
        </div>
        <div className='payment__buttons'>
        <button
          type="button"
          className="btn payment__button"
          disabled={loading}
          onClick={() =>
            action(state.number, state.name, state.cvc, state.expiry)
          }
        >
          {title}
          {loading && (
            <div
              className="ml-3 spinner-border spinner-border-sm"
              role="status"
            >
              {' '}
            </div>
          )}
        </button>
        <button
          type="button"
          className="btn payment__button__clear"
          disabled={loading}
          onClick={() =>{ clearInput(); callBack()}}
          data-target={target}
          data-toggle='collapse'
        >
          Cancelar
        </button>
        </div>
      </form>
    </div>
  )
}

export { CreditCard }
