import React, { useState, useEffect } from 'react'
import { Row, Col, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Pagination from 'react-js-pagination'

import { Container, Order } from './styles'
import api from '~/services/api'
import MedicineImage from '~/assets/images/produto.png'
import { Select } from '~/components/Form'
import { useForm } from 'react-hook-form'
import { Status } from '~/components/Status'

export default function Orders() {
  const [orders, setOrders] = useState({ data: [] })
  const [orderStates, setOrderStates] = useState([])
  const [loading, setLoading] = useState(false)
  const { register, watch } = useForm()
  const status = watch('status')
  const getOrders = async page => {
    setLoading(true)
    const res = await api.get(
      `/orders?page=${page ? page : 1}&status=${status}`,
    )
    setOrders(res.data)
    setLoading(false)
  }

  const getOrderStates = async () => {
    setLoading(true)
    const res = await api.get(`/orders/states`)
    setOrderStates(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getOrderStates()
  }, [])

  useEffect(() => {
    getOrders()
  }, [status])

  const handlePageChange = page => {
    getOrders(page)
  }

  return (
    <div className="container">
      <div className="row">
        <h2>Pedidos</h2>
        <Select
          ref={register}
          name="status"
          size="md"
          icon="icon-chevron-down"
          className="ml-3"
        >
          <option value="">Todos os status</option>
          {orderStates.map(state => (
            <option value={state.key}>{state.name}</option>
          ))}
        </Select>
      </div>
      <Row>
        {!loading &&
          orders?.data?.map(order => (
            <div className="col-6 p-1 ">
              <div
                className="border-radius-8 card p-0 shadow-sm "
                key={order.id}
              >
                <a href={`/admin/pedidos/${order.id}`}>
                  <div className="row no-gutters">
                    <div className="col-auto">
                      {order.medicines[0].photo ? (
                        <img
                          className="admin-order-card-img border-radius-left-8"
                          src={order.medicines[0].photo?.uuidUrl}
                          alt={order.medicines[0].name}
                        />
                      ) : (
                        <img src={MedicineImage} alt="" />
                      )}
                    </div>
                    <div className="col">
                      <div className="card-block px-2">
                        <div className="row mb-2 mt-4">
                          <div className="card-text col">
                            <strong className="mr-2">Pedido</strong>
                            {order.id}
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="card-text col">
                            <strong className="mr-2">Data</strong>
                            {moment(order.created_at).format('DD/MM/YYYY')}
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="card-text col">
                            <strong className="mr-2">Prescritor</strong>
                            {order.prescription.physician
                              ? order.prescription.physician.profile.full_name
                              : order.prescription.prePhysician?.full_name
                                  .length > 0
                              ? order.prescription.prePhysician?.full_name
                              : '---------'}
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="card-text col">
                            <strong className="mr-2">Paciente</strong>
                            {order.prescription.patient.profile.full_name}
                          </div>
                        </div>
                        <div className="row my-2">
                          <div className="card-text col">
                            <strong className="mr-2">Status</strong>
                            <Status id={order.status} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          ))}
      </Row>
      <Row>
        <Col className="d-flex justify-content-center mt-3">
          <Pagination
            activePage={orders.page}
            itemsCountPerPage={orders.perPage}
            totalItemsCount={orders.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </Col>
      </Row>
    </div>
  )
}
