import { updateObject } from '~/shared/updateObject'

const initialState = {
  listPrescriptions: [],
  finishRequest: false,
  form: {
    search: '',
    status: '',
    haveAnvisa: null,
    havePrescription: null,
    haveOrder: null,
  }
}

const setListPrescriptions = (state, action) => {
  return updateObject(state, {
    listPrescriptions: action.payload,
    finishRequest: true,
    form: action.form
  })
}

const setListPrescriptionsRequest = (state) => {
  return updateObject(state, {
    finishRequest: false,
  })
}

const prescriptionsReducer = (state=initialState, action) => {
  switch (action.type) {
    case "SET_LIST_PRESCRIPTIONS":
      return setListPrescriptions(state, action);
    case "SET_LIST_PRESCRIPTIONS_REQUEST":
      return setListPrescriptionsRequest(state)
    default:
      return state;
  }
}

export { prescriptionsReducer }
