import React, { useState, useEffect } from 'react'
import { Row, Col, Accordion, Alert, Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import { useSelector, useDispatch } from 'react-redux'

import { Creators as AuthActions } from '~/store/ducks/auth'
import { Creators as ErrorActions } from '~/store/ducks/error'

import { Container, MedicineList, OrderDetails } from './styles'
import Button from '~/components/Button'
import Thumb from '~/components/Thumb'
import ModalSelectMedicines from '~/components/ModalSelectMedicines'
import ModalAddAddress from '~/components/ModalAddAddress'
import api from '~/services/api'
import { Input } from '~/components/Form'
import { Currency } from '~/components/Currency'

export default function Quotation({ next, data, userMail, refresh }) {
  const { session } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [medicines, setMedicines] = useState(data.medicines)
  const [loading, setLoading] = useState(false)
  const [modalShowMedicines, setModalShowMedicines] = useState(false)
  const [modalShowAddress, setModalShowAddress] = useState(false)
  const [addressId, setAddressId] = useState()
  const [addressIdEdit, setAddressIdEdit] = useState()
  const [addressFilePreview, setAddressFilePreview] = useState()
  const [documentFilePreview, setDocumentFilePreview] = useState()
  const [
    responsibleDocumentFilePreview,
    setResponsibleDocumentFilePreview,
  ] = useState()
  const [brandsOrdered, setBrandsOrdered] = useState([])
  const [brands, setBrands] = useState([])

  useEffect(() => {
    if (session.addresses[0]) {
      setAddressId(session.addresses[0].id)
    }
    const document = session.documents?.find(
      doc => doc.type === 'identification',
    )
    if (document) {
      setDocumentFilePreview(document.file.name)
    }
    const responsibleDocument = session.documents?.find(
      doc => doc.type === 'identification-responsible',
    )
    if (responsibleDocument) {
      setResponsibleDocumentFilePreview(responsibleDocument.file.name)
    }
  }, [session])

  useEffect(() => {
    const address = session.addresses?.find(ad => ad.id === addressId)
    if (address?.file) {
      setAddressFilePreview(address.file.name)
    }
  }, [addressId, session.addresses])

  useEffect(() => {
    setBrands(
      data.medicines
        .map(medicine => medicine.brand)
        .filter(
          (brand, index, self) =>
            index === self.findIndex(b => b.id === brand.id),
        ),
    )

    setBrandsOrdered(
      data.orders.map(order => {
        const ids = order.medicines.map(medicine => medicine.brand_id)
        return ids[0]
      }),
    )

    setMedicines(data.medicines)
  }, [data])

  const sendFile = async (file, type) => {
    try {
      var formData = new FormData()
      formData.append('assetType', type)
      formData.append('file', file)
      const res = await api.post('/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return res
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
    }
  }

  const editAddress = id => {
    setAddressIdEdit(id)
    setModalShowAddress(true)
  }

  const setAddressFile = async (id, user_id) => {
    setLoading(true)
    const data = { file_id: id, user_id: user_id }
    await api.put(`/addresses/${addressId}`, data)
    toast.success('Arquivo enviado com sucesso')
    setLoading(false)
  }

  const setDocumentFile = async (id, user_id) => {
    setLoading(true)
    const data = {
      file_id: id,
      user_id: user_id,
      type: 'identification',
      name: 'rg',
    }

    await api.put(`user/${session.id}/image`, data)
    toast.success('Arquivo enviado com sucesso')
    setLoading(false)
  }

  const setResponsibleDocumentFile = async id => {
    setLoading(true)
    const data = {
      documents: [
        { file_id: id, type: 'identification-responsible', name: 'rg' },
      ],
    }
    await api.put(`/users/${session.id}`, data)
    toast.success('Arquivo enviado com sucesso')
    setLoading(false)
  }

  const {
    getRootProps: addressGetRootProps,
    getInputProps: addressGetInputProps,
  } = useDropzone({
    accept: 'image/*, application/pdf',
    noKeyboard: true,
    multiple: false,
    onDrop: async acceptedFiles => {
      const res = await sendFile(acceptedFiles[0], 'address')
      if (res?.data) {
        setAddressFile(res.data.id, data.user_id)
        setAddressFilePreview(res.data.name)
      }
    },
  })

  const {
    getRootProps: documentGetRootProps,
    getInputProps: documentGetInputProps,
  } = useDropzone({
    accept: 'image/*, application/pdf',
    noKeyboard: true,
    multiple: false,
    onDrop: async acceptedFiles => {
      const res = await sendFile(acceptedFiles[0], 'document')
      if (res?.data) {
        setDocumentFile(res.data.id)
        setDocumentFilePreview(res.data.name)
      }
    },
  })

  const {
    getRootProps: responsibleDocumentGetRootProps,
    getInputProps: responsibleDocumentGetInputProps,
  } = useDropzone({
    accept: 'image/*, application/pdf',
    noKeyboard: true,
    multiple: false,
    onDrop: async acceptedFiles => {
      const res = await sendFile(acceptedFiles[0], 'document')
      if (res?.data) {
        setResponsibleDocumentFile(res.data.id)
        setResponsibleDocumentFilePreview(res.data.name)
      }
    },
  })

  const addQuantity = index => {
    const newMedicines = [...medicines]
    const max = newMedicines[index].pivot.quantity
      ? newMedicines[index].pivot.quantity
      : 99
    if (
      typeof newMedicines[index].quantity === 'undefined' ||
      newMedicines[index].quantity < max
    ) {
      newMedicines[index] = {
        ...newMedicines[index],
        quantity:
          typeof newMedicines[index].quantity != 'undefined'
            ? newMedicines[index].quantity + 1
            : 2,
      }
      setMedicines(newMedicines)
    }
  }

  const subQuantity = index => {
    const newMedicines = [...medicines]
    if (
      typeof newMedicines[index].quantity === 'undefined' ||
      newMedicines[index].quantity > 1
    ) {
      newMedicines[index] = {
        ...newMedicines[index],
        quantity:
          typeof newMedicines[index].quantity != 'undefined'
            ? newMedicines[index].quantity - 1
            : 1,
      }
      setMedicines(newMedicines)
    }
  }

  const closeSelectMedicines = async () => {
    await refresh(data.id)
    setModalShowMedicines(false)
  }

  const closeAddAddress = async () => {
    await refresh(data.id)
    dispatch(AuthActions.getSessionRequest())
    setModalShowAddress(false)
  }

  const submitOrder = async brandId => {
    const medicinesOrder = medicines
      .filter(medicine => medicine.brand_id === brandId)
      .map(medicine => ({
        id: medicine.id,
        quantity: medicine.quantity ?? 1,
        currency: medicine.currency,
        price: medicine.price,
      }))

    const dataOrder = {
      prescription_id: data.id,
      address_id: addressId,
      medicines: medicinesOrder,
    }

    try {
      await api.post('/orders', dataOrder)
      await refresh(data.id)
      toast.success('Pedido enviado para análise.')
    } catch (e) {
      dispatch(ErrorActions.setError(e.response))
    }
  }

  const goAnalyzing = async () => {
    if (brandsOrdered.length > 0 && addressFilePreview && documentFilePreview) {
      const prescription = {
        user_email: userMail,
        status: 'analyzing',
      }
      await api.put(`/prescriptions/${data.id}`, prescription)
      next('analyzing')
      window.scrollTo(0, 0)
    } else {
      toast.error(
        'Faça um pedido dos produtos acima e faça upload dos arquivos restantes',
      )
    }
  }

  let total = []

  return (
    <>
      <Container>
        <Alert variant="success">
          Estamos analisando os seus documentos. Enquanto isso, você já pode
          consultar preços, decidir a sua compra e fazer upload dos documentos
          restantes abaixo.
        </Alert>
        {brandsOrdered.length === 0 && (
          <div className="mb-3">
            <Button
              className="primary"
              onClick={() => setModalShowMedicines(true)}
            >
              Selecionar produtos
            </Button>
          </div>
        )}
        <Accordion className='p-1'>
          {medicines.length > 0 && (
            <>
              <h4>Produtos</h4>
              <p className="mb-3 mt-1">
                Selecione aqui os produtos que constam em sua prescrição.
              </p>
            </>
          )}
          {brands.length > 0 &&
            brands.map((brand, brandIdx) => {
              total[brandIdx] = 0
              return (
                <>
                  <MedicineList className="my-3">
                    <h5 className='brand-text'>{brand.name}</h5>

                    {medicines.map((medicine, medIdx) => {
                      if (medicine.brand.id === brand.id) {
                        total[brandIdx] =
                          total[brandIdx] +
                          medicine.price *
                            (medicine.quantity ? medicine.quantity : 1)
                        if (brand.shipping)
                          total[brandIdx] += parseInt(brand.shipping)

                        if (typeof total[brandIdx] === 'integer')
                          total[brandIdx] = total[brandIdx].toFixed(2)
                      }
                      return (
                        medicine.brand.id === brand.id && (
                          <>
                            <div key={medicine.id}>
                              <div className="medicine mt-4">
                                <div className="thumb">
                                  <Thumb>
                                    <div className="inner">
                                      <img
                                        src={medicine.photo?.uuidUrl}
                                        alt="Produto"
                                      />
                                    </div>
                                  </Thumb>
                                </div>
                                <div className="infos">
                                  <div className="d-flex info-content justify-content-between align-items-center w-100">
                                    <div className="name info">
                                      <p>{medicine.name}</p>
                                    </div>
                                    <div className="d-flex flex-column align-items-center info">
                                      <p>Quantidade</p>
                                      <div className="quantity">

                                        <Button
                                          className="sub"
                                          onClick={() => subQuantity(medIdx)}
                                        >
                                          -
                                        </Button>
                                        <span>{medicine.quantity ?? 1}</span>
                                        <Button
                                          className="add"
                                          onClick={() => addQuantity(medIdx)}
                                        >
                                          +
                                        </Button>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column flex-column align-items-center">
                                      <p className='Price-text'>Preço</p>
                                      <p className="info price">

                                        <Currency
                                          value={
                                            medicine.price *
                                            (medicine.quantity
                                              ? medicine.quantity
                                              : 1)
                                          }
                                          currency={medicine.currency}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <hr className="divisor" />
                            <div className="total">
                              <div className="label">
                                <b>Frete*:</b>
                              </div>
                              <div className="value">
                                <p>
                                  <Currency
                                    value={
                                      medicine.shipping
                                        ? medicine.shipping
                                        : brand.shipping
                                    }
                                    currency={
                                      medicine.shipping_currency
                                        ? medicine.shipping_currency
                                        : brand.shipping_currency
                                    }
                                  />
                                </p>
                              </div>
                            </div>
                          </>
                        )
                      )
                    })}
                    <div className="total">
                      <div className="label">
                        <b>Total: </b>
                      </div>
                      <div className="value">
                        <p>
                          <Currency
                            value={total[brandIdx]}
                            currency={medicines[0].currency}
                          />
                        </p>
                      </div>
                    </div>
                  </MedicineList>
                  <Row>
                    <Col className="d-flex align-items-end">
                      <small>
                        *O frete pode variar de acordo com a quantidade
                        comprada.
                      </small>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Button
                        className="primary"
                        onClick={() => submitOrder(brand.id)}
                        disabled={brandsOrdered.includes(brand.id)}
                      >
                        {brandsOrdered.includes(brand.id)
                          ? 'Pedido pendente'
                          : 'Fazer pedido'}
                      </Button>
                    </Col>
                  </Row>
                  <hr className="divisor" />
                </>
              )
            })}
        </Accordion>
        <OrderDetails>
          <Row>
            <Col xs={12}>
              <h4>Endereço</h4>
              <p className="mb-3 mt-1">
                Preencha o mesmo endereço usado por você para o pedido de
                autorização Anvisa.
              </p>
            </Col>
            {session.addresses.map(address => (
              <Col lg={6} key={address.id}>
                <div
                  body
                  className={`address my-3 d-flex justify-content-between p-3 ${
                    addressId === address.id ? 'active' : ''
                  }`}
                >
                  <div>
                    <p className="mb-1">
                      {address.street}, {address.number}
                    </p>
                    <p className="mb-1">{address.complement}</p>
                    <p className="mb-1">
                      {address.city} - {address.state}
                    </p>
                    <p className="mb-1">{address.cep}</p>
                  </div>
                  <div
                    onClick={() => editAddress(address.id)}
                    className="anchor p-3 text--sm text-decoration-none d-flex align-items-center justify-content-end"
                  >
                    Editar
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg={4}>
              <Card body className="mb-3" onClick={() => editAddress()}>
                <div className="d-flex justify-content-between add-address">
                  <p>Adicionar endereço</p>
                  <b>+</b>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={12} className="p-4">
              <h4>Documentos</h4>
              <p className="mb-3 mt-1">
                Insira o comprovante do endereço acima. Insira o documento de
                identidade do paciente
                {session.roles.includes('responsible') && ' e do responsável'}.
              </p>
            </Col>
            <Col xs={12} lg={6} className="d-flex my-4">
              <div {...addressGetRootProps()}>
                <input {...addressGetInputProps()} />
                <div>
                  <p className='mb-2'>Comprovante de Endereço</p>
                  <div className="d-flex align-items-center">
                    <Input
                      size="sm-md"
                      type="text"
                      name="file"
                      classInput="border-color-primary"
                      disabled
                      fluid
                      placeholder="Procurar arquivo"
                      value={
                        addressFilePreview ||
                        'Clique para anexar seu documento.'
                      }
                    />
                    <div className="ml-2">
                      <Button
                        className="primary"
                        disabled={loading ? true : false}
                      >
                        Buscar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={12} className="d-flex  my-4">
              <div {...documentGetRootProps()}>
                <input {...documentGetInputProps()} />
                <div>
                  <p className='mb-2'>Documento de Identidade do Paciente</p>
                  <div className="d-flex align-items-center">
                    <Input
                      size="sm-md"
                      type="text"
                      name="file"
                      classInput="border-color-primary"
                      disabled
                      fluid
                      placeholder="Procurar arquivo"
                      value={
                        documentFilePreview ||
                        'Clique para anexar seu documento.'
                      }
                    />
                    <div className="ml-2">
                      <Button
                        className="primary"
                        disabled={loading ? true : false}
                      >
                        Buscar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {session.roles.includes('responsible') && (
              <Col xs={6} className="d-flex justify-content-center my-4">
                <div {...responsibleDocumentGetRootProps()}>
                  <input {...responsibleDocumentGetInputProps()} />
                  <div>
                    <p>Documento de Identidade do Responsável</p>
                    <div className="d-flex align-items-center">
                      <Input
                        size="sm-md"
                        type="text"
                        name="file"
                        classInput="border-color-primary"
                        disabled
                        fluid
                        placeholder="Procurar arquivo"
                        value={
                          responsibleDocumentFilePreview ||
                          'Clique para anexar o documento do responsável.'
                        }
                      />
                      <div className="ml-2">
                        <Button
                          className="primary"
                          disabled={loading ? true : false}
                        >
                          Buscar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            {brandsOrdered.length > 0 &&
              addressFilePreview &&
              documentFilePreview && (
                <Col xs={12}>
                  <Alert variant="success">
                    Ótimo, agora já temos todos os documentos que precisamos
                    para terminar a análise da sua autorização. Aguarde o
                    contato do nosso time que deve acontecer em até 2 dias
                    úteis.
                  </Alert>
                </Col>
              )}
            <Col xs={12} className="d-flex justify-content-end">
              <div>
                <Button className="primary" onClick={goAnalyzing}>
                  Próximo
                </Button>
              </div>
            </Col>
          </Row>
        </OrderDetails>
      </Container>

      <ModalSelectMedicines
        show={modalShowMedicines}
        onHide={closeSelectMedicines}
        prescriptionId={data.id}
      />

      <ModalAddAddress
        show={modalShowAddress}
        onHide={closeAddAddress}
        addressId={addressIdEdit}
      />
    </>
  )
}
