import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import { Container } from './styles'
import UploadImage from '../../../../components/UploadImage'
import { Input, Select } from '~/components/Form'
import api from '~/services/api'
import Button from '~/components/Button'
import { ReactTinyLink } from 'react-tiny-link'
import { Creators as ErrorActions } from '~/store/ducks/error'
import { useDispatch } from 'react-redux'
import TextArea from '~/components/Form/TextArea'

export default function NewBrand(props, widthCrop = 460) {
  const { register, handleSubmit, errors, setValue } = useForm()
  const [loading, setLoading] = useState(false)
  const [imageId, setImageId] = useState()
  const [coverId, setCoverId] = useState()
  const [listLinks, setListLinks] = useState([])
  const [link, setLink] = useState([])
  const [listArticles, setListArticles] = useState([])
  const [articles, setArticles] = useState([])
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const onSubmit = async data => {
    setLoading(true)
    const brand = {
      links: listLinks,
      articles: listArticles,
      logo_id: imageId,
      cover_id: coverId,
      ...data.brand,
      active: data.brand.active === 'true' ? true : false,
    }
    if (id)
      await api
        .put(`/brands/${id}`, brand)
        .then(() => history.push('/admin/produtos'))
        .catch(r => dispatch(ErrorActions.setError(r.response.data.errors)))
    else
      await api
        .post('/brands', brand)
        .then(() => history.push('/admin/produtos'))
        .catch(r => dispatch(ErrorActions.setError(r.response.data.errors)))
    setLoading(false)
  }

  const getData = async brandId => {
    setLoading(true)
    const resBrand = await api.get(`/brands/${brandId}`)
    setListLinks(resBrand.data.links ? resBrand.data.links : [])
    setListArticles(resBrand.data.articles ? resBrand.data.articles : [])
    setValue('brand', resBrand.data)
    setLoading(false)
  }

  useEffect(() => {
    if (id) getData(id)
  }, [id])

  const addingLink = (listKey, setListKey, origin, linkValue) => {
    if (linkValue) {
      let list = Array.isArray(listKey) ? listKey : []
      list.push(linkValue)
      setListKey(list)
      origin('')
    }
  }

  const changeLink = (e, set) => {
    set(e.currentTarget.value)
  }

  const removeLink = (index, set, list = []) => {
    let newList = []
    list.map((result, removeIndex) => {
      if (removeIndex !== index) {
        newList.push(result)
      }
    })
    set(newList)
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <h3>Imagem da Marca</h3>
            <UploadImage
              callback={setImageId}
              height={200}
              aspect={0}
              isPrivate={false}
              assetType={'brandLogo'}
            />
          </Col>
          <Col>
            <h3>Cover da Marca</h3>
            <UploadImage
              callback={setCoverId}
              height={150}
              aspect={0}
              widthCrop={460}
              isPrivate={false}
              assetType={'brandBanner'}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={3}>
            <Select
              label="Status"
              name="brand.active"
              size="md"
              fluid
              className="my-3"
              error={errors.brand?.active}
              ref={register}
            >
              <option value={true}>Ativo</option>
              <option value={false}>Inativo</option>
            </Select>
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Nome da Marca"
              name="brand.name"
              size="md"
              fluid
              className="my-3"
              error={errors.brand?.name}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Descrição"
              name="brand.description"
              size="md"
              fluid
              className="my-3"
              error={errors.brand?.description}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="CNPJ"
              name="brand.cnpj"
              placeholder="000.000.00/0000-0"
              size="md"
              fluid
              className="my-3"
              error={errors.brand?.cnpj}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Moeda"
              name="brand.currency"
              size="md"
              fluid
              className="my-3"
              error={errors.brand?.currency}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Frete"
              name="brand.shipping"
              size="md"
              fluid
              className="my-3"
              error={errors.brand?.shipping}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Moeda (frete)"
              name="brand.shipping_currency"
              size="md"
              placeholder="R$"
              fluid
              className="my-3"
              error={errors.brand?.shipping_currency}
              ref={register}
            />
          </Col>
          <Col lg={3}>
            <Input
              type="text"
              label="Fabricante (Anvisa)"
              name="brand.manufacturer"
              size="md"
              fluid
              className="my-3"
              error={errors.brand?.manufacturer}
              ref={register}
            />
          </Col>

          <Col lg={12}>
            <Input
              type="text"
              label="Nome comercial Anvisa"
              name="brand.anvisa_commercial_name"
              size="md"
              fluid
              className="my-3"
              error={errors.brand?.anvisa_commercial_names}
              ref={register}
            />
          </Col>

          <Col lg={12}>
            <TextArea
              rows="5"
              label="Instruções de pagamento"
              name="brand.payment_instructions"
              fluid
              className="my-3"
              ref={register}
            />
          </Col>

          <div className="col-12 my-3">
            <div className="card">
              <div className="card-header d-flex justify-content-center">
                <h6>Videos</h6>
              </div>
              <div className="card-body">
                <div className="row">
                  {Array.isArray(listLinks) &&
                    listLinks.map(
                      (data, index) =>
                        data && (
                          <div className="col-4 mt-2">
                            <ReactTinyLink
                              cardSize="large"
                              showGraphic={true}
                              maxLine={3}
                              minLine={1}
                              url={data}
                            />
                            <button
                              type="button"
                              className="border-0 btn mt-2 btn-info w-100"
                              onClick={() =>
                                removeLink(index, setListLinks, listLinks)
                              }
                            >
                              <i className="fas fa-trash" />
                            </button>
                          </div>
                        ),
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="links">Link do video</label>
            <div className="form-group d-flex">
              <input
                type="text"
                className="form-control mr-3"
                id="links"
                placeholder="youtube.com"
                value={link}
                onChange={e => changeLink(e, setLink)}
              />
              <button
                type="button"
                className="btn new--brand--btn"
                onClick={() =>
                  addingLink(listLinks, setListLinks, setLink, link)
                }
                disabled={!link.length > 0}
              >
                Adicionar
              </button>
            </div>
          </div>

          <div className="col-12 my-3">
            <div className="card">
              <div className="card-header d-flex justify-content-center">
                <h6>Artigos</h6>
              </div>
              <div className="card-body">
                <div className="row">
                  {Array.isArray(listArticles) &&
                    listArticles.map(
                      (data, index) =>
                        data && (
                          <div className="col-4 mt-2">
                            <ReactTinyLink
                              cardSize="large"
                              showGraphic={true}
                              maxLine={3}
                              minLine={1}
                              url={data}
                            />
                            <button
                              type="button"
                              className="border-0 btn mt-2 btn-info w-100"
                              onClick={() =>
                                removeLink(index, setListArticles, listArticles)
                              }
                            >
                              <i className="fas fa-trash" />
                            </button>
                          </div>
                        ),
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="links">Link do artigo</label>
            <div className="form-group d-flex">
              <input
                type="text"
                className="form-control mr-3"
                id="articles"
                placeholder="articles.com"
                value={articles}
                onChange={e => changeLink(e, setArticles)}
              />
              <button
                type="button"
                className="btn new--brand--btn"
                onClick={() =>
                  addingLink(
                    listArticles,
                    setListArticles,
                    setArticles,
                    articles,
                  )
                }
                disabled={!articles.length > 0}
              >
                Adicionar
              </button>
            </div>
          </div>
        </Row>
        <Row>
          <Col>
            <Button className="primary" disabled={loading} type="submit">
              Enviar
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  )
}
