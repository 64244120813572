import React from 'react'

export default function Article({ article = {} }) {
  return (
    <div className='container-fluid article'>
      <div className='row  mt-3'>
      <div className='col-lg-12'>
        <a href={`https://www.ncbi.nlm.nih.gov/pubmed/${article.pubmed_id}`}>
          <h2 className='article__title' dangerouslySetInnerHTML={{ __html: article.ArticleTitle }}/>
        </a>
        <div className="d-flex article__info justify-content-between">
          <p className="article__autors">
            {article.Authors?.map(author => `${author}; `)}
          </p>
          {article.ArticleDate != 'undefined/undefined/undefined' ? <p className="article__autors">Data de publicação: {article.ArticleDate}</p>
            : <p className="article__autors">Data de publicação não informada</p> }
          {console.log(article)}
        </div>
      </div>
      </div>

      <div className='row'>
        <div className='col-lg-12'>
          <div className="article__abstract">
            <p dangerouslySetInnerHTML={{ __html: article.AbstractText }}/>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <a className='article__link' href={`https://www.ncbi.nlm.nih.gov/pubmed/${article.pubmed_id}`}>
            Ver artigo completo
          </a>
        </div>
      </div>

      <hr className='my-5'/>
    </div>
  )
}
