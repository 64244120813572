import * as actionTypes from "./actionTypes";

const setListPrescriptions = (payload, form) => {
  return {
    type: actionTypes.SET_LIST_PRESCRIPTIONS,
    payload,
    form
  }
}
const setListPrescriptionsRequest = () => {
  return {
    type: actionTypes.SET_LIST_PRESCRIPTIONS_REQUEST,
  }
}

export { setListPrescriptions, setListPrescriptionsRequest }
