import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Patient, Physician, Admin } from './links'

import { RenderImg } from '~/components/RenderImg/RenderImg'

export default function SideMenu() {
  const { session } = useSelector(state => state.auth)
  const [links, setLinks] = useState([])
  const location = useLocation()

  useEffect(() => {
    const role = localStorage.getItem('roles')
    switch (role) {
      case 'patient':
        setLinks(Patient)
        break
      case 'responsible':
        setLinks(Patient)
        break
      case 'physician':
        setLinks(Physician)
        break
      case 'administrator':
        setLinks(Admin)
        break
    }
  })

  if (location.pathname.split('/').includes('template')) return null

  return (
    <div className="d-flex flex-column side--menu">

      <h5 className="text text--rm text--black-dark align-self-center w-75 text-center mb-4">
      </h5>
      <ul className="d-flex flex-column list-unstyled">
        {links.map(link => (
          <Link
            className={`anchor item ${link.class}  text--black-dark text-decoration-none ${location.pathname ===
              link.url && 'active'}`}
            to={link.url}
            key={link.url}
          >
            <li
              className={`d-flex align-items-center ${location.pathname ===
                link.url && 'active'}`}
            >
              <div className='nav-ribbon'>
              <i className={`fa fa-2x mr-2 ${link.icon}`} />
              </div>
              {link.label}
            </li>
          </Link>
        ))}
      </ul>
    </div>
  )
}
