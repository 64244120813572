import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import NumberFormat from 'react-number-format'

import { Container } from './styles'
import ProfileCard from '~/components/ProfileCard'
import Button from '~/components/Button/index'
import { useEffect } from 'react'
import api from '~/services/api'
import Prescription from '../Prescriptions/Prescription'

export default function Patient() {
  const { id } = useParams()
  const [patient, setPatient] = useState({})
  const [prescriptions, setPrescriptions] = useState([])
  const [loading, setLoading] = useState(false)

  const getPrescriptions = async () => {
    const res = await api.get(`/prescriptions?patient=${id}`)
    setPrescriptions(res.data)
  }

  const getPatient = async id => {
    setLoading(true)
    const res = await api.get(`/patients/${id}`)
    setPatient(res.data)
    setLoading(false)
  }

  useEffect(() => {
    getPatient(id)
    getPrescriptions()
  }, [id])

  const address = patient.addresses?.length ? patient.addresses[0] : {}
  const profile = patient.profile
  const ward = patient.wards?.length ? patient.wards[0] : {}

  return (
    <Container>
      <Row>
        <Col>
          <Button>Voltar</Button>
        </Col>
      </Row>
      <Row>
        <Col lg={8} className="my-4">
          <ProfileCard data={patient} />
        </Col>
      </Row>
      <Row>
        {profile?.birthdate && (
          <Col lg={6} className="mb-4">
            <h5>Data de Nascimento</h5>
            <p>{moment(profile?.birthdate).format('DD/MM/YYYY')}</p>
          </Col>
        )}
        {profile?.rg && (
          <Col lg={6} className="mb-4">
            <h5>RG</h5>
            <p>{profile?.rg}</p>
          </Col>
        )}
        {profile?.cpf && (
          <Col lg={6} className="mb-4">
            <h5>CPF</h5>
            <p>
              <NumberFormat
                value={profile?.cpf}
                displayType={'text'}
                format="###.###.###-##"
              />
            </p>
          </Col>
        )}
        {profile?.mobile_number && (
          <Col lg={6} className="mb-4">
            <h5>Contato</h5>
            <p>
              <NumberFormat
                value={profile?.mobile_number}
                displayType={'text'}
                format="(##) #####-####"
              />
            </p>
          </Col>
        )}
      </Row>
      {ward.profile && (
        <Row>
          <Col className="my-3">
            <h4>Dados do paciente</h4>
          </Col>
          {ward.profile?.birthdate && (
            <Col lg={6} className="mb-4">
              <h5>Data de Nascimento</h5>
              <p>{moment(ward.profile?.birthdate).format('DD/MM/YYYY')}</p>
            </Col>
          )}
          {ward.profile?.rg && (
            <Col lg={6} className="mb-4">
              <h5>RG</h5>
              <p>{ward.profile?.rg}</p>
            </Col>
          )}
          {ward.profile?.cpf && (
            <Col lg={6} className="mb-4">
              <h5>CPF</h5>
              <p>
                <NumberFormat
                  value={ward.profile?.cpf}
                  displayType={'text'}
                  format="###.###.###-##"
                />
              </p>
            </Col>
          )}
          {ward.profile?.mobile_number && (
            <Col lg={6} className="mb-4">
              <h5>Contato</h5>
              <p>
                <NumberFormat
                  value={ward.profile?.mobile_number}
                  displayType={'text'}
                  format="(##) #####-####"
                />
              </p>
            </Col>
          )}
        </Row>
      )}
      {prescriptions.length > 0 && (
        <Row>
          <Col className="my-3">
            <h4 className="text--rm">Prescrições</h4>
          </Col>
        </Row>
      )}
      {prescriptions.map(prescription => (
        <Prescription
          data={prescription}
          className="mb-3"
          key={prescription.id}
        />
      ))}
    </Container>
  )
}
