import React, { useEffect, useState } from 'react'
import { Row, Col, Spinner, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { Container } from './styles'
import Patient from '../Patients/PatientCard'
import api from '~/services/api'
import Article from '~/components/Article'
import ParserPubMed from '~/helpers/ParserPubMed'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'

export default function Home() {
  const [loadingArticles, setLoadingArticles] = useState(true)
  const [loadingPatients, setLoadingPatients] = useState(true)
  const [patients, setPatients] = useState([])
  const [articlesId, setArticlesId] = useState()
  const [articles, setArticles] = useState([])
  const { session } = useSelector(state => state.auth)


  const getArticlesId = async () => {
    setLoadingArticles(true)
    const res = await api.get('/articles')
    setArticlesId(res.data)
    setLoadingArticles(false)
  }

  const getArticles = async () => {
    setLoadingArticles(true)

    if (articlesId) {
      const parsed = await ParserPubMed(articlesId)
      setArticles(parsed)
    }
    setLoadingArticles(false)
  }

  const getPatients = async () => {
    setLoadingPatients(true)
    const res = await api.get('/patients/list')
    setPatients(res.data)
    setLoadingPatients(false)
  }

  useEffect(() => {
    getArticlesId()
    getPatients()
  }, [])

  useEffect(() => {
    getArticles()
  }, [articlesId])

  return (
    <div className='container-fluid physician__home'>
      {patients.length > 0 && (
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className="physician__home__title">Últimos Pacientes</h2>
          </div>
        </div>
      )}
      <div className='row'>
        {loadingPatients && (
          <div className="col-lg-12 d-flex justify-content-center">
            <Spinner animation="grow" />
          </div>
        )}
        {patients.length > 0 &&
          patients.slice(0, 3).map(patient => (
            <div className="mt-2 mb-4 col-lg-12" key={patient.id}>
              <Link to={`/medico/pacientes/${patient.id}`}>
                <Patient data={patient} />
              </Link>
            </div>
          ))}
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 className="physician__home__title">Últimos Artigos</h2>
        </div>
      </div>
      <div className='row'>
        {loadingArticles && (
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-12'>
                <Skeleton width='80%'/>
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col-lg-3'>
                <Skeleton/>
              </div>
              <div className='col-lg-3 offset-lg-6'>
                <Skeleton/>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-lg-12'>
                <Skeleton count={5} className='mt-2'/>
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-lg-12'>
                <Skeleton width='80%'/>
              </div>
            </div>

            <div className='row mt-2'>
              <div className='col-lg-3'>
                <Skeleton/>
              </div>
              <div className='col-lg-3 offset-lg-6'>
                <Skeleton/>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-lg-12'>
                <Skeleton count={5} className='mt-2'/>
              </div>
            </div>

          </div>
        )}

          {articles.length > 0 &&
            articles
              .slice(0, 2)
              .map(article => <Article article={article} key={article.id} />)}

      </div>
    </div>
  )
}
