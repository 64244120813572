import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import { Creators as AuthActions } from '~/store/ducks/auth'
import Input from '~/components/Form/Input'
import Button from '~/components/Button'
import { Section } from './styles'
import ModalForgotPassword from './ModalForgotPassword/index'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Digite um email válido')
    .required('Digite um email'),
  password: Yup.string().required('Digite uma senha'),
})

export default function Login() {
  const { forced } = useParams()
  const { register, handleSubmit, errors } = useForm({
    validationSchema: LoginSchema,
  })
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const dispatch = useDispatch()

  const onSubmit = event => {
    event.preventDefault()
    dispatch(
      AuthActions.storeSessionRequest({
        email: event.target.email.value,
        password: event.target.password.value,
      }),
    )
  }

  useEffect(() => {
    if (forced === 'exp') {
      toast.error('Sua sessão expirou!')
    }
  }, [forced])
  const title = 'Faça login e aproveite tudo o que a Dr. Cannabis disponibiliza para você'
  const description = 'Ao fazer login na Dr. Cannabis você tem acesso a uma área exclusiva criada para facilitar ' +
    'o seu acesso legal à cannabis medicinal.'
  return (
    <Section>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="twitter:title" content={title} />
        <meta
          name="description"
          content={description}
        />
        <meta
          itemprop="description"
          content={description}
        />
        <meta
          name="og:description"
          content={description}
        />
        <meta
          name="twitter:description"
          content={description}
        />
      </Helmet>
      <Container>
        <Row>
          <Col>
            <h1 className="text-center mb-4">Login</h1>
          </Col>
        </Row>
        <hr className="mb-5" />
        <Row className="justify-content-md-center">
          <Col lg={5} className="mb-3">
            <form onSubmit={event => handleSubmit(onSubmit(event))}>
              <Row>
                <Col xs={12}>
                  <Input
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="nome@email.com"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.email}
                    ref={register}
                  />
                </Col>
                <Col xs={12}>
                  <Input
                    type="password"
                    name="password"
                    label="Senha"
                    placeholder="******"
                    size="md"
                    fluid
                    className="my-3"
                    error={errors.password}
                    ref={register}
                  />
                </Col>
                <Col xs={12}>
                  <div className=" my-3">
                    <a
                      href="#"
                      onClick={() => setShowForgotPassword(true)}
                      className="px-0 color black"
                    >
                      <b>
                        <u>Esqueci a senha</u>
                      </b>
                    </a>
                  </div>
                </Col>
                <Col xs={12}>
                  <Button className="primary md my-3" type="submit">
                    Entrar
                  </Button>
                </Col>
              </Row>
            </form>
            <Row>
              <Col xs={12}>
                <div className=" my-4">
                  <Link to="/cadastro" className="px-0 color black">
                    Ainda não tem uma conta? <b>Cadastre-se</b>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ModalForgotPassword
        show={showForgotPassword}
        handleClose={() => setShowForgotPassword(false)}
      />
    </Section>
  )
}
