import React from 'react'
import { Multiselect } from 'multiselect-react-dropdown'


const CustomMultSelect = ({label, inputName, listOptions, isObject = false, value, register, setValue, inputPlaceholder})=>{

  return (
    <div className='custom__mult__select'>
      <label className='w-100'>
        {label}
      <Multiselect
        options={listOptions}
        selectedValues={value}
        isObject={isObject}
        placeholder={inputPlaceholder}
        onSelect={(value)=> setValue(inputName, value)}
        onRemove={(value)=> setValue(inputName, value)}
        ref={register}
      />
      </label>
    </div>
  )
}

export {CustomMultSelect}
