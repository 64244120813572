import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import moment from 'moment'

import { Section } from './styles'
import LogoImg from '~/assets/images/logo.png'
import api from '~/services/api'

function Prescription({ history }) {
  const { session } = useSelector(state => state.auth)
  const [prescription, setPrescription] = useState({ medicines: [] })
  const [loading, setLoading] = useState(true)
  const { id } = useParams()

  const getData = async id => {
    setLoading(true)
    const res = await api.get(`/prescriptions/${id}`)
    if (res.data) setPrescription(res.data)
    else history.push('/')
    setLoading(false)
    window.print()
  }

  useEffect(() => {
    getData(id)
  }, [])

  return (
    <Section>
      {!loading && (
        <Container>
          <div className="d-flex justify-content-center">
            <div className="row">
              <div className="col-4" />
              <div className="col-4 pr-5">
                <img className="img-fluid" src={LogoImg} alt="" />
              </div>
              <div className="col-4" />
            </div>
          </div>

          <Row>
            <Col xs={12} className="my-3">
              <h1 className="ident text-center">PRESCRIÇÃO MÉDICA</h1>
            </Col>
          </Row>
          <Row>
            <Col sm={12} className=" mt-4">
              <p>
                <b>Nome Completo do Médico:</b>{' '}
                {prescription.physician?.profile.full_name}
              </p>
            </Col>
            <Col sm={12} md={6}>
              <p>
                <b>CPF do médico:</b> {prescription.physician?.profile.cpf}
              </p>
            </Col>
            <Col sm={12} className=" mt-2">
              <p>
                <b>CRM :</b> {prescription.physician?.physician?.crm}/
                {prescription.physician?.physician?.uf}
              </p>
            </Col>
            <Col sm={12} className="">
              {prescription.patient?.ward?.length > 0 && (
                <p>
                  <b>Nome Completo do Paciente:</b>{' '}
                  {prescription.patient?.ward[0]?.profile?.full_name}
                </p>
              )}
              <p>
                <b>
                  Nome Completo do
                  {prescription.patient?.ward.length > 0 ? (
                    <span> Responsável: </span>
                  ) : (
                    <span> Paciente: </span>
                  )}
                </b>
                {prescription.patient?.profile?.full_name}
              </p>
            </Col>
            {(prescription.patient?.ward[0]?.profile?.cpf ||
              prescription.patient?.profile?.cpf) && (
              <Col sm={12} md={6} className=" col-md-6">
                <p>
                  <b>CPF do paciente:</b>{' '}
                  {prescription.patient?.ward?.length > 0 ? (
                    <span>{prescription.patient?.ward[0]?.profile?.cpf}</span>
                  ) : (
                    <span>{prescription.patient?.profile?.cpf}</span>
                  )}
                </p>
              </Col>
            )}

            <Col sm={12} className=" mt-3" v-if="prescriptions.length">
              {prescription.medicines.map(medicine => (
                <div>
                  <p className="font-weight-bold mb-0">
                    <span>{medicine.name}</span>{' '}
                    <span className="float-right mr-5">
                      {medicine.pivot?.duration} meses
                    </span>
                    <br />
                    {medicine.pivot?.quantity} unidades
                  </p>
                  <p className="font-weight-bold mb-2 mt-3">
                    {medicine.pivot?.use}
                  </p>
                  <p className="">{medicine.pivot?.administration}</p>
                </div>
              ))}
            </Col>

            {prescription.physician?.addresses[0].city && (
              <Col sm={12} md={6} className="mt-5 text-center">
                <p>
                  <b>Local:</b> {prescription.physician?.addresses[0].city},{' '}
                  {prescription.physician?.addresses[0].state} &nbsp;{' '}
                  <b>Data:</b> {moment().format('DD/MM/YYYY')}
                </p>
              </Col>
            )}
            <Col sm={12} md={6} className="mt-5 text-center">
              <p className="font-weight-bold m-0">
                _______________________________{' '}
              </p>
              <p className="m-0">{prescription.physician?.profile.full_name}</p>
              <p className="m-0">{prescription.physician?.physician.crm}</p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mt-4">
              <p>
                Esta prescrição está fundamentada na{' '}
                <b>RDC nº 335 de 24/01/2020</b> da Agência Nacional de
                Vigilância Sanitária que define os critérios e os procedimentos
                para a importação de produto à base de cannabis por pessoa
                física para uso próprio.{' '}
              </p>
              <p>
                <small>
                  <i>
                    Art. 17 A prescrição realizada pelo profissional e a
                    solicitação de Autorização pelo paciente ou seu responsável
                    legal representam a ciência e o aceite por ambos da ausência
                    de comprovação da qualidade, da segurança e da eficácia dos
                    produtos importados, bem como pelos eventos adversos que
                    podem ocorrer, sendo o profissional prescritor e o paciente
                    ou seu responsável legal totalmente responsáveis pelo uso do
                    produto.
                  </i>
                </small>
              </p>
              <p>
                O paciente foi orientado sobre o tratamento e a possibilidade de
                compra de produtos diferentes. <br />
                Mais de um produto/marca listados têm como finalidade facilitar
                o processo administrativo de autorização e compra, não devendo
                os itens serem utilizados ou comprados ao mesmo tempo. O uso do
                produto importado é estritamente pessoal e intransferível, sendo
                proibida a sua entrega a terceiros, doação, venda ou qualquer
                outra utilização diferente da indicada.
              </p>
            </Col>
            <Col lg={12} className="mt-4 mb-5">
              <p className="font-weight-bold">
                Tem dúvidas, conte sempre com a Dr. Cannabis
              </p>
              <p>
                Se é paciente escreva para <b>pacientes@drcannabis.com.br</b>
              </p>
              <p>
                Se é médico escreva para <b>medicos@drcannabis.com.br</b>
              </p>
            </Col>
          </Row>
        </Container>
      )}
    </Section>
  )
}

export default Prescription
