import React from "react"

const CustomCheckbox = ({label, inputName,register, inputValue, callBack, inputChecked})=>{
  return(
    <div className='form-group custom__checkbox'>
      <label className="checkbox-container">
        {label}
          <input className='form-check-input' type="checkbox"
            name={inputName}
            id={inputName}
            ref={register}
            value={inputValue}
            onClick={callBack}
            checked={inputChecked}
          />
          <span className='checkbox-checkmark' />
      </label>

    </div>
  )
}

export {CustomCheckbox}
