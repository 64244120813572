import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Alert } from 'react-bootstrap'
import { useForm, useFormContext, FormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import api from '~/services/api'
import { Container, Medicine, MedicineList } from './styles'
import Button from '~/components/Button'
import Thumb from '~/components/Thumb'
import { Input, Select, TextArea } from '~/components/Form'

function SelectMedicines({ prescriptionId, onHide, show }) {
  const { register, watch } = useForm()
  const [prescription, setPrescription] = useState({})
  const [medicines, setMedicines] = useState([])
  const [brands, setBrands] = useState([])
  const search = watch('search')
  const brand = watch('brand')

  const fetchPrescriptions = async () => {
    const res = await api.get(`/prescriptions/${prescriptionId}`)
    setPrescription(res.data)
  }

  const fetchBrands = async () => {
    const res = await api.get(`/brands/summary`)
    setBrands(res.data)
  }

  useEffect(() => {
    // fetchPrescriptions()
    // fetchBrands()
  }, [])

  const fetchMedicines = async () => {
    let params = []
    if (search !== undefined) {
      params.push(`name=${search}`)
    }
    if (brand !== undefined) {
      params.push(`brand=${brand}`)
    }
    let url = params.join('&')
    if (url.length > 0) {
      url = '/medicines?' + url
    } else {
      url = '/medicines'
    }
    const res = await api.get(url)
    setMedicines(res.data)
  }

  useEffect(() => {
    fetchMedicines()
  }, [search, brand])

  const addMedicine = async id => {
    let listAux = medicines.data
    let listMedicines = medicines
    const medicinesId = prescription.medicines?.map(presc => presc.id) ?? []
    medicinesId.push(id)
    const data = {
      medicines: medicinesId.map(meds => ({ id: meds })),
    }
    await api.put(`/prescriptions/${prescriptionId}`, data)
    fetchPrescriptions()
    const index = listAux.findIndex(val => val.id === id)
    if (index > -1) {
      listAux.splice(index, 1)
      listMedicines.data = listAux
      setMedicines(listMedicines)
    }
  }

  const removeMedicine = async id => {
    const index = prescription.medicines.findIndex(val => val.id === id)
    let listMedicines = medicines
    if (index > -1) {
      listMedicines.data.push(prescription.medicines[index])
      setMedicines(listMedicines)
    }
    let medicinesId = prescription.medicines?.map(presc => presc.id) ?? []
    medicinesId = medicinesId.filter(med => med !== id)
    const data = {
      medicines: medicinesId.map(meds => ({ id: meds })),
    }
    await api.put(`/prescriptions/${prescriptionId}`, data)
    await fetchPrescriptions()
  }

  const validateList = id => {
    if (Array.isArray(prescription.medicines)) {
      const index = prescription.medicines.findIndex(val => val.id === id)
      return index === -1
    }
    return true
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Selecionar produtos
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Select
              ref={register}
              name="brand"
              size="md"
              icon="icon-chevron-down"
              className="mr-3"
            >
              <option value="">Marcas</option>
              {brands.map(brand => (
                <option value={brand.slug}>{brand.name}</option>
              ))}
            </Select>
          </Col>
          <Col>
            <Input
              type="text"
              name="search"
              placeholder="Buscar"
              size="md"
              icon="icon-search"
              ref={register}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MedicineList className="my-3">
              {prescription.medicines?.map(medicine => (
                <div className="medicine">
                  <div className="name">{medicine.name}</div>
                  <div>
                    <Button
                      className="danger"
                      onClick={() => removeMedicine(medicine.id)}
                    >
                      Remover
                    </Button>
                  </div>
                </div>
              ))}
            </MedicineList>
          </Col>
        </Row>
        <hr className="separator" />
        <Row>
          <Col>
            <MedicineList className="my-3">
              {medicines.data?.map(
                medicine =>
                  validateList(medicine.id) && (
                    <div className="medicine">
                      <div className="name">{medicine.name}</div>
                      <div>
                        <Button
                          className="primary"
                          onClick={() => addMedicine(medicine.id)}
                        >
                          Adicionar
                        </Button>
                      </div>
                    </div>
                  ),
              )}
            </MedicineList>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="primary" onClick={onHide}>
          Continuar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default function AskMedicine({ next, data, refresh }) {
  const { medicines } = data
  const [modalShow, setModalShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const formMethods = useForm()

  const goGetPrescription = async pivotMedicines => {
    if (data.medicines.length === 0) {
      toast.error('Selecione os produtos antes de continuar')
    } else {
      setLoading(true)
      const dataMedicines = medicines.map((medicine, idx) => {
        return { id: medicine.id, ...pivotMedicines.medicines[idx] }
      })
      const prescription = {
        medicines: dataMedicines,
        status: 'getPrescription',
      }
      await api.put(`/prescriptions/${data.id}`, prescription)
      next('getPrescription')
      setLoading(false)
    }
  }

  const closeSelectMedicines = async () => {
    await refresh(data.id)
    setModalShow(false)
  }

  return (
    <Container>
      <Alert variant="success">
        <p>Agora só falta selecionar os produtos, a quantidade e o uso:</p>
      </Alert>

      <h4 className={`${medicines.length ? 'mb-2' : 'mb-4'} text-center`}>
        Quais os produtos prescritos?
      </h4>
      <form onSubmit={formMethods.handleSubmit(goGetPrescription)}>
        <FormContext {...formMethods}>
          {data.medicines.map((medicine, idx) => (
            <WindowedRow index={idx} item={medicine} />
          ))}
        </FormContext>

        <div className="d-flex justify-content-between">
          <Button
            className="primary"
            type="button"
            onClick={() => setModalShow(true)}
          >
            Selecionar produtos
          </Button>

          <Button className="primary" type="submit" disabled={loading}>
            Próximo
          </Button>
        </div>
      </form>
      <SelectMedicines
        show={modalShow}
        onHide={closeSelectMedicines}
        prescriptionId={data.id}
      />
    </Container>
  )
}

const WindowedRow = React.memo(({ index, item }) => {
  const { register, errors } = useFormContext()

  return (
    <div>
      {index !== 0 && <hr className="separator" />}

      <Medicine key={item.id}>
        <div className="d-flex align-items-center">
          <div className="thumb">
            <Thumb>
              <div className="inner">
                <img src={item.photo?.uuidUrl} alt="" />
              </div>
            </Thumb>
          </div>
          <div className="name">{item.name}</div>
        </div>
        <div className="d-flex align-items-center">
          <Row>
            <Col md={4}>
              <div>
                <Input
                  className="my-3"
                  label="Quantidade"
                  size="md"
                  fluid
                  name={`medicines[${index}].quantity`}
                  ref={register({ required: true })}
                  error={
                    errors.medicines ? errors.medicines[index]?.quantity : false
                  }
                  defaultValue={item.pivot.quantity}
                  type="number"
                />
              </div>
            </Col>
            <Col lg={8}>
              <div>
                <Input
                  className="my-3"
                  label="Duração do tratamento (em meses)"
                  size="md"
                  placeholder="ex: 24"
                  fluid
                  type="number"
                  ref={register({ required: true })}
                  defaultValue={item.pivot.duration}
                  name={`medicines[${index}].duration`}
                  error={
                    errors.medicines ? errors.medicines[index]?.duration : false
                  }
                />
              </div>
            </Col>
            <Col lg={12}>
              <div>
                <TextArea
                  className="my-3"
                  label="Descreva aqui a posologia e via de uso"
                  size="lg"
                  fluid
                  ref={register({ required: true })}
                  defaultValue={item.pivot.use}
                  name={`medicines[${index}].use`}
                  error={
                    errors.medicines ? errors.medicines[index]?.use : false
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </Medicine>
    </div>
  )
})
